import React, { Component } from 'react'

class ListManager extends Component {
  async componentDidMount(){
    let init = this.props.listInit
    let managersList2 = this.props.managersList
    if(managersList2.length<10){
      init = 0
    }
    let end = init +10
    await this.displayPlaces(init,end)
  }

  async componentDidUpdate() {
    let init = this.props.listInit
    let managersList3 = this.props.managersList
    if(managersList3.length<10){
      init = 0
    }
    let end = init +10
    
    if (this.state.pages ===""){
      await this.displayPlaces(init,end)
    }
  }

  async displayPlaces(init,end) {
    try{
      let managersList = this.props.managersList
      let pages =Math.ceil(managersList.length/10)
      let page =Math.ceil(end/10)
      this.setState({pages:pages})
      this.setState({page:page})
      let managers = await  managersList.slice(init,end)
      this.setState({managers:managers})
      await this.props.editEmployeeListInit(init)
      return pages
    }catch(err){
      alert("error al desplegar la lista de empleados")
    }
  }
    
  constructor(props) {
    super(props)
    this.state = {
      managers: [],
      pages: "",
      page: 1,
      numReloads: 0,
    };
    this.displayPlaces= this.displayPlaces.bind(this);
  }

  render() {
    try{
      if (this.state.pages > 1 && this.state.page !== "") {
        var page = this.state.page
        var pages = this.state.pages
        var init = (page-1)*10
        var end = page*10
        var paginator = 
          <div className="wid-25 te-ali-cen">
            <div> Página {page} de {pages} </div>
            <table className="wid-100">
              <tbody className="wid-100">
                <tr className="wid-100">
                  <td className="wid-25"></td>
                  <td className="wid-25">
                    <button onClick={(event) => {
                      event.preventDefault()
                      if(page>1){
                        this.setState({page: page-1})
                        init = init - 10
                        end = end - 10
                      }
                      this.displayPlaces(init,end)
                    }} 
                    className="btn btn-outline-secondary">Anterior </button>
                  </td>
                  <td className="wid-25">
                    <button onClick={(event) => {
                      event.preventDefault()
                      if(page<pages){
                        this.setState({page: page+1})
                        init = init + 10
                        end = end + 10
                      }
                      this.displayPlaces(init,end)
                    }}
                    className="btn btn-outline-secondary">Siguiente </button>
                  </td>
                  <td className="wid-25"></td>
                </tr>
              </tbody>
            </table>
          </div>
      }else{
        <div></div>
      }

      if(this.props.managersList.length === 0){
        return (
          <div>
            <div id= "content" className="mt-3">
              <br></br><br></br><br></br>
              <p className="te-ali-cen">  No tienes gerentes asignados aún, para cargarlos pulsa "Crear gerente" y sube su correspondiente TGD</p>
              <br></br>
            </div>  
          </div>
        )
      }

      //Creamos el box con la lista de empleados y unos títulos descriptivos
      return (
        <div id= "content" className="mt-3">
          <div className="card mb-4">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Correo</th>
                    <th scope="col">Nombre</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody id="productList">
                  {this.state.managers.map((man, key) => {                     
                    return(
                      <tr key={key} className="wid-100">
                        <td className="wid-40">{man.email}</td>
                        <td className="wid-30">{man.username}</td>
                        <td className="wid-30 te-ali-rig">
                          {
                            {}
                            ? <button onClick={(event) => {this.props.employees_manager(man.email)}} className="btn btn-outline-secondary fs-12">
                                Ver Empleados
                              </button>
                            : null
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {paginator}
        </div>
      ) 
    }catch(err){
      return(<div>Error al cargar la lista de gerentes</div>)
    }
  }
}

export default ListManager;