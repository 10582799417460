import React, { Component, useContext, useState } from 'react'
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import { Link, NavLink, Router } from 'react-router-dom'
import LoginModal from './LoginModal';

class NavBar_NL_ND extends Component {


render(){
  return (

    <table className='w-100 h-100'>
    <tr className='w-100 h-100'>
      <td className='w-70 h-100'>
        <table className='h-100'>
          <tr className='h-50'>
            <td>
              <div className='w-100 mt-10-px'>
                <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/">Inicio</NavLink>
              </div>
            </td>
            <td>
              <div className='w-100 mt-10-px'>
                <NavLink className='ml-20-px azul-verdoso-#28BAC1 text-decoration-none w-100' to="/register">Registrarse</NavLink>
              </div>
            </td>
          </tr>
          <tr className='w-100 h-50'>
            <td>
              <div className='w-100'>
                <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/blog">Blog</NavLink>
              </div>
            </td>
            <td>
              <div className='w-100'>
                <NavLink className='ml-20-px azul-verdoso-#28BAC1 text-decoration-none w-100' to="/login">Iniciar sesión</NavLink>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>



  //   <table className='w-100'>
  //   <tr className='w-80'>
  //     <td className='pt-pb-10-px'>
  //       <div className='btn-group'>
  //         <button type="button" className="btn btn-outline-primary" to="/register" onSubmit={(event) => {
  //                       event.preventDefault()
  //                   this.props.showNavigatorModalNavbar()}}><NavLink to="/register">Registrarse</NavLink>
  //         </button>

                  
                      
  //         <button type="button" className="btn btn-outline-primary" onSubmit={(event) => {
  //                 event.preventDefault()
  //                 this.props.showLoginModal()}}><NavLink  to="/login">Iniciar sesión</NavLink>
  //         </button>
                  
  //       </div>
  //     </td>
  //   </tr>
  // </table>


   )
}
  
}
export default NavBar_NL_ND;
