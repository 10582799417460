import React from "react";
import Chart from "chart.js/auto";

class ScatterChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.chartType = "scatter";
        this.chartTitle = "Horas de trabajo realizadas por los conductores";
    }

    componentDidMount() {
        this.createScatterChart();
    }

    createScatterChart = async () => {
        const response = this.props.dataGraphic;
        const popCanvas = this.chartRef.current.getContext("2d");
    
        // Destroy existing chart if it exists
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    
        const datasets = response.map(employee => {
            const data = employee.data.map(week => ({
               
                x: week.driving_hours / 60,
                y: week.working_hours / 60 
            }));
    
            return {
                label: ` ${employee.employee_name}`,
                data: data,
                backgroundColor:'rgba(0, 43, 217, 0.2)',
                borderColor: 'rgb(0, 43, 217)',
                borderWidth: 1,
            };
        });
    
        // Update the chart options to include trendline
        this.chartInstance = new Chart(popCanvas, {
            type: this.chartType,
            data: {
                datasets,
            },
            options: {
                plugins: {
                    legend: {
                        width: '100%',
                        display: false,
                        position: 'left',
                        align: 'start',
                        labels: {
                            boxWidth: 20,
                            padding: 10,
                            usePointStyle: true,
                        },
                        maxItems: 3,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Horas de conduccion',
                        },
                        type: 'linear',
                        position: 'bottom',
                    } ,
                    y: {
                        title: {
                            display: true,
                            text: 'Horas otros trabajos',
                        },
                        type: 'linear',
                        position: 'left',
                    },
                },
            },
        });
    };

    render() {
        const chartContainerStyle = {
            maxHeight: '70vh', // 80% of the viewport height
            overflow: 'auto', // Make it scrollable
        };

        return (
            <div>

                <canvas ref={this.chartRef} className="w-70-vh h-70-vh"></canvas>
            </div>
        );
    }
}

export default ScatterChart;