import React, { Component } from 'react'

//Componente que muestra los clientes que tiene una empresa
class List extends Component {
  async componentDidMount(){
    let init = this.props.listInit
    let end = init +10
    
    await this.displayPlaces(init,end)
  }

  async componentDidUpdate() {
    let init = this.props.listInit
    let end = init +10
    
    if (this.state.pages ===""){
      await this.displayPlaces(init,end)
    }
  }

  async editEmployee(id){
    try{
      let employee
      if (this.props.myEmployee){
        if (this.props.myEmployee.id!==id){
          employee = await this.props.search(id,0,0,{},false,"", false, false)
        }
      }else{
        employee = await this.props.search(id,0,0,{},false,"", false, false)
      }
      
      if (employee || this.props.myEmployee){
        await this.props.showCreationFields()
      }
    }catch(err){
      alert("error al intentar crear un empleado")
    }
  }

  async displayPlaces(init,end) {
    try{
      let employeesList = await this.props.employeesList
      let pages =Math.ceil(employeesList.length/10)
      let page =Math.ceil(end/10)
      this.setState({pages:pages})
      this.setState({page:page})
      let employees =await  employeesList.slice(init,end)
      this.setState({employees:employees})
      await this.props.editEmployeeListInit(init)
      return pages
    }catch(err){
      alert("error al desplegar la lista de empleados")
    }
  }
    
  constructor(props) {
    super(props)
    this.state = {
      employees: [],
      pages: "",
      page: 1,
      numReloads: 0,
    };
    this.displayPlaces= this.displayPlaces.bind(this);
  }

  render() {

     try{
      //Comprueba si es ordenador o móvil para mostrarlo de una u otra forma
      if(this.props.device === 'desktop' && !this.props.isFeatureEnabled){

        //Comprueba si debe crear varias páginas o no
        if (this.state.pages > 1 && this.state.page !== "") {
          var page = this.state.page
          var pages = this.state.pages
          var init = (page-1)*10
          var end = page*10
          var paginator = 
            <div className="wid-100 te-ali-cen">
              <div> Página {page} de {pages} </div>
              <table className="wid-100">
                <tbody className="wid-100">
                  <tr className="wid-100">
                    <td className="wid-25"></td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page>1){
                          this.setState({page: page-1})
                          init = init - 10
                          end = end - 10
                        }
                        this.displayPlaces(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }} 
                      className="btn btn-outline-secondary">Anterior </button>
                    </td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page<pages){
                          this.setState({page: page+1})
                          init = init + 10
                          end = end + 10
                        }
                        this.displayPlaces(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Siguiente </button>
                    </td>
                    <td className="wid-25"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        }else{
          <div></div>
        }
  
        //Si la empresa no tiene empleados asignados
        if(this.props.employeesList.length === 0){
          return (
            <div>
            <div id= "content" className="mt-3">
              <br></br><br></br><br></br>
              <p className="te-ali-cen">  No tienes empleados asignados aún, para cargarlos pulsa "Crear Empleado" y sube su correspondiente TGD</p>
              <br></br>
            </div>
            
            </div>
          )
        }
  
        //Creamos el box con la lista de empleados y unos títulos descriptivos
        return (
          <div id= "content" className="mt-3">
            <form className="mb-3"  onSubmit={(event) => {
              event.preventDefault()
              let _tripSearched = this._tripSearched.value 
              this.props.search(empl.id,0,0,{},false,"", false, false);
            }}></form>
            <div className="card mb-4">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Empleado</th>
                      <th scope="col">Identificador</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody id="productList">
                    {this.state.employees.map((empl, key) => {   
                      var statusText
                      var statusColor
  
                      if(empl.base_salary!=0){
                        statusText = "Editado"
                        statusColor = "green"
                      }else{
                        statusText = "Pendiente"
                        statusColor = "red"
                      }
                    
                      return(
                        <tr key={key} className="wid-100">
                          <td className="wid-40">{empl.name} <br></br>{empl.apellidos} </td>
                          <td className="wid-15">{empl.identification}</td>
                          <td className="wid-15"><p  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"color":statusColor}}>{statusText}</p></td>
                          <td className="wid-15 te-ali-rig">
                            {
                              {}
                              ? <button

                                        onClick={(event) => {
                                          this.props.actualizarEstadoYAlmacenar()
                                          this.props.search(empl.id,0,0,{}, false,"", false, false)
                                        }} 

                                        className="btn btn-outline-secondary fs-12">
                                  Ver
                                </button>
                              : null
                            }
                          </td>
                          <td className="wid-15 te-ali-rig">
                            { {}
                              ? <button className="btn btn-outline-secondary fs-12"

                                        onClick={(event) => {
                                          this.props.actualizarEstadoYAlmacenar()
                                          this.editEmployee(empl.id)
                                          }}>

                                        Editar
                                </button>
                              : null
                            }
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {paginator}
          </div>
        )
      } else {
        if (this.state.pages > 1 && this.state.page !== "") {
          var page = this.state.page
          var pages = this.state.pages
          var init = (page-1)*10
          var end = page*10
          var paginator = 
            <div className="wid-100 te-ali-cen">
              <div> Página {page} de {pages} </div>
              <table className="wid-100">
                <tbody className="wid-100">
                  <tr className="wid-100">
                    <td className="wid-25"></td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page>1){
                          this.setState({page: page-1})
                          init = init - 10
                          end = end - 10
                        }
                        this.displayPlaces(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Anterior </button>
                    </td>
                    <td className="wid-25">
                      <button onClick={(event) => {
                        event.preventDefault()
                        if(page<pages){
                          this.setState({page: page+1})
                          init = init + 10
                          end = end + 10
                        }
                        this.displayPlaces(init,end)
                        this.props.actualizarEstadoYAlmacenar()
                      }}
                      className="btn btn-outline-secondary">Siguiente </button>
                    </td>
                    <td className="wid-25"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        }else{
          <div></div>
        }
  
        if(this.props.employeesList.length === 0){
          return (
            <div>
            <div id= "content" className="mt-3">
              <br></br>
              <p className="te-ali-cen">   No tienes empleados asignados aún, para cargarlos pulsa "Crear Empleado" y sube su correspondiente TGD</p>
            </div>
            <br></br><br></br><br></br><br></br>
            </div>
          )
        }
  
        return (
          <div id= "content" className="mt-3">
            <form className="mb-3"  onSubmit={(event) => {
              event.preventDefault()
              let _tripSearched = this._tripSearched.value 
              this.props.search(empl.id,0,0,{},false,"", false, false);
            }}></form>
            <div className="card mb-4">
              <div className="card-body">
                <table className="table">
                  <tbody id="productList">
                    {this.state.employees.map((empl, key) => {   
                      var statusText
                      var statusColor
  
                      if(empl.base_salary!=0){
                        statusText = "Editado"
                        statusColor = "green"
                      }else{
                        statusText = "Pendiente"
                        statusColor = "red"
                      }
                
                      return(
                        <tr key={key} className="wid-100 te-ali-cen">
                          <td className="wid-25 te-ali-cen">{empl.name} <br></br> {empl.apellidos}</td>
                          <td className="wid-25 te-ali-cen">{empl.identification} <br></br> {
                            {}
                            ? <button 
                              className="btn btn-outline-secondary fs-14"
                              onClick={(event) => {this.props.actualizarEstadoYAlmacenar();this.props.search(empl.id,0,0,{}, false, "", false, false)}} >
                                Ver
                              </button>
                            : null
                          }</td>
                          <td className="wid-25 te-ali-cen">{statusText} <br></br> {
                              {}
                              ? <button 
                                className="btn btn-outline-secondary fs-14"
                                onClick={(event) => {this.props.actualizarEstadoYAlmacenar();this.editEmployee(empl.id)}}>
                                  Editar
                                </button>
                              : null
                            } </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {paginator}
          </div>
        )
      }
    }catch(err){
      return(<div>Error al cargar la lista de empleados</div>)
    }
  }
}

export default List;