import React, { useState, useEffect, useRef } from "react";
// import state from "../store";
import { blogListTotal } from "./BlogList";
import GenericArticle from "./GenericArticle";
import Navbar from "./Navbar";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   FacebookIcon,
//   XIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
//   EmailShareButton,
//   EmailIcon,
// } from "react-share";
import { useNavigate, useParams } from "react-router-dom";

import  NavBarLogged_Desktop_from_about from './NavBar_Desktop_from_about'

/**
 * Componente funcional para mostrar un articulo y su contenido.
 *
 * @param {Object} props - Propiedades del componente.
 * @returns {JSX.Element} Elemento JSX que representa el componente Blog.
 */
const Blog = (props) => {
  const navigate = useNavigate();
  const { blogId } = useParams();
  // Comentado porque no se utiliza
  // const blog = blogListTotal.find((blog) => blog.id === parseInt(blogId));
  const currentUrl = window.location.href;
  const topElementRef = useRef(null);

  const [selectedBlog, setSelectedBlog] = useState(null); // Estado para introducir el articulo seleccionado
  const [isBlogSelected, setIsBlogSelected] = useState(false); // Estado para saber si se ha seleccionado un articulo
  const [useSmoothScroll, setUseSmoothScroll] = useState(false); // Estado para saber si usar el scroll arriba

  /**
   * Maneja el click en un articulo.
   *
   * @param {number} id - ID del blog seleccionado.
   */
  const handleBlogClick = (id) => {
    // Se llama cuando el usuario pulsa uno de los artículos, selecciona el artículo según su ID
    let blog = blogListTotal.find((blog) => blog.id === id);
    setSelectedBlog(blog);
    // Al seleccionar un articulo cambia el estado a TRUE
    setIsBlogSelected(true);
    setUseSmoothScroll(true);

    if (blog) {
      // si hay articulo te dirige al path de ese articulo
      navigate(`/blog/${blog.path}`, { replace: true });

      if (useSmoothScroll) {
        try {
          topElementRef.current.scrollIntoView({ behavior: "smooth" });
        } catch (err) {
          //console.log(err);
        }
      }
    }
  };

  /**
   * Maneja la navegación a un post de articulo basado en el path proporcionado.
   *
   * @param {string} path - El path del post del blog.
   */
  const handlePath = (path) => {
    // Encuentra el post del articulo con el path proporcionado y lo establece como el post del articulo seleccionado.
    let blog = blogListTotal.find((blog) => blog.path === path);

    // Si ha encontrado la noticia
    if (blog) {
      // Si se encuentra el articulo realizar las acciones correspondientes
      setSelectedBlog(blog);
      // Al seleccionar un articulo cambia el estado a TRUE
      setIsBlogSelected(true);
      setUseSmoothScroll(true);
      // Luego navega al path del post del articulo.
      navigate(`/blog/${blog.path}`);
      // Sino ha encontrado la noticia
    } else {
      // Si no se encuentra el blog navega a la página 404
      navigate("/page404");
      
    }
  };

  useEffect(() => {
    const selectedId = props.selectedId;

    // Si hay un selected id, hace el handleBlogClick directamente
    if (selectedId) {
      handleBlogClick(selectedId);
    } else {
      // Coge el último elemento de la URL para saber cuál es el path
      const obtenerUltimoPath = (url) => {
        // Separa los elementos de la URL por / y cogemos el último
        var partes = url.split("/");
        var ultimoPath = partes[partes.length - 1];
        return ultimoPath;
      };
      let path = obtenerUltimoPath(currentUrl);

      if (path !== "blog") {
        handlePath(path);
      }
    }
  }, [props.selectedId, currentUrl]); // Añade currentUrl al array

  var toDisplay = selectedBlog ? selectedBlog.ContentComponent : <div></div>;
  let titleShared = selectedBlog ? selectedBlog.title : "Título no disponible";
  let contentShared = selectedBlog ? selectedBlog.summary : "Descripción no disponible";

  // Visualización del articulo en desktop
  let BlogList;
  /**
   * Carga la variable BlogList basada en el estado isBlogSelected.
   *
   * @returns {JSX.Element} Elemento JSX que representa BlogList.
   */
  const cargaVariableDesktop = () => {

    
    if (isBlogSelected) {
      BlogList = (
        <div ref={topElementRef}>
          
          <button className="btn btn-primary bg-highlight-color shadow ml-65p" onClick={() => setIsBlogSelected(false)}>
            Volver
          </button>
          <div className="d-flex justify-content-center pt-15 pb-15">{sharedButton()}</div>
          <table className="text-start w-100">
            <tr className="w-100">
              <td className="w-5"></td>
              <td className="w-90">
                {selectedBlog && (
                  <div className="w-100 text-start fw-light">
                    <p>
                      <GenericArticle
                        ContentComponent={toDisplay}
                        imageUrl={selectedBlog.imageUrl}
                        title={selectedBlog.title}
                        content={selectedBlog.content}
                        summary={selectedBlog.summary}
                        author={selectedBlog.author}
                        date={selectedBlog.date}
                      />
                    </p>
                  </div>
                )}
              </td>
              <td className="w-5"></td>
            </tr>
          </table>
          <div className="d-flex justify-content-center pb-15 pt-15">{sharedButton()}</div>
        </div>
      );
    } else {

      BlogList = (
        <div ref={topElementRef} className="container">
          <br></br><br></br><br></br><br></br><br></br><br></br>
          <div className="row justify-content-center">
            {blogListTotal.map((blog) => (
              <div
                key={blog.id}
                onClick={() => handleBlogClick(blog.id)}
                className="h-400p col-5 m-20 border border-table rounded-3 transition-bg-color-03 fw-bold clickable no-select"
              >
                <h3 className="text-center pt-2 blog-title">{blog.title}</h3>
                <div className="overflow-hidden h-85 d-flex justify-content-center align-items-center">
                  {blog.imageUrl && <img src={blog.imageUrl} alt="" className=" rounded-3" />}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return BlogList;
  };

  // Visualización de articulos en dispositivos diferentes a desktop
  let BlogListMobile;

  /**
   * Carga la variable BlogListMobile basada en el estado isBlogSelected.
   *
   * @returns {JSX.Element} Elemento JSX que representa BlogListMobile.
   */
  const cargaVariableMobile = () => {
    
    if (isBlogSelected) {
      
      BlogListMobile = (
        <div ref={topElementRef}>
          <br></br><br></br><br></br><br></br>
          <button className="btn btn-primary bg-highlight-color shadow m-10" onClick={() => setIsBlogSelected(false)}>
            Volver
          </button>
          <div className="d-flex justify-content-center pt-15 pb-15">{sharedButton()}</div>
          <table className="text-start w-100">
            <tr className="w-100">
              <td className="w-5"></td>
              <td className="w-90">
                {selectedBlog && (
                  <div className="w-100 text-start fw-light">
                    <p>
                      <GenericArticle
                        ContentComponent={toDisplay}
                        imageUrl={selectedBlog.imageUrl}
                        title={selectedBlog.title}
                        content={selectedBlog.content}
                        summary={selectedBlog.summary}
                        author={selectedBlog.author}
                        date={selectedBlog.date}
                      />
                    </p>
                  </div>
                )}
              </td>
              <td className="w-5"></td>
            </tr>
          </table>
          <div className="d-flex justify-content-center pb-15 pt-15">{sharedButton()}</div>
        </div>
      );
    } else {
  
      BlogListMobile = (
        <div ref={topElementRef}>
          <br></br><br></br><br></br><br></br>
          <table className="text-start w-100">
            <tr className="w-100">
              <td className="w-5"></td>
              <td className="w-90 aling-text-top va-top clickable no-select">
                <ul className="aling-text-top list-unstyled">
                  {blogListTotal.map((blog) => (
                    <li
                      key={blog.id}
                      onClick={() => handleBlogClick(blog.id)}
                      className="border border-table rounded-3 ptl-10 m-10 transition-bg-color-03"
                    >
                      <div id="content" className="mt-3">
                        <div className="card mb-4">
                          <div className="card-body w-100">
                            <table className="text-start w-100">
                              <tbody className="text-start w-100">
                                <tr className="text-start w-100">
                                  <td className="text-start w-100">
                                    <h2>{blog.title}</h2>
                                    <h4>{blog.imageUrl ? <img src={blog.imageUrl} alt="" className="w-auto mt-15 rounded-3" /> : null}</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </td>
              <td className="w-5"></td>
            </tr>
          </table>
        </div>
      );
    }

    return BlogListMobile;
  };

  // Introduce en la variable un articulo seleccionado o la lista de articulos
  // Si es un dispositivo desktop

  let  device;
  let ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    device = "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    device = "mobile";
  }else{
    device = "desktop";
  }
   


  if (device === "desktop") {
    console.log("en el desktop blog");
    BlogList = cargaVariableDesktop();
    return <div className="shadow w-100 h-100 pt-50 bg-white"><NavBarLogged_Desktop_from_about/>{BlogList}</div>;
    // Sino es desktop
  } else {
    console.log("en el mobile blog");
    BlogListMobile = cargaVariableMobile();
    return <div className="shadow w-100 h-100 bg-white"><Navbar/>{BlogListMobile}</div>;
  }

  /**
   * Función para mostrar botones de compartir en redes sociales.
   *
   * @returns {JSX.Element} Elemento JSX que representa los botones de compartir.
   */
  function sharedButton() {
    return (
      <div className="text-end d-inline-flex align-items-center ptl-3">
        {/* <TwitterShareButton url={currentUrl} title={titleShared} separator="">
          <XIcon size={32} round={true} className="mr-15" />
        </TwitterShareButton>
        <FacebookShareButton url={currentUrl} hashtag={titleShared}>
          <FacebookIcon size={32} round={true} className="mr-15" />
        </FacebookShareButton>
        <WhatsappShareButton url={currentUrl} title={titleShared} separator="">
          <WhatsappIcon size={32} round={true} className="mr-15" />
        </WhatsappShareButton>
        <EmailShareButton url={currentUrl} subject={titleShared} body={contentShared} separator="">
          <EmailIcon size={32} round={true} />
        </EmailShareButton> */}
      </div>
    );
  }
};

export default Blog;
