import React, { Component } from "react";
import html2pdf from "html2pdf.js";
// import moment from "moment";
// import ErrorHappened from "./ErrorHappened";

import CustomSpinner from "./CustomSpinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "./modal.css";

class CMR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTrip: this.props.myTrip,
      role: "",
      status: "",
      amountCrypto: "",
      width: "",
      shipperMail: "",
      carrierMail: "",
      senderMail: "",
      receiverMail: "",
      listDetails: [],
      efCarrierMail: "",
      signedBySender: "",
      signedBySenderDate: "",
      signedByCarrier: "",
      signedByCarrierDate: "",
      signedByReceiver: "",
      signedByReceiverDate: "",
      senderAddress: "",
      showSenderDetails: false,
      showSenderEditButton: true,
      showReceiverDetails: false,
      showReceiverEditButton: true,
      showCarrierDetails: false,
      showCarrierEditButton: true,
      showErrorMessage: false,
      errorMsg: "",
      userIsShipper: false,
      userIsSender: false,
      userIsReceiver: false,
      userIsEfCarrier: false,
      winWidth: 0,
      winHeight: 0,
      device: "",
      HTML: "",
    };
    this.handleChangeTrip = this.assignSender.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.hideForms = this.hideForms.bind(this);
  }

  async componentDidMount() {
    let token = await this.props.userToken;
    let tripId = await this.state.myTrip.id;

    const roleData = await this.getUserRole(token, tripId);
    ////console.log("Role Data: ", roleData);
    if (roleData.role === "shipper") {
      this.setState({ userIsShipper: true });
    }

    //get window width in PIXELS
    let windowWidth = window.innerWidth;

    let elementWidth = Math.round(windowWidth * 0.66);

    // parese element width to string
    elementWidth = elementWidth.toString() + " px";

    this.setState({ width: elementWidth });

    await this.getRoles(token, tripId);

    let statusStr = await this.state.myTrip.status;
    let userRole = await this.props.userRole;

    var status;

    switch (statusStr) {
      case "pending":
        status = "No asignado";
        break;
      case "Pending service":
        status = "Pendiente Servicio";
        break;
      case "Confirmed by client":
        status = "Confirmado por el cliente";
        break;
      case "Pending payment":
        status = "Pendiente Pago";
        break;
      case "Paid":
        status = "Pagado";
        break;
      case "Complain":
        status = "En disputa";
        break;
      case "pending deposit":
        status = "Pendiente depósito";
        break;
    }

    this.setState({ status: status });

    let role;
    // check if user is the shipper, if else check if is carrier
    if (userRole === "shipper") {
      role = "Cargador";
    }
    if (userRole === "carrier") {
      role = "Transportista";
    }
    if (userRole === "intermediary") {
      role = "Intermediario";
    }
    await this.setState({ role: role });
  }

  async assignSender(id, senderEmail) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: senderEmail,
          b: id,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/sender/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "Sender not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el remitente");
    }
  }

  async assignSender2(id, senderEmail, senderAddess1, senderName1) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          a: senderEmail,
          b: id,
          c: senderAddess1,
          d: senderName1,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/sender2/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "Sender not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el remitente");
    }
  }

  async assignEfCarrier(id, efCarrierEmail) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          a: efCarrierEmail,
          b: id,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/effective-carrier/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "efCarrier not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el porteador");
    }
  }

  async assignEfCarrier2(id, efCarrierEmail, efCarrierAddess1, efCarrierName1) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          a: efCarrierEmail,
          b: id,
          c: efCarrierAddess1,
          d: efCarrierName1,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/effective-carrier2/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "efCarrier not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el porteador");
    }
  }

  async assignReceiver(id, receiverEmail) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          a: receiverEmail,
          b: id,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/receiver/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "Receiver not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el consignatario");
    }
  }

  async assignReceiver2(id, receiverEmail, receiverAddess1, receiverName1) {
    try {
      var token = await this.props.userToken;

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          a: receiverEmail,
          b: id,
          c: receiverAddess1,
          d: receiverName1,
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/receiver2/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "Receiver not registered") {
        await this.props.showError("Este correo no está registrado. Se ha creado una cuenta y enviado un email con los datos de acceso.");
      }

      await this.props.showEditTripFields(id, response);
    } catch (err) {
      //console.log("Error during sender assignment", err);
      await this.props.showError("Se ha producido un error al asignar el consignatario");
    }
  }

  async assigncarrierComments(id, carrComment) {
    var token = await this.props.userToken;

    let requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({
        a: carrComment,
        b: id,
      }),
    };

    let response = await fetch((await this.props.endPoint) + "trip/carrier-comments/", requestOptions).then((response) => response.json());

    switch (response) {
      case "forbidden":
        await this.props.showError("No tienes acceso a esta accion");
        break;
      case "Receiver not registered":
        await this.props.showError("El correo no está en nuestra base de datos");
        break;
    }

    await this.props.showEditTripFields(id, response);
    //await this.props.search(id)
    //await this.props.showEditTripFields(id, response)

    // //console.log("response", response.carrier_comments)
    // //console.log("id", id)
  }

  async assignsenderComments(id, sendComment) {
    var token = await this.props.userToken;

    let requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({
        a: sendComment,
        b: id,
      }),
    };

    let response = await fetch((await this.props.endPoint) + "trip/sender-instruction/", requestOptions).then((response) => response.json());

    switch (response) {
      case "forbidden":
        await this.props.showError("No tienes acceso a esta accion");
        break;
    }

    await this.props.showEditTripFields(id, response);
    //await this.props.search(id)
    //await this.props.showEditTripFields(tripId)
  }

  async assignreceiverComments(id, receiverComment) {
    var token = await this.props.userToken;

    let requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({
        a: receiverComment,
        b: id,
      }),
    };

    let response = await fetch((await this.props.endPoint) + "trip/receiver-comment/", requestOptions).then((response) => response.json());

    switch (response) {
      case "forbidden":
        await this.props.showError("No tienes acceso a esta accion");
        break;
    }

    await this.props.showEditTripFields(id, response);
    //await this.props.search(id)
    //await this.props.showEditTripFields(tripId)
  }

  // async confirmReceiver(id){

  //   var token = await this.props.userToken

  //   let requestOptions = {
  //     method: "PUT",
  //     headers: {"Content-Type": "application/json",
  //               "Authorization": "Bearer " + token},
  // }

  //   let response = await fetch( await this.props.endPoint +"trip/receiver_comment/" , requestOptions).then((response) => response.json())

  //   switch(response){
  //     case "not authorized":

  //       await this.props.showError('No tienes acceso a esta accion')
  //       break;
  //   }
  //   await this.props.showEditTripFields(id, response)
  //   //await this.props.search(id)
  //   //await this.props.showEditTripFields(tripId)

  // }

  sendEmailCMR = async () => {
    try {
      var token = await this.props.userToken;
      // Se comprueba a quién se le debe mandar el correo
      let tripId = await this.state.myTrip.id;
      let eMailCMR = await [
        this.state.shipperMail.split(",")[0],
        this.state.senderMail.split(",")[0],
        this.state.receiverMail.split(",")[0],
        this.state.efCarrierMail.split(",")[0],
      ];
      let htmlCMR = await this.extractHtml();

      // Generar el PDF utilizando html2pdf.js
      const opt = {
        margin: 10,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // Extrae el blob del HTML
      const pdfBlob = await html2pdf().from(htmlCMR).set(opt).outputPdf("blob");

      // Convierte el Blob a base64
      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(pdfBlob);
      });

      let requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: tripId,
          b: eMailCMR,
          c: pdfBase64, // Incluir el PDF en base64
        }),
      };

      let response = await fetch((await this.props.endPoint) + "trip/emailCMR/", requestOptions).then((response) => response.json());

      if (response === "forbidden") {
        await this.props.showError("No tienes acceso a esta acción");
      } else if (response === "trip not found") {
        await this.props.showError("Viaje no encontrado");
      } else {
        await this.props.showError("Se han enviado los correos electronicos");
      }
    } catch (err) {
      //console.log("Error: ", err);
      await this.props.showError("Se ha producido un error al mandar los correos");
    }
  };

  extractHtml = () => {
    const emailHtml = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        body {
          font-size: 14px;
        }
            table {
          border-collapse: collapse; /* Para asegurarte de que no haya espacios entre los bordes de la tabla */
        }
      </style>
    </head>
      <body>
        <div>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0; height: 130px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold">
                      <label>Remitente</label>
                    </div>
                    <div style="margin-bottom: 10px">${this.state.senderMail}</div>
                  </div>
                </td>
                <td style="width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div style="font-weight: bold; margin-botton: 5px">
                      <label>CARTE DE PORTE INTERNACIONAL</label>
                    </div>
                    <div>
                      <label>
                        Este Transporte queda sometido, no obstante toda claúsula contraria, al Convenio sobre el Contrato de Transporte Internacional de Mercancías
                        por Carretera (CMR)
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0;height: 130px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold">
                      <label>Consignatario</label>
                    </div>
                    <div style="margin-bottom: 10px">${this.state.receiverMail}</div>
                  </div>
                </td>
                <td style="width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold">
                      <label>Porteador</label>
                    </div>
                    <div style="display: flex; justify-content: start">
                      <div style="margin-bottom: 40px">
                        <label>${this.state.efCarrierMail}</label>
                      </div>
                    </div style="display: flex; justify-content: start">
                    <div style="margin-bottom: 10px">
                        <label>Empresa:${this.state.carrierMail}</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0;height: 80px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div style="display: flex; font-weight: bold; margin-bottom: 10px">
                      <div style="width: 50%">
                        <label>Origen</label>
                      </div>
                      <div style="width: 50%">
                        <label>Fecha</label>
                      </div>                
                    </div>                  
                    <div style="display: flex">
                      <div style="width: 50%">
                        <label>${this.state.myTrip.origin}</label>
                      </div>
                      <div style="width: 50%">
                        <label>
                          ${new Intl.DateTimeFormat("es-ES", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(parseInt(this.state.myTrip.trip_date) * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td style="width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div style="font-weight: bold; margin-bottom: 10px">
                      <label>Porteadores Sucesivos</label>
                    </div>
                    <div>
                      <label>Deshabilitado</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0;height: 150px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div style="display: flex; font-weight: bold; margin-bottom: 10px">
                      <div style="width: 50%">
                        <label>Destino</label>
                      </div>
                      <div style="width: 50%">
                        <label>Fecha</label>
                      </div>                
                    </div>       
                    <div style="display: flex">
                      <div style="width: 50%">
                        <label>${this.state.myTrip.destination}</label>
                      </div>
                    <div style="width: 50%">
                        <label>
                          ${new Intl.DateTimeFormat("es-ES", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(this.state.myTrip.trip_date * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td style="width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold; margin-bottom: 10px">
                      <label>Comentarios Porteador</label>
                    </div>
                    <div style="max-width: 300px; max-height: 90px; overflow: auto">
                      <label style="display: block">${this.state.myTrip.carrier_comments}</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0;height: 80px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div style="font-weight: bold">
                      <label>Documentos Anexos</label>
                    </div>
                    <div>
                      <label>Deshabilitado</label>
                    </div>
                  </div>
                </td>
                <td style="width: 50%; border-bottom: 0"></td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; border: 1px solid #000; border-bottom: 0;height: 100px; margin: auto">
      <tbody>
        <tr>
          <td style="width: 100%; padding: 10px; vertical-align: top">
            <div style="height: 100%">
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Marcas y números</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Número de bultos</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Embalaje</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Naturaleza mercancía</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Nº estadístico</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Peso en Kg</label>
                    </td>
                    <td style="text-align: center; width: 14.28%; padding-bottom: 10px">
                      <label style="font-weight: bold">Volumen en m3</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[0]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[1]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[2]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[3]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[4]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[5]}</label>
                    </td>
                    <td style="text-align: center; width: 14.28%">
                      <label>${this.state.listDetails[6]}</label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
          </table>    
          <table style="width: 100%; border: 1px solid #000; height: 150px; margin: auto">
            <tbody>
              <tr>
                <td style="border-right: 1px solid #000; width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold">
                      <label>Instrucciones Remitente</label>
                    </div>
                    <div style="max-width: 300px; max-height: 90px; overflow: auto; word-wrap: break-word">
                      <label style="display: block">${this.state.myTrip.sender_instruction}</label>
                    </div>
                  </div>
                </td>
                <td style="width: 50%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column; height: 100%">
                    <div style="font-weight: bold">
                      <label>Comentarios Consignatario</label>
                    </div>
                    <div style="max-width: 300px; max-height: 90px; overflow: auto; word-wrap: break-word">
                      <label style="display: block">${this.state.myTrip.receiver_comments}</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; height: 150px; margin: auto; margin-top: 5px">
            <tbody style="width: 100%">
              <tr>
                <td style="width: 30%; vertical-align: bottom">
                  <label style="font-weight: bold">Sello Remitente</label>
                </td>
                <td style="width: 3%"></td>
                <td style="width: 30%; vertical-align: bottom">
                  <label style="font-weight: bold">Sello Porteador</label>
                </td>
                <td style="width: 3%"></td>
                <td style="width: 30%; vertical-align: bottom">
                  <label style="font-weight: bold">Sello Consignatario</label>
                </td>
              </tr>
              <tr style="width: 100%">
                <td style="border: 1px solid #000; width: 30%; padding: 10px; vertical-align: top">
                  <div style="display: flex; flex-direction: column">
                    <div>
                      <label>Fecha: ${this.state.signedBySenderDate}</label>
                    </div>
                    <div>
                      <label>
                        Firma:
                        <div style="max-width: 200px; max-height: 75px; overflow-wrap: break-word">
                        <label style="font-size: 1em; display: block">
                          ${this.state.signedBySender}
                        </label>
                        </div>
                      </label>
                    </div>
                  </div>
                </td>
                <td style="width: 3%"></td>
                <td style="border: 1px solid #000; width: 30%; padding: 10px; vertical-align: top">
                  <div>
                    <div>
                      <label>Fecha: ${this.state.signedByCarrierDate}</label>
                    </div>
                    <div>
                      <label>
                        Firma:
                        <div style="max-width: 200px; max-height: 75px; overflow-wrap: break-word">
                        <label style="font-size: 1em; display: block">
                          ${this.state.signedByCarrier}
                        </label>
                        </div>
                      </label>
                    </div>
                  </div>
                </td>
                <td style="width: 3%"></td>
                <td style="border: 1px solid #000; width: 30%; padding: 10px; vertical-align: top">
                  <div>
                    <div>
                      <label>Fecha: ${this.state.signedByReceiverDate}</label>
                    </div>
                    <div>
                      <label>
                        Firma:
                        <div style="max-width: 200px; max-height: 75px; overflow-wrap: break-word">
                        <label style="font-size: 1em; display: block">
                          ${this.state.signedByReceiver}
                        </label>
                        </div>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
    </html>
    `;

    this.setState({ HTML: emailHtml });

    return emailHtml;
  };

  async sign(role, id) {
    var token = await this.props.userToken;

    let requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({
        a: role,
        b: id,
      }),
    };

    let response = await fetch((await this.props.endPoint) + "trip/sign/", requestOptions).then((response) => response.json());

    switch (response) {
      case "forbidden":
        await this.props.showError("No tienes acceso a esta accion");
        break;
    }
    await this.props.showEditTripFields(id, response);
    //await this.props.search(id)
    //await this.props.showEditTripFields(tripId)
  }

  async getRoles(token, id) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    var url1 = (await this.props.endPoint) + "trip/participants/" + id;
    var response = await fetch(url1, requestOptions).then((response) => response.json());

    //console.log("response getRoles: ", response);

    const updateState = (response, updates) => {
      this.setState({
        shipperMail: response.shipperMail,
        carrierMail: response.carrierMail,
        senderMail: response.senderMail,
        receiverMail: response.receiverMail,
        listDetails: response.listDetails,
        efCarrierMail: response.efCarrierMail,
        signedBySender: response.signedBySender,
        signedBySenderDate: response.signedBySenderDate,
        signedByReceiver: response.signedByReceiver,
        signedByReceiverDate: response.signedByReceiverDate,
        signedByCarrier: response.signedByCarrier,
        signedByCarrierDate: response.signedByCarrierDate,
        ...updates,
      });
    };

    if (response === "Trip not found") {
      await this.props.showError("No se ha encontrado ese flete");
    } else if (response === "No eres parte") {
      await this.props.showError("No eres parte de ese flete");
    } else if (response.senderUndefined && response.receiverUndefined && response.efCarrierUndefined) {
      updateState(response, {
        showSenderDetails: true,
        showSenderEditButton: false,
        showReceiverDetails: true,
        showReceiverEditButton: false,
        showCarrierDetails: true,
        showCarrierEditButton: false,
      });
    } else if (response.senderUndefined && response.receiverUndefined) {
      updateState(response, {
        showSenderDetails: true,
        showSenderEditButton: false,
        showReceiverDetails: true,
        showReceiverEditButton: false,
        showCarrierDetails: false,
        showCarrierEditButton: true,
      });
    } else if (response.senderUndefined && response.efCarrierUndefined) {
      updateState(response, {
        showSenderDetails: true,
        showSenderEditButton: false,
        showReceiverDetails: false,
        showReceiverEditButton: true,
        showCarrierDetails: true,
        showCarrierEditButton: false,
      });
    } else if (response.receiverUndefined && response.efCarrierUndefined) {
      updateState(response, {
        showSenderDetails: false,
        showSenderEditButton: true,
        showReceiverDetails: true,
        showReceiverEditButton: false,
        showCarrierDetails: true,
        showCarrierEditButton: false,
      });
    } else if (response.senderUndefined) {
      updateState(response, {
        showSenderDetails: true,
        showSenderEditButton: false,
      });
    } else if (response.receiverUndefined) {
      updateState(response, {
        showSenderDetails: false,
        showSenderEditButton: true,
        showReceiverDetails: true,
        showReceiverEditButton: false,
        showCarrierDetails: false,
        showCarrierEditButton: true,
      });
    } else if (response.efCarrierUndefined) {
      updateState(response, {
        showSenderDetails: false,
        showSenderEditButton: true,
        showReceiverDetails: false,
        showReceiverEditButton: true,
        showCarrierDetails: true,
        showCarrierEditButton: false,
      });
    } else {
      updateState(response, {
        showSenderDetails: false,
        showSenderEditButton: true,
        showReceiverDetails: false,
        showReceiverEditButton: true,
        showCarrierDetails: false,
        showCarrierEditButton: true,
      });
    }

    // Si el usuario tiene un rol asignado en el viaje
    if (response.senderMail.split(",")[0] === this.props.myEmployee.mail) {
      this.setState({ userIsSender: true });
    }
    if (response.receiverMail.split(",")[0] === this.props.myEmployee.mail) {
      this.setState({ userIsReceiver: true });
    }
    if (response.efCarrierMail.split(",")[0] === this.props.myEmployee.mail) {
      this.setState({ userIsEfCarrier: true });
    }

    // Si el viaje no se creo con el metodo nuevo
    if (
      this.state.userIsShipper === false &&
      this.state.userIsSender === false &&
      this.state.userIsReceiver === false &&
      this.state.userIsEfCarrier === false
    ) {
      this.setState({ userIsShipper: true });
      this.setState({ userIsSender: true });
      this.setState({ userIsReceiver: true });
      this.setState({ userIsEfCarrier: true });
    }

    // //console.log("myemployee email: ", this.props.myEmployee.mail);
    // //console.log("SenderMail: ", response.senderMail.split(",")[0]);
    // //console.log("receiverMail: ", response.receiverMail.split(",")[0]);
    // //console.log("efCarrierMail: ", response.efCarrierMail.split(",")[0]);

    // //console.log("myemployee Id: ", this.props.myEmployee.id);
    // Console que siempre es undefined porque response no contiene id
    ////console.log("sender id", response.sender_id);
    if (response.sender_id === (await this.props.myEmployee.id)) {
      this.setState({ showSenderDetails: true });
      this.setState({ showSenderEditButton: false });
    }
  }

  async getUserRole(token, tripId) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let url = (await this.props.endPoint) + "trip/my-role/" + tripId;
    let response = await fetch(url, requestOptions).then((response) => response.json());

    ////console.log("response getUserRole: ", response);
    return response;
  }

  async details(id, marks_num, num_bul, packaging, nat_mer, no_stat, peso, volumen) {
    var token = await this.props.userToken;

    //console.log();
    let requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({
        a: id,
        b: marks_num,
        c: num_bul,
        d: packaging,
        e: nat_mer,
        f: no_stat,
        g: peso,
        h: volumen,
      }),
    };

    let response = await fetch((await this.props.endPoint) + "trip/details/", requestOptions).then((response) => response.json());

    switch (response) {
      case "forbidden":
        await this.props.showError("No tienes acceso a esta accion");
        break;
    }
    await this.props.showEditTripFields(id, response);
    //await this.props.search(id)
    //await this.props.showEditTripFields(tripId)

    // //console.log("response", response.details_list)
    // //console.log("id", id)
    // //console.log("response", response["details_list"])
  }

  async hideForms() {
    // hide the elements with id "assign_sender"
    let assign_sender = document.getElementById("assign_sender");
    assign_sender.style.display = "none";
    let assign_receiver = document.getElementById("assign_receiver");
    assign_receiver.style.display = "none";
    let assign_EfCarrier = document.getElementById("assign_EfCarrier");
    assign_EfCarrier.style.display = "none";
    let assign_carrierComments = document.getElementById("assign_carrierComments");
    assign_carrierComments.style.display = "none";
    let marks_num = document.getElementById("marks_num");
    marks_num.style.display = "none";
    let num_bul = document.getElementById("num_bul");
    num_bul.style.display = "none";
    let packaging = document.getElementById("packaging");
    packaging.style.display = "none";
    let edit_details = document.getElementById("edit_details");
    edit_details.style.display = "none";
    let nat_mer = document.getElementById("nat_mer");
    nat_mer.style.display = "none";
    let no_stat = document.getElementById("no_stat");
    no_stat.style.display = "none";
    let peso = document.getElementById("peso");
    peso.style.display = "none";
    let volumen = document.getElementById("volumen");
    volumen.style.display = "none";
    let assign_senderComments = document.getElementById("assign_senderComments");
    assign_senderComments.style.display = "none";
    let assign_receiverComments = document.getElementById("assign_receiverComments");
    assign_receiverComments.style.display = "none";
    let sign_sender = document.getElementById("sign_sender");
    sign_sender.style.display = "none";
    let sign_efCarrier = document.getElementById("sign_efCarrier");
    sign_efCarrier.style.display = "none";
    let sign_receiver = document.getElementById("sign_receiver");
    sign_receiver.style.display = "none";
    let print = document.getElementById("print");
    print.style.display = "none";
  }

  print = () => {
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    let html = this.extractHtml();
    pri.document.open();
    pri.document.write(html);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  // Método para mostrar los inputs del sender
  showInputs = () => {
    this.setState({ showSenderDetails: true });
    this.setState({ showSenderEditButton: false });
  };

  // Método para ocultar los inputs del sender
  hideInputs = () => {
    this.setState({ showSenderDetails: false });
    this.setState({ showSenderEditButton: true });
  };

  // Método para mostrar los inputs del receiver
  showInputs = () => {
    this.setState({ showReceiverDetails: true });
    this.setState({ showReceiverEditButton: false });
  };

  // Método para ocultar los inputs del receiver
  hideInputs = () => {
    this.setState({ showReceiverDetails: false });
    this.setState({ showReceiverEditButton: true });
  };

  // Método para mostrar los inputs del carrier
  showInputs = () => {
    this.setState({ showCarrierDetails: true });
    this.setState({ showCarrierEditButton: false });
  };

  // Método para ocultar los inputs del carrier
  hideInputs = () => {
    this.setState({ showCarrierDetails: false });
    this.setState({ showCarrierEditButton: true });
  };

  render() {
    // var cmr;
    var receiver = this.state.receiverMail;
    var sender = this.state.senderMail;
    var efCarrier = this.state.efCarrierMail;
    var width = this.state.width;
    var height = width * (3 / 2);
    var eMailSender = this.state.senderMail.split(",")[0];
    var eMailReceiver = this.state.receiverMail.split(",")[0];
    var eMailefCarrier = this.state.efCarrierMail.split(",")[0];
    var isDisabledShipper = !this.state.userIsShipper;
    var isDisabledSender = !this.state.userIsSender;
    var isDisabledReceiver = !this.state.userIsReceiver;
    var isDisabledEfCarrier = !this.state.userIsEfCarrier;
    // //console.log("Rol del usuario es shipper: ", this.state.userIsShipper);
    // //console.log("Rol del usuario es sender: ", this.state.userIsSender);
    // //console.log("Rol del usuario es receiver: ", this.state.userIsReceiver);
    // //console.log("Rol del usuario es efCarrier: ", this.state.userIsEfCarrier);

    ////console.log("trip_date", this.state.myTrip.trip_date)
    ////console.log("Date",new Date(parseInt(this.state.myTrip.trip_date)*86400000 + Date.parse(new Date(2022, 0, 1))))

    if (this.props.device === "desktop") {
      if (this.props.noWallet === true) {
        return (
          <div className="card w-98 mx-auto mt-80p" id="divcontents">
            {this.props.loading && (
              <div className="custom-spinner">
                <CustomSpinner device={this.props.device} />
              </div>
            )}
            {/* <Navbar
              showError={this.props.showError}
              account={this.props.account}
              device={this.props.device}
              product={this.props.product}
              selectProduct={this.props.selectProduct}
              showCreationFields={this.props.showCreationFields}
              usersEmployeeCount={this.props.usersEmployeeCount}
              usersEmployeeLoad={this.props.usersEmployeeLoad}
              userRole={this.props.userRole}
              askNewPass={this.props.askNewPass}
              selectANDirecto={this.props.selectANDirecto}
              showANDirecto={this.props.showANDirecto}
              showNavigatorModal={this.props.showNavigatorModal}
              showLoginModal={this.props.showLoginModal}
              showRegisterModal={this.props.showRegisterModal}
              winWidth={this.props.winWidth}
              winHeight={this.props.winHeight}
              volverAlPasado={this.props.volverAlPasado}
              regresoAlFuturo={this.props.regresoAlFuturo}
              stateHistory={this.props.stateHistory}
              stateFuture={this.props.stateFuture}
              showTripModal={this.props.showTripModal}
            /> */}
            <iframe id="ifmcontentstoprint" className="w-0p h-0p position-absolute"></iframe>
            <div className="card-body">
              {/* Tabla datos remitente y CMR */}
              <table className="w-96 h-222p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila remitente */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column h-100">
                        <div className="fw-bold">
                          <label>Remitente</label>
                        </div>
                        <div className="text-success mb-10p">{sender}</div>
                        <form
                          className="mb-10p"
                          id="assign_sender"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            let sender = this.sender.value;
                            this.assignSender(id, sender);
                          }}
                        >
                          {this.state.showSenderDetails && (
                            <div className="position-absolute bottom-10 start-20">
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Nombre:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(senderName) => {
                                    this.senderName = senderName;
                                  }}
                                  type="text"
                                  disabled={isDisabledSender && isDisabledShipper}
                                  required
                                />
                              </div>
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Dirección:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(senderAddress) => {
                                    this.senderAddress = senderAddress;
                                  }}
                                  type="text"
                                  disabled={isDisabledSender && isDisabledShipper}
                                  required
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <label className="d-inline-block w-140p">Correo electrónico:</label>
                            <input
                              className="form-control d-inline-block w-300p text-success text-end"
                              ref={(sender) => {
                                this.sender = sender;
                              }}
                              type="email"
                              defaultValue={eMailSender}
                              disabled={isDisabledSender && isDisabledShipper}
                              required
                            />
                          </div>
                          {this.state.showSenderEditButton && (
                            <div>
                              <div className="position-absolute bottom-10 end-20">
                                <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledSender && isDisabledShipper}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                        {this.state.showSenderDetails && (
                          <form
                            id="assign_sender2"
                            onSubmit={(event) => {
                              event.preventDefault();
                              let id = this.state.myTrip.id;
                              let sender = this.sender.value;
                              let senderName = this.senderName.value;
                              let senderAddress = this.senderAddress.value;
                              this.assignSender2(id, sender, senderAddress, senderName);
                            }}
                          >
                            <div className="position-absolute bottom-10 end-20">
                              <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledSender && isDisabledShipper}>
                                Guardar
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </td>
                    {/* Fila CMR */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top">
                      <div className="d-flex flex-column">
                        <div className="fw-bold">
                          <p>CARTE DE PORTE INTERNACIONAL</p>
                        </div>
                        <div>
                          <p>
                            Este Transporte queda sometido, no obstante toda claúsula contraria, al Convenio sobre el Contrato de Transporte Internacional de
                            Mercancías por Carretera (CMR)
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla datos consignatario y datos porteador */}
              <table className="w-96 h-222p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila datos consignatario */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column h-100">
                        <div className="fw-bold">
                          <label>Consignatario</label>
                        </div>
                        <div className="mb-10p text-success ">{receiver}</div>
                        <form
                          className="mb-10p"
                          id="assign_receiver"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            let receiver = this.receiver.value;
                            this.assignReceiver(id, receiver);
                          }}
                        >
                          {this.state.showReceiverDetails && (
                            <div className="position-absolute bottom-10 start-20">
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Nombre:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(receiverName) => {
                                    this.receiverName = receiverName;
                                  }}
                                  type="text"
                                  disabled={isDisabledReceiver && isDisabledShipper}
                                  required
                                />
                              </div>
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Dirección:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(receiverAddress) => {
                                    this.receiverAddress = receiverAddress;
                                  }}
                                  type="text"
                                  disabled={isDisabledReceiver && isDisabledShipper}
                                  required
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <label className="d-inline-block w-140p">Correo electrónico:</label>
                            <input
                              className="form-control d-inline-block w-300p text-success text-end"
                              ref={(receiver) => {
                                this.receiver = receiver;
                              }}
                              type="email"
                              defaultValue={eMailReceiver}
                              disabled={isDisabledReceiver && isDisabledShipper}
                              required
                            />
                          </div>
                          {this.state.showReceiverEditButton && (
                            <div>
                              <div className="position-absolute bottom-10 end-20">
                                <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledReceiver && isDisabledShipper}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                        {this.state.showReceiverDetails && (
                          <form
                            id="assign_receiver2"
                            onSubmit={(event) => {
                              event.preventDefault();
                              let id = this.state.myTrip.id;
                              let receiver = this.receiver.value;
                              let receiverName = this.receiverName.value;
                              let receiverAddress = this.receiverAddress.value;
                              this.assignReceiver2(id, receiver, receiverAddress, receiverName);
                            }}
                          >
                            <div className="position-absolute bottom-10 end-20">
                              <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledReceiver && isDisabledShipper}>
                                Guardar
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </td>
                    {/* Fila datos porteador */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column h-100">
                        <div className="fw-bold">
                          <label>Porteador</label>
                        </div>
                        <div className="d-flex justify-content-start">
                          <div className="w-60 mb-10p">
                            <label className="text-success">{efCarrier}</label>
                          </div>
                          <div className="w-40 mb-10p">
                            Empresa:<label className="text-success">{this.state.carrierMail}</label>
                          </div>
                        </div>
                        <form
                          className="mb-10p"
                          id="assign_EfCarrier"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            let efCarrier = this.efCarrier.value;
                            this.assignEfCarrier(id, efCarrier);
                          }}
                        >
                          {this.state.showCarrierDetails && (
                            <div className="position-absolute bottom-10 start-20">
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Nombre:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(efCarrierName) => {
                                    this.efCarrierName = efCarrierName;
                                  }}
                                  type="text"
                                  disabled={isDisabledEfCarrier && isDisabledShipper}
                                  required
                                />
                              </div>
                              <div className="mb-10p">
                                <label className="d-inline-block w-140p">Dirección:</label>
                                <input
                                  className="form-control d-inline-block w-300p text-success text-end"
                                  ref={(efCarrierAddress) => {
                                    this.efCarrierAddress = efCarrierAddress;
                                  }}
                                  type="text"
                                  disabled={isDisabledEfCarrier && isDisabledShipper}
                                  required
                                />
                              </div>
                            </div>
                          )}
                          <div>
                            <label className="d-inline-block w-140p">Correo electrónico:</label>
                            <input
                              className="form-control d-inline-block w-300p text-success text-end"
                              ref={(efCarrier) => {
                                this.efCarrier = efCarrier;
                              }}
                              type="email"
                              defaultValue={eMailefCarrier}
                              disabled={isDisabledEfCarrier && isDisabledShipper}
                              required
                            />
                          </div>
                          {this.state.showCarrierEditButton && (
                            <div>
                              <div className="position-absolute bottom-10 end-20">
                                <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledEfCarrier && isDisabledShipper}>
                                  Editar
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                        {this.state.showCarrierDetails && (
                          <form
                            id="assign_EfCarrier2"
                            onSubmit={(event) => {
                              event.preventDefault();
                              let id = this.state.myTrip.id;
                              let efCarrier = this.efCarrier.value;
                              let efCarrierName = this.efCarrierName.value;
                              let efCarrierAddress = this.efCarrierAddress.value;
                              this.assignEfCarrier2(id, efCarrier, efCarrierName, efCarrierAddress);
                            }}
                          >
                            <div className="position-absolute bottom-10 end-20">
                              <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledEfCarrier && isDisabledShipper}>
                                Guardar
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla origen y porteadores */}
              <table className="w-96 h-140p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila origen */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p align-top">
                      <div className="container">
                        <div className="row">
                          <div className="col-6 fw-bold">
                            <label>Origen</label>
                          </div>
                          <div className="col-6 fw-bold">
                            <label>Fecha</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <label className="text-success">{this.state.myTrip.origin}</label>
                          </div>
                          <div className="col-6">
                            <label className="text-success">
                              {new Intl.DateTimeFormat("es-ES", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(new Date(parseInt(this.state.myTrip.trip_date) * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* Fila porteadores */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top">
                      <div className="d-flex flex-column">
                        <div className="fw-bold">
                          <label>Porteadores Sucesivos</label>
                        </div>
                        <div>
                          <label>Deshabilitado</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla destino y comentarios porteador */}
              <table className="w-96 h-222p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila destino */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p align-top">
                      <div className="container">
                        <div className="row">
                          <div className="col-6 fw-bold">
                            <label>Destino</label>
                          </div>
                          <div className="col-6 fw-bold">
                            <label>Fecha</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <label className="text-success">{this.state.myTrip.destination}</label>
                          </div>
                          <div className="col-6">
                            <label className="text-success">
                              {new Intl.DateTimeFormat("es-ES", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(new Date(this.state.myTrip.trip_date * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* Fila comentarios porteador */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top position-relative">
                      <div className="d-flex d-column h-100">
                        <div className="fw-bold">
                          <label>Comentarios Porteador</label>
                        </div>
                        <div className="mw-415p mh-75p overflow-auto text-break">
                          <label className="d-block text-success">{this.state.myTrip.carrier_comments}</label>
                        </div>
                      </div>
                      <form
                        id="assign_carrierComments"
                        onSubmit={(event) => {
                          event.preventDefault();
                          let id = this.state.myTrip.id;
                          let carrierComments = this.carrierComments.value;
                          this.assigncarrierComments(id, carrierComments);
                        }}
                      >
                        <div className="position-absolute bottom-10 start-20 text-success">
                          <textarea
                            name="paragraph_text"
                            cols="50"
                            rows="3"
                            ref={(carrierComments) => {
                              this.carrierComments = carrierComments;
                            }}
                            className="form-control textarea-cmr text-success"
                            type="text"
                            style={{ height: "100 px" }}
                            disabled={isDisabledEfCarrier}
                          />
                        </div>
                        <div className="position-absolute bottom-10 end-20">
                          <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledEfCarrier}>
                            Editar
                          </button>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla documentos y vacia */}
              <table className="w-96 h-100p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila documentos */}
                    <td className="w-50 border border-cmr border-bottom-0 p-10p plr-20p align-top">
                      <div className="d-flex flex-column">
                        <div className="fw-bold">
                          <label>Documentos Anexos</label>
                        </div>
                        <div>
                          <label>Deshabilitado</label>
                        </div>
                      </div>
                    </td>
                    {/* Fila vacia */}
                    <td className="w-50 border border-cmr border-bottom-0"></td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla detalles */}
              <table className="w-96 h-200p mx-auto">
                <tbody>
                  <tr>
                    <td className="w-100 border border-cmr border-bottom-0 p-10p plr-20p align-top position-relative">
                      <div className="h-100">
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            const id = this.state.myTrip.id;
                            const { listDetails } = this.state;
                            const [prevMarksNum, prevNumBul, prevPackaging, prevNatMer, prevNoStat, prevPeso, prevVolumen] = listDetails;
                            const marks_num = this.marks_num.value || prevMarksNum;
                            const num_bul = this.num_bul.value || prevNumBul;
                            const packaging = this.packaging.value || prevPackaging;
                            const nat_mer = this.nat_mer.value || prevNatMer;
                            const no_stat = this.no_stat.value || prevNoStat;
                            const peso = this.peso.value || prevPeso;
                            const volumen = this.volumen.value || prevVolumen;
                            this.details(id, marks_num, num_bul, packaging, nat_mer, no_stat, peso, volumen);
                          }}
                        >
                          <div className="row">
                            <div className="col">
                              <label htmlFor="marks_num" className="fw-bold form-label mb-20p">
                                Marcas y números
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="num_bul" className="fw-bold form-label mb-20p">
                                Número de bultos
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="packaging" className="fw-bold form-label mb-20p">
                                Embalaje
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="nat_mer" className="fw-bold form-label mb-20p">
                                Naturaleza mercancía
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="no_stat" className="fw-bold form-label mb-20p">
                                Nº estadístico
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="peso" className="fw-bold form-label mb-20p">
                                Peso en Kg
                              </label>
                            </div>
                            <div className="col">
                              <label htmlFor="volumen" className="fw-bold form-label mb-20p">
                                Volumen en m3
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[0]}</label>
                              <input
                                ref={(marks_num) => {
                                  this.marks_num = marks_num;
                                }}
                                type="text"
                                className="form-control text-success text-end"
                                id="marks_num"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[1]}</label>
                              <input
                                ref={(num_bul) => {
                                  this.num_bul = num_bul;
                                }}
                                type="number"
                                className="form-control text-success text-end"
                                id="num_bul"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[2]}</label>
                              <input
                                ref={(packaging) => {
                                  this.packaging = packaging;
                                }}
                                type="text"
                                className="form-control text-success text-end"
                                id="packaging"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[3]}</label>
                              <input
                                ref={(nat_mer) => {
                                  this.nat_mer = nat_mer;
                                }}
                                type="text"
                                className="form-control text-success text-end"
                                id="nat_mer"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[4]}</label>
                              <input
                                ref={(no_stat) => {
                                  this.no_stat = no_stat;
                                }}
                                type="text"
                                className="form-control text-success text-end"
                                id="no_stat"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[5]}</label>
                              <input
                                ref={(peso) => {
                                  this.peso = peso;
                                }}
                                type="number"
                                className="form-control text-success text-end"
                                id="peso"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                            <div className="col text-end">
                              <label className="h-10p text-success">{this.state.listDetails[6]}</label>
                              <input
                                ref={(volumen) => {
                                  this.volumen = volumen;
                                }}
                                type="number"
                                className="form-control text-success text-end"
                                id="volumen"
                                disabled={isDisabledShipper && isDisabledSender}
                              />
                            </div>
                          </div>
                          <div className="position-absolute bottom-10 end-20">
                            <button type="submit" id="edit_details" className="btn btn-primary w-100p" disabled={isDisabledShipper && isDisabledSender}>
                              Editar
                            </button>
                          </div>
                        </form>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla instrucciones remitente y comentarios consignatario */}
              <table className="w-96 h-222p mx-auto">
                <tbody>
                  <tr>
                    {/* Fila instrucciones remitente */}
                    <td className="w-50 border border-cmr p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column h-100">
                        <div className="fw-bold">
                          <label>Instrucciones Remitente</label>
                        </div>
                        <div className="mw-415p mh-75p overflow-auto text-break">
                          <label className="d-block text-success">{this.state.myTrip.sender_instruction}</label>
                        </div>
                      </div>
                      <form
                        id="assign_senderComments"
                        onSubmit={(event) => {
                          event.preventDefault();
                          let id = this.state.myTrip.id;
                          let sendComment = this.sendComment.value;
                          this.assignsenderComments(id, sendComment);
                        }}
                      >
                        <div className="position-absolute bottom-10 start-20">
                          <textarea
                            name="paragraph_text"
                            cols="50"
                            rows="3"
                            ref={(sendComment) => {
                              this.sendComment = sendComment;
                            }}
                            className="form-control textarea-cmr text-success"
                            type="text"
                            disabled={isDisabledSender && isDisabledShipper}
                          />
                        </div>
                        <div className="position-absolute bottom-10 end-20">
                          <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledSender && isDisabledShipper}>
                            Editar
                          </button>
                        </div>
                      </form>
                    </td>
                    {/* Fila comentarios consignatario */}
                    <td className="w-50 border border-cmr p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column h-100">
                        <div className="fw-bold">
                          <label>Comentarios Consignatario</label>
                        </div>
                        <div className="mw-415p mh-75p overflow-auto text-break">
                          <label className="d-block text-success">{this.state.myTrip.receiver_comments}</label>
                        </div>
                      </div>
                      <form
                        id="assign_receiverComments"
                        onSubmit={(event) => {
                          event.preventDefault();
                          let id = this.state.myTrip.id;
                          let receiverComment = this.receiverComment.value;
                          this.assignreceiverComments(id, receiverComment);
                        }}
                      >
                        <div className="position-absolute bottom-10 start-20">
                          <textarea
                            name="paragraph_text"
                            cols="50"
                            rows="3"
                            ref={(receiverComment) => {
                              this.receiverComment = receiverComment;
                            }}
                            className="form-control textarea-cmr text-success"
                            type="text"
                            disabled={isDisabledReceiver}
                          />
                        </div>
                        <div className="position-absolute bottom-10 end-20">
                          <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledReceiver}>
                            Editar
                          </button>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla firmas */}
              <table className="w-96 h-200p mx-auto mt-20p">
                <tbody className="w-100">
                  <tr>
                    <td className="w-30 align-bottom">
                      <label className="fw-bold">Sello Remitente</label>
                    </td>
                    <td className="w-3"></td>
                    <td className="w-30  align-bottom">
                      <label className="fw-bold">Sello Porteador</label>
                    </td>
                    <td className="w-3"></td>
                    <td className="w-30  align-bottom">
                      <label className="fw-bold">Sello Consignatario</label>
                    </td>
                  </tr>
                  <tr className="w-100">
                    <td className="w-30 border border-cmr p-10p plr-20p align-top position-relative">
                      <div className="d-flex flex-column">
                        <div>
                          <label>
                            Fecha: <label className="text-success">{this.state.signedBySenderDate}</label>
                          </label>
                        </div>
                        <div>
                          <label>
                            Firma: <label className="fs-cmr-sign text-success">{this.state.signedBySender}</label>
                          </label>
                        </div>
                      </div>
                      <div>
                        <form
                          id="sign_sender"
                          className="w-100"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            this.sign("sender", id);
                          }}
                        >
                          <div className="position-absolute bottom-10 end-20">
                            <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledSender}>
                              Firmar
                            </button>
                          </div>
                        </form>
                      </div>
                    </td>
                    <td className="w-3"></td>
                    <td className="w-30 border border-cmr p-10p plr-20p align-top position-relative">
                      <div>
                        <div>
                          <label>
                            Fecha: <label className="text-succcess">{this.state.signedByCarrierDate}</label>
                          </label>
                        </div>
                        <div>
                          <label>
                            Firma: <label className="fs-cmr-sign text-success"> {this.state.signedByCarrier}</label>
                          </label>
                        </div>
                      </div>
                      <div>
                        <form
                          id="sign_efCarrier"
                          className="w-100"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            this.sign("carrier", id);
                          }}
                        >
                          <div className="position-absolute bottom-10 end-20">
                            <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledEfCarrier}>
                              Firmar
                            </button>
                          </div>
                        </form>
                      </div>
                    </td>
                    <td className="w-3"></td>
                    <td className="w-30 border border-cmr p-10p plr-20p align-top position-relative">
                      <div>
                        <div>
                          <label>
                            Fecha: <label className="text-success">{this.state.signedByReceiverDate}</label>
                          </label>
                        </div>
                        <div>
                          <label>
                            Firma:<label className="fs-cmr-sign text-success"> {this.state.signedByReceiver}</label>
                          </label>
                        </div>
                      </div>
                      <div>
                        <form
                          id="sign_receiver"
                          className="w-100"
                          onSubmit={(event) => {
                            event.preventDefault();
                            let id = this.state.myTrip.id;
                            this.sign("receiver", id);
                          }}
                        >
                          <div className="position-absolute bottom-10 end-20">
                            <button type="submit" className="btn btn-primary w-100p" disabled={isDisabledReceiver}>
                              Firmar
                            </button>
                          </div>
                        </form>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Tabla botones */}
              <table className="w-96 mx-auto m-40p">
                <td>
                  <form
                    id="print"
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.print();
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="mr-30p">
                        <button type="button" className="btn btn-primary w-200p" onClick={this.sendEmailCMR}>
                          <label>Enviar Correo</label>
                        </button>
                      </div>
                      <div className="mr-30p">
                        <button type="submit" className="btn btn-primary w-200p">
                          <label>Imprimir</label>
                        </button>
                      </div>
                      <div>
                        <button type="button" className="btn btn-danger w-200p" onClick={this.props.onClose}>
                          <label>Cerrar</label>
                        </button>
                      </div>
                    </div>
                  </form>
                </td>
              </table>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      if (this.props.noWallet === true) {
        return (
          <div className="card mb-4 mt-100p" id="divcontents">
            {this.props.loading && (
              <div className="custom-spinner">
                <CustomSpinner device={this.props.device} />
              </div>
            )}
            <Navbar
              showError={this.props.showError}
              account={this.props.account}
              device={this.props.device}
              product={this.props.product}
              selectProduct={this.props.selectProduct}
              showCreationFields={this.props.showCreationFields}
              usersEmployeeCount={this.props.usersEmployeeCount}
              usersEmployeeLoad={this.props.usersEmployeeLoad}
              userRole={this.props.userRole}
              askNewPass={this.props.askNewPass}
              selectANDirecto={this.props.selectANDirecto}
              showANDirecto={this.props.showANDirecto}
              showNavigatorModal={this.props.showNavigatorModal}
              showLoginModal={this.props.showLoginModal}
              showRegisterModal={this.props.showRegisterModal}
              winWidth={this.props.winWidth}
              winHeight={this.props.winHeight}
              volverAlPasado={this.props.volverAlPasado}
              regresoAlFuturo={this.props.regresoAlFuturo}
              stateHistory={this.props.stateHistory}
              stateFuture={this.props.stateFuture}
              showTripModal={this.props.showTripModal}
            />
            <iframe id="ifmcontentstoprint" className=" h-0p w-0p position-absolute" />
            <div className="card-body">
              <div className="mobile-container">
                <div className="text-justify">
                  <div className="fs-24 fw-bold mb-3">
                    <p>CARTE DE PORTE INTERNACIONAL</p>
                  </div>

                  <div>
                    <p className="fs-22">
                      Este Transporte queda sometido, no obstante toda claúsula contraria, al Convenio sobre el Contrato de Transporte Internacional de
                      Mercancías por Carretera (CMR)
                    </p>
                  </div>
                </div>
                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Remitente</p>
                  </label>
                  <div className="fs-22 mb-2 text-success">
                    <label>{sender}</label>
                  </div>
                  <form
                    id="assign_sender"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let sender = this.sender.value;
                      this.assignSender(id, sender);
                    }}
                  >
                    <div className="d-flex align-items-center  ">
                      <table className="w-100 ">
                        <tbody>
                          <tr>
                            <td className=" text-start v-align-m w-30 ">
                              <p className=" fs-22">Email:</p>
                            </td>
                            <td className=" text-end v-align-m w-70">
                              <input
                                ref={(sender) => {
                                  this.sender = sender;
                                }}
                                type="email"
                                defaultValue={eMailSender}
                                disabled={isDisabledSender && isDisabledShipper}
                                required
                                className="form-control form-control-lg w-97 ml-10 mb-3 text-success text-start"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {this.state.showSenderEditButton && (
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100" disabled={isDisabledSender && isDisabledShipper}>
                        Editar
                      </button>
                    )}
                  </form>
                </div>
                {this.state.showSenderDetails && (
                  <form
                    id="assign_sender2"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let sender = this.sender.value;
                      let senderName = this.senderName.value;
                      let senderAddress = this.senderAddress.value;
                      this.assignSender2(id, sender, senderAddress, senderName);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Nombre:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(senderName) => {
                                  this.senderName = senderName;
                                }}
                                type="text"
                                required
                                disabled={isDisabledSender && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 mb-3 text-success text-start"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Dirección:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(senderAddress) => {
                                  this.senderAddress = senderAddress;
                                }}
                                type="text"
                                required
                                disabled={isDisabledSender && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 text-success text-start"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledSender && isDisabledShipper}>
                      Guardar
                    </button>
                  </form>
                )}
                <hr></hr>
                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Consignatario</p>
                  </label>
                  <div className="fs-22 mb-2 text-success">
                    <label>{receiver}</label>
                  </div>
                  <form
                    id="assign_receiver"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let receiver = this.receiver.value;
                      this.assignReceiver(id, receiver);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <table className="w-100 ">
                        <tbody>
                          <tr>
                            <td className="text-start v-align-m w-30 ">
                              <p className="fs-22">Email:</p>
                            </td>
                            <td className="text-end v-align-m w-70 ">
                              <input
                                ref={(receiver) => {
                                  this.receiver = receiver;
                                }}
                                type="email"
                                required
                                defaultValue={eMailReceiver}
                                disabled={isDisabledReceiver && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 mb-3 text-success text-start"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {this.state.showReceiverEditButton && (
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100 " disabled={isDisabledReceiver && isDisabledShipper}>
                        Editar
                      </button>
                    )}
                  </form>
                </div>
                {this.state.showReceiverDetails && (
                  <form
                    id="assign_receiver2"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let receiver = this.receiver.value;
                      let receiverName = this.receiverName.value;
                      let receiverAddress = this.receiverAddress.value;
                      this.assignReceiver2(id, receiver, receiverAddress, receiverName);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Nombre:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(receiverName) => {
                                  this.receiverName = receiverName;
                                }}
                                type="text"
                                required
                                disabled={isDisabledReceiver && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 mb-3 text-success text-end"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Dirección:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(receiverAddress) => {
                                  this.receiverAddress = receiverAddress;
                                }}
                                type="text"
                                required
                                disabled={isDisabledReceiver && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 text-success text-end"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledReceiver && isDisabledShipper}>
                      Guardar
                    </button>
                  </form>
                )}
                <hr></hr>
                <div>
                  <div>
                    <label>
                      <p className="fs-26 fw-bold mb-3">Porteador</p>
                    </label>
                  </div>
                  <div className="mb-1">
                    <label className="fs-22">Empresa:{this.state.carrierMail}</label>
                  </div>
                  <label className="fs-22 mb-3 ">Conductor: </label>
                  <div className="fs-22 mb-2 text-success">
                    <label>{efCarrier}</label>
                  </div>
                  <form
                    id="assign_EfCarrier"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let efCarrier = this.efCarrier.value;
                      this.assignEfCarrier(id, efCarrier);
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className=" text-start v-align w-30 ">
                              <p className="fs-22">Email:</p>
                            </td>
                            <td className=" text-end v-align-m w-70">
                              <input
                                ref={(efCarrier) => {
                                  this.efCarrier = efCarrier;
                                }}
                                type="email"
                                required
                                defaultValue={eMailefCarrier}
                                disabled={isDisabledEfCarrier && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 text-success text-end"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {this.state.showCarrierEditButton && (
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledEfCarrier && isDisabledShipper}>
                        Editar
                      </button>
                    )}
                  </form>
                </div>
                {this.state.showCarrierDetails && (
                  <form
                    id="assign_EfCarrier2"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let efCarrier = this.efCarrier.value;
                      let efCarrierName = this.efCarrierName.value;
                      let efCarrierAddress = this.efCarrierAddress.value;
                      this.assignEfCarrier2(id, efCarrier, efCarrierName, efCarrierAddress);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Nombre:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(efCarrierName) => {
                                  this.efCarrierName = efCarrierName;
                                }}
                                type="text"
                                required
                                disabled={isDisabledEfCarrier && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 mb-3 text-success text-end"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start v-align-m w-30">
                              <label className="fs-22">Dirección:</label>
                            </td>
                            <td className="text-end v-align-m w-70">
                              <input
                                ref={(efCarrierAddress) => {
                                  this.efCarrierAddress = efCarrierAddress;
                                }}
                                type="text"
                                required
                                disabled={isDisabledEfCarrier && isDisabledShipper}
                                className="form-control form-control-lg w-97 ml-10 text-success text-end"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledEfCarrier && isDisabledShipper}>
                      Guardar
                    </button>
                  </form>
                )}
                <hr></hr>
                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Porteadores Sucesivos </p>
                    <p className="fs-22">Deshabilitado </p>
                  </label>
                </div>
                <hr></hr>

                <div>
                  <div className="mb-3">
                    <label>
                      <p className="fs-26 fw-bold">Datos del viaje</p>
                    </label>
                  </div>
                  <div className="mb-1">
                    <label>
                      <p>
                        <label className="fs-21 fw-bold">Origen: </label>
                        <label className="fs-20">{this.state.myTrip.origin}</label>
                      </p>
                    </label>
                  </div>

                  <div className="mb-3">
                    <label>
                      <p>
                        <label className="fs-21 fw-bold">Fecha: </label>
                        <label className="fs-20">
                          {new Intl.DateTimeFormat("es-ES", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(parseInt(this.state.myTrip.trip_date) * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                        </label>
                      </p>
                    </label>
                  </div>
                </div>

                <div>
                  <div className="mb-1">
                    <label>
                      <p>
                        <label className="fs-21 fw-bold">Destino: </label>
                        <label className="fs-20">{this.state.myTrip.destination}</label>
                      </p>
                    </label>
                  </div>
                  <label>
                    <p>
                      <label className="fs-21 fw-bold">Fecha: </label>
                      <label className="fs-20">
                        {new Intl.DateTimeFormat("es-ES", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        }).format(new Date(this.state.myTrip.trip_date * 86400000 + Date.parse(new Date(2022, 0, 1))))}
                      </label>
                    </p>
                  </label>
                </div>
                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Comentarios Porteador: </p>
                  </label>

                  {/* {this.state.myTrip.carrier_comments} */}

                  <form
                    id="assign_carrierComments"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let carrierComments = this.carrierComments.value;
                      this.assigncarrierComments(id, carrierComments);
                    }}
                  >
                    <textarea
                      ref={(carrierComments) => {
                        this.carrierComments = carrierComments;
                      }}
                      cols="30"
                      rows="5"
                      defaultValue={this.state.myTrip.carrier_comments}
                      disabled={isDisabledEfCarrier}
                      className="form-control form-control-lg w-96 ml-10 text-success text-start"
                    />
                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledEfCarrier}>
                      Editar
                    </button>
                  </form>
                </div>
                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Instrucciones del remitente: </p>
                  </label>

                  {/* {this.state.myTrip.sender_instruction} */}
                  <form
                    id="assign_senderComments"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let sendComment = this.sendComment.value;
                      this.assignsenderComments(id, sendComment);
                    }}
                  >
                    <textarea
                      ref={(sendComment) => {
                        this.sendComment = sendComment;
                      }}
                      cols="30"
                      rows="5"
                      defaultValue={this.state.myTrip.sender_instruction}
                      disabled={isDisabledSender && isDisabledShipper}
                      className="form-control form-control-lg w-96 ml-10 text-success text-start"
                    />
                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledSender && isDisabledShipper}>
                      Editar
                    </button>
                  </form>
                </div>
                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Comentarios del consignatario: </p>
                  </label>
                  {/* {this.state.myTrip.receiver_comments} */}
                  <form
                    id="assign_receiverComments"
                    onSubmit={(event) => {
                      event.preventDefault();
                      let id = this.state.myTrip.id;
                      let receiverComment = this.receiverComment.value;
                      this.assignreceiverComments(id, receiverComment);
                    }}
                  >
                    <textarea
                      ref={(receiverComment) => {
                        this.receiverComment = receiverComment;
                      }}
                      cols="30"
                      rows="5"
                      defaultValue={this.state.myTrip.receiver_comments}
                      disabled={isDisabledReceiver}
                      className="form-control form-control-lg w-96 ml-10 text-success text-start"
                    />

                    <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledReceiver}>
                      Editar
                    </button>
                  </form>
                </div>
                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Documentos anexos </p>
                    <p className="fs-22">Deshabilitado </p>
                  </label>
                </div>
                <hr></hr>
                <div>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      const id = this.state.myTrip.id;
                      const { listDetails } = this.state;
                      const [prevMarksNum, prevNumBul, prevPackaging, prevNatMer, prevNoStat, prevPeso, prevVolumen] = listDetails;
                      const marks_num = this.marks_num.value || prevMarksNum;
                      const num_bul = this.num_bul.value || prevNumBul;
                      const packaging = this.packaging.value || prevPackaging;
                      const nat_mer = this.nat_mer.value || prevNatMer;
                      const no_stat = this.no_stat.value || prevNoStat;
                      const peso = this.peso.value || prevPeso;
                      const volumen = this.volumen.value || prevVolumen;
                      this.details(id, marks_num, num_bul, packaging, nat_mer, no_stat, peso, volumen);
                    }}
                  >
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <label>
                              <p className="fs-26 fw-bold mb-3">Detalles del pedido:</p>
                            </label>
                          </td>
                        </tr>
                        {[
                          ["Marcas y números", "marks_num", this.state.listDetails[0]],
                          ["Número de bultos", "num_bul", this.state.listDetails[1]],
                          ["Embalaje", "packaging", this.state.listDetails[2]],
                          ["Naturaleza mercancía", "nat_mer", this.state.listDetails[3]],
                          ["Nº estadístico", "no_stat", this.state.listDetails[4]],
                          ["Peso en Kg", "peso", this.state.listDetails[5]],
                          ["Volumen en m³", "volumen", this.state.listDetails[6]],
                        ].map(([label, refName, defaultValue], index) => (
                          <tr key={index} className="d-flex align-items-center mb-3">
                            <td className="text-start v-align-m w-25">
                              <label className="fs-22">{label}:</label>
                            </td>
                            <td className="text-end v-align-m w-73">
                              <input
                                ref={(input) => {
                                  this[refName] = input;
                                }}
                                type="text"
                                id={refName}
                                defaultValue={defaultValue}
                                disabled={isDisabledShipper && isDisabledSender}
                                className="form-control form-control-lg w-100 ml-10 mb-2 text-success text-end"
                              />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="2" className="text-center">
                            <button
                              type="submit"
                              id="edit_details"
                              className="btn btn-primary btn-lg border-0 w-100"
                              disabled={isDisabledShipper && isDisabledSender}
                            >
                              Editar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>

                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold">Sello Remitente</p>
                  </label>
                  <div>
                    <div className="mb-1">
                      <label className="fs-22">Fecha: {this.state.signedBySenderDate}</label>
                    </div>
                    <label className="fs-22">
                      Firma: <label className="fs-17 text-success text-break">{this.state.signedBySender}</label>
                    </label>
                    <form
                      id="sign_sender"
                      onSubmit={(event) => {
                        event.preventDefault();
                        let id = this.state.myTrip.id;
                        this.sign("sender", id);
                      }}
                    >
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledSender}>
                        Firmar
                      </button>
                    </form>
                  </div>
                </div>

                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold">Sello Porteador</p>
                  </label>
                  <div>
                    <div className="mb-1">
                      <label className="fs-22">Fecha: {this.state.signedByCarrierDate}</label>
                    </div>
                    <label className="fs-22">
                      Firma: <label className="fs-17 text-success text-break">{this.state.signedByCarrier}</label>
                    </label>
                    <form
                      id="sign_efCarrier"
                      onSubmit={(event) => {
                        event.preventDefault();
                        let id = this.state.myTrip.id;
                        this.sign("carrier", id);
                      }}
                    >
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledEfCarrier}>
                        Firmar
                      </button>
                    </form>
                  </div>
                </div>

                <hr></hr>

                <div>
                  <label>
                    <p className="fs-26 fw-bold mb-3">Sello Consignatario</p>
                  </label>
                  <div>
                    <div className="mb-1">
                      <label className="fs-22">Fecha: {this.state.signedByReceiverDate}</label>
                    </div>
                    <label className="fs-22">
                      Firma: <label className="fs-22 text-success text-break">{this.state.signedByReceiver}</label>
                    </label>
                    <form
                      id="sign_receiver"
                      onSubmit={(event) => {
                        event.preventDefault();
                        let id = this.state.myTrip.id;
                        this.sign("receiver", id);
                      }}
                    >
                      <button type="submit" className="btn btn-primary btn-lg border-0 w-100 mt-3" disabled={isDisabledReceiver}>
                        Firmar
                      </button>
                    </form>
                  </div>
                </div>
                <hr></hr>

                <div>
                  <form
                    id="print"
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.print();
                    }}
                  >
                    <button type="button" className="btn btn-primary btn-block btn-lg border-0 w-100" onClick={this.sendEmailCMR}>
                      <label>Enviar Correo</label>
                    </button>
                    <button type="submit" className="btn btn-primary btn-block btn-lg border-0 w-100 mt-2">
                      Imprimir
                    </button>
                    <button type="button" className="btn btn-danger btn-block btn-lg border-0 w-100 mt-2" onClick={this.props.onClose}>
                      Cerrar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}

export default CMR;
