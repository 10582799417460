import React, { Component } from "react";
import './modal.css';

class ModalChangePass extends Component {
  async changePass(previousPass, password1, password2){
    try{
      if(await this.props.getOldPass(previousPass)){
        this.setState({incorrectPass:false})
        if (password1===password2 && password1.length>=8){
          this.setState({unequal:false})
          this.props.assignNewPass(password1)
        }else{
          this.setState({unequal:true})
        }
      }else{
        this.setState({incorrectPass:true})
        this.setState({unequal:false})
      }
    }catch(err){
      this.props.showError()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      unequal:false,
      incorrectPass:false
    }
  
    this.errorStatus = this.changePass.bind(this)
  }

  render (){
    try{
      var show = this.props.show
      var children = this.props.children
      let error = <div></div>

      if (this.state.incorrectPass){
        error = <div className="text-danger">Contraseña actual incorrecta</div>
      } else if (this.state.unequal){
        error = <div className="text-danger">La nueva contraseña debe coincidir y tener al menos 8 caracteres</div>
      }

      var showHideClassName = show ? "modal display-block" : "modal display-none";
      return (
        <div className="showHideClassName text-center">
          <section className="modal-main text-center w-66 b-rad-10-px">
            <br></br>
            <h5>{children}</h5>
            <form className="text-center w-100" onSubmit={(event) => {
              event.preventDefault()
              let previousPass = this.previousPass.value
              let password1 = this.password1.value
              let password2 = this.password2.value
              this.changePass(previousPass, password1, password2)
            }}>
              <table className="w-100 text-center">
                <tr>
                  <td className="w-10"></td>
                  <td  className="w-80">
                    <table  className="w-100">
                      <tr>
                        <td>Contraseña actual</td>
                      </tr>
                      <tr> 
                        <td>
                          <input type="password" ref={(previousPass) => { this.previousPass = previousPass }} className="form-control form-control-lg fs-14 text-muted"/>
                        </td> 
                      </tr> 
                      <tr>
                        <td>Nueva Contraseña</td>
                      </tr>
                      <tr> 
                        <td>
                          <input type="password" ref={(password1) => { this.password1 = password1 }} className="form-control form-control-lg text-muted fs-14"/>
                        </td> 
                      </tr> 
                      <tr>
                        <td>
                          Confirma la contraseña
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="password" ref={(password2) => { this.password2 = password2 }} className="form-control form-control-lg text-muted fs-14"/>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td className="w-10"></td>
                </tr>
                <tr>
                  <td className="w-10"></td>
                  <td  className="w-80">
                    {error}
                    <br></br>
                  </td>
                  <td  className="w-10"></td>
                </tr>
                <tr>
                  <td  className="w-10"></td>
                  <td  className="w-80">
                    <table className="w-100">
                      <tr>
                        <td className="w-40">
                          <button type="button" className="btn btn-primary btn-block btn-lg w-100 bg-danger b-n fs-16" onClick={(event) => {this.props.onClose()}}>
                            Cancelar
                          </button>
                        </td>
                        <td className="w-20"></td>
                        <td className="w-40">
                          <button type="submit" className="btn btn-primary btn-block btn-lg w-100 bg-success b-n fs-16">
                            Confirmar
                          </button>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td className="w-10"></td>
                </tr>
              </table>
            </form>
            <br></br>
          </section>
        </div>
      );
    }catch(err){
      return(<div>Error al cargar el componente</div>)
    }
  }
}

export default ModalChangePass; 