import React from "react";

const GenericArticle = ({ content, ContentComponent, imageUrl, title, summary, author, date }) => {
  return (
    <div className="w-100 text-left fw-light">
      <h1 className="pt-15 text-end">{author}</h1>
      <h1 className="pb-30 text-end">{date}</h1>
      <h1 className="fw-bold">{title}</h1>
      <br></br>
      <p className="mb-20 text-intend-30p">{summary}</p>
      <div className="d-flex justify-content-center">{imageUrl ? <img src={imageUrl} alt="" className="mw-100 h-auto mb-20 rounded-3" /> : null}</div>
      <ContentComponent content={content} />
    </div>
  );
};

export default GenericArticle;
