import React, { Component } from "react";
import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

class Actions extends Component {
  componentDidMount() {
    const { time } = this.props;
    setTimeout(() => this.setState({ render: true }), time || 400);
  }

  constructor(props) {
    super(props);
    this.state = {
      type: "name",
      render: false,
    };
    this.chooseSearchType = this.chooseSearchType.bind(this);

  }

  async chooseSearchType(type) {
    await this.setState({ type: type.value });
  }

  render() {
    let actions;
    const { render } = this.state;


    var graphDisplay =
    // Cambiando distancia de logo y gráfico

    <button className='btn btn-primary w-100 h-42-px fs-16 d-flex align-items-center justify-content-center' onClick={(event) => {
      this.props.showGraphic(0,0,[],false);
  }}>
      <span className='d-flex align-items-center px-1'>
          <svg
              xmlns="http://www.w3.org/2000/svg" className="bi bi-graph-up text-white me-1" width="16" height="16" viewBox="0 0 16 16">
              <path fill="white" fillRule="evenodd" d="M0 0h1v16H0V0zm1 15h15v1H1v-1zm15 0h-4V8.5h4V15zm-5.5 0H8V3.5h2.5V15zM8 2.5h2.5V15H8V2.5zm-3.5 12H1V1.5h3.5V14z"/>
          </svg>
          Gráfico
      </span>
  </button>
  
  
  



    // if (this.props.device !== "desktop") {
    //   graphDisplay = <div></div>
    // }


    if(this.props.userRole === "admin"){
      actions = 
        <table className="table w-100">
          <tbody  className='w-100'>
            <tr className='w-100'>
              <td className='w-49'>
                <form  onSubmit={(event) => {
                  event.preventDefault() 
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.usersManagerCount(0,0,"text", {})
                }}>            
                  <button type="submit"
                          className="btn btn-primary btn-block btn-lg w-100 fs-16">
                            Ver gerentes

                  </button>
                </form>
              </td>
              <td className='w-2'></td>
              <td className='w-49'>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.showCreationManager()
                }}>
                  <button type="submit" 
                          className="btn btn-primary btn-block btn-lg w-100 fs-16">
                            Crear gerente

                  </button>
                </form>
              </td>
            </tr>
            <tr className='w-100'>
              <td className='w-49'>
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar() 
                  this.props.usersEmployeeCount(0,0,"text", {})
                }}>            
                  <button type="submit" 
                          
                          className="btn btn-primary btn-block btn-lg w-100 fs-16">
                            Ver empleados

                  </button>
                </form>
              </td>
              <td className='w-2'></td>
              <td className='w-49'>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.showCreationFields()
                }}>
                  <button type="submit" 
                          className="btn btn-primary btn-block btn-lg w-100 fs-16">
                            Crear empleado

                  </button>
                </form>
              </td>
            </tr>
            <tr className='w-100'>
              <td  className="form-control form-control-lg w-100">

                <input
                  type="text"
                  onKeyUp={(event) => this.props.searchManual(event.target.value)}
                  className="form-control form-control-lg"
                  placeholder="Busca nombre, apellidos o tarjeta"
                  required
                />
              </td>
              <td></td>
              <td className="form-control-lg text-muted w-100 h-100">
                {graphDisplay}          

              </td>
            </tr>
          </tbody>
        </table>
     
    } else {
      actions = 
        <table className="table w-100">
          <tbody  className='w-100'>
            <tr className='w-100'>
              <td className='w-49'>
                <form  onSubmit={(event) => {
                  event.preventDefault() 
                  this.props.usersEmployeeCount(0,0,"text", {})
                }}>            
                  <button type="submit" className="btn btn-primary btn-block btn-lg w-100 fs-16">Ver empleados</button>
                </form>          
              </td>
              <td className='w-2'></td>
              <td className='w-49'>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.showCreationFields()
                }}>
                  <button type="submit" className="btn btn-primary btn-block btn-lg w-100 fs-16">Crear empleado</button>
                </form>
              </td>
            </tr>
            <tr className='w-100 h-100'>
              <td  className="form-control form-control-lg w-100 h-100">
                <div className='w-100 h-100'>

                  <input
                    onKeyDown={(e) => this.props.searchManual(e, this.state.type)}
                    className="form-control form-control-lg"
                    placeholder="Busca por nombre, identificador o No de Tarjeta"
                    required
                  />
                </div>
              </td>
              <td></td>
              <td className="form-control-lg text-muted w-100 h-100">
                <Dropdown          
                  onChange={ this.chooseSearchType}
                  defaultOption={this.state.type} 
                  className='text-muted fs-10'
                  options ={[
                    { value: "name", label: "Nombre"},
                    { value: "apellidos", label: "Apellido" }

                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
     
    }

    if (!render) {
      return null;
    }

    return <div>{actions}</div>;
  }
}

export default Actions;
