
import React, { Component, useContext } from 'react'
import logo from '../logonuevo.avif'
import Logout from './Logout'
//import './modal.css';
import AskInstall from './AskInstall';
import './App.css';



class NavigatorModal extends Component {
  render() {
    try {
      var show = this.props.show;
      let field0 = <div></div>;
      let field1 = <div></div>;
      let field2 = <div></div>;
      let field3  = <div></div>;

      if (this.props.userRole) {

        field3 = <div className='text-center w-100'>
          <form className='text-center w-100' onSubmit={(event) => {
            event.preventDefault()
            this.props.onCloseNav()
            this.props.askNewPass()

          }}>

            <button type="submit" className='btn btn-primary w-66 h-25'>Cambiar contraseña</button>

          </form>
        </div>

      }

      if (this.props.userRole === "admin") {
        let field0Text = "Calcular Complementos";
        if (this.props.showANDirecto) {
          field0Text = "Salir del calculador";
        }

        field0 = <div className='text-center w-100'>
          <form className='text-center w-100' onSubmit={(event) => {
            event.preventDefault()
            this.props.onCloseNav()
            this.props.selectANDirecto()

          }}>

            <button type="submit" className='btn btn-secondary btn-sm w-66 bg-colorAzul'>{field0Text}</button>

          </form>
        </div>

        field1 = <div className='text-center w-100'>
          <form className='text-center w-100' onSubmit={(event) => {
            event.preventDefault()
            this.props.onCloseNav()
            this.props.usersEmployeeLoad()

          }}>
 {/* style={{ "width": "66%", backgroundColor: "#2381B8" }} */}
            <button type="submit" className='btn btn-primary w-66'>Cargar empleados</button>

          </form>
        </div>

        field2 = <div className='text-center w-100'>
        <form onSubmit={(event) => {
          event.preventDefault()
          this.props.onCloseNav()
          his.props.usersEmployeeCount(0, 0, "text", {})


        }}>
          {/* Mirar AN"-Backend-copiaprimera */}

          <button type="submit" className='btn btn-primary w-66 h-25'>Ver empleados</button>

        </form>
        </div>


      }

      var showHideClassName = show ? "modal display-block" : "modal display-none";

      if (this.props.userRole === "admin") {
        if (this.props.device === "desktop") {

          //console.log("estamos en la liena 90")
          return (

            <div className="modal display-block" style={{ textAlign: "center" }}>
              <section className="modal-main text-center b-rad-10-px b-1-s bg-white">
  
                <table className='text-center w-100'>

                  <tr>
                    <td className='w-10'></td>
                    <td className='w-40 text-center'>
                      <br></br>
                      <div className='w-100 text-center'>
                        {field2}
                      </div>
                    </td>
                    <td className='w-40 text-center'>
                      <br></br>
                      <div className='w-100 text-center'>
                        {field3}
                      </div>
                    </td>
                    <td className='w-10'></td>
                  </tr>

                  <tr>
                    <td className='w-10'></td>
                    <td className='w-40 text-center'>
                      <br></br>
                      <button type="button" className='btn btn-primary w-66 h-25 bg-success ' onClick={this.props.onCloseNav}>
                        Volver atrás 

                      </button>
                    </td>
                    <td className='w-40 text-center'>
                    {/* <br></br>
                      <button className='btn p-0 w-66 bg-danger h-10'>
                      <Logout/>
                      </button> */}
                    

                      <table className='w-100'>
                      <tbody className='w-100'> 
                      <tr className='w-100'>
                        <td className='w-12'></td>
                        <td className='w-76'><Logout/></td>
                        <td className='w-12'></td>
                      </tr>
                      </tbody>
                      </table> 
                        
                      
                    </td>
                    <td className='w-10'></td>
                  </tr>
                </table>
                <br></br>
              </section>
            </div>
          )
        } else {
          return (
            <div className={showHideClassName} style={{ textAlign: "center" }}>
              <section className="modal-main text-center b-rad-10-px b-1-s bg-white">
  
                <table className='text-center w-100'>

                  <tr>
                    <td className='w-10'></td>
                    <td className='w-80 text-center'>
                      <br></br>
                      <div className='text-center w-100'>
                        {field2}
                      </div>
                    </td>
                    <td className='w-10'></td>
                  </tr>
                  <tr>
                    <td className='w-10'></td>
                    <td className='w-80 text-center'>
                      <br></br>
                      <div className='text-center w-100'>
                        {field3}
                      </div>
                    </td>
                    <td className='w-10'></td>
                  </tr>
                  <tr>
                    <td className='w-10 text-center'></td>
                    <td className='w-80 text-center'>
                      <br></br>
                      <button type="button" className='btn btn-secondary btn-block btn-sm text-white w-66 bg-success'  onClick={this.props.onCloseNav}>

                        Volver atrás
                      </button>
                    </td>
                    <td className='w-10'></td>
                  </tr>
                  <tr>
                  <td className='w-10 text-center'></td>
                  <td className='w-80 text-center'>
                    <br></br>

                <AskInstall />
                </td>
                <td className='w-10 text-center'></td>
                </tr>

                  <tr>
                    <td className='w-10'></td>
                    <td className='w-80 text-center'>
                      <br></br>
                      <div className='text-center w-100'>
                        <Logout />
                      </div>
                    </td>
                    <td className='w-10'></td>
                  </tr>
                </table>
                <br></br>
              </section>
            </div>
          );
        }
      } else {
        return (
          <div className={showHideClassName} style={{ textAlign: "center" }}>
            <section className="modal-main modal-main text-center b-rad-10-px b-1-s bg-white">
              <table className='text-center w-100'>

                <tr>
                  <td className='text-center w-100'>
                    <br></br>
                    <div className='text-center w-100'>
                      {field2}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-center w-100'>
                    <br></br>
                    <div className='text-center w-100'>
                      {field3}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-center w-100'>
                    <br></br>
                    <button type="button" className='btn btn-secondary btn-block btn-sm w-66 bg-success' onClick={this.props.onCloseNav}>

                      Volver atrás
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className='text-center w-100'>
                    <br></br>

                    <AskInstall />
                  </td>
                </tr>

                <tr>
                  <td className='text-center w-100'>
                    <br></br>
                    <div className='text-center w-100'>
                      <Logout />
                    </div>
                  </td>
                </tr>
              </table>
              <br></br>
            </section>
          </div>
        );
      }
    } catch (err) {
      return <div>Error al cargar el componente</div>;
    }
  }
}

export default NavigatorModal;
