import React, { Component } from "react";
import "./modal.css";
import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

class ModalPeriod extends Component {



    constructor(props) {
        super(props)
        this.state = {
            comment: '',
            place: '',
            modeGood: {},

        };

    }

    async handleChangeModeGood(mode) {
        await this.setState({ modeGood: mode })
    }

    render() {
       try{

        var show = this.props.show
        var children = this.props.children

        var showHideClassName = show ? "modal display-block" : "modal display-none";
        let mode = { label: "Conducción", value: "Driving" }
        //console.log(this.props.selectedPeriod.dietAmount)
        var defAmount = parseFloat(this.props.selectedPeriod.dietAmount)/1000
        var defaultComments = this.props.selectedPeriod.comments
        if (this.props.selectedPeriod.comments){
            defaultComments  = this.props.selectedPeriod.comments
        }else{
            defaultComments  = "" 
        }
        return (



            <div className="showHideClassName text-center">
                <section className="modal-main text-center b-rad-10-px b-1-s">
                    <h5>{children}</h5>
                    <form className="text-center" onSubmit={(event) => {

                        event.preventDefault()
                        let diet = this.diet.value
                        let comment = this.comment.value



                        this.props.editPeriodDetails(diet, comment)


                    }}>
                        <table className="w-100 text-center">
                            <tr>
                                <td className="w-10"></td>
                                <td className="w-80">
                                    <table className="w-100">
                                        <tr>
                                            <td>
                                                Comentarios
                                            </td>
                                        </tr>


                                        <tr className="w-100">
                                            <td className="w-100">
                                                <input
                                                    ref={(comment) => { this.comment = comment }}
                                                    className="form-control form-control-lg text-muted fs-14 w-100"
                                                    defaultValue={defaultComments}

                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Importe
                                            </td>
                                        </tr>


                                        <tr className="w-100">
                                            <td className="w-100">
                                                <input
                                                    ref={(diet) => { this.diet = diet }}
                                                     className="form-control form-control-lg text-muted fs-14 w-100"
                                                     defaultValue={parseFloat(this.props.selectedPeriod.dietAmount)/1000}

                                                />
                                            </td>
                                        </tr>

                                       
                                        <tr>



                                        </tr>

                                    </table>
                                </td>
                                <td className="w-10"></td>
                            </tr>
                            <tr>
                                <td className="w-10"></td>
                                <td className="w-80">
                                    <br></br>


                                </td>
                                <td className="w-10"></td>

                            </tr>
                            <tr>

                                <td className="w-10"></td>
                                <td className="w-80">
                                    <table className="w-100">
                                        <tr>

                                            <td className="w-40">
                                                <button type="submit" className="btn btn-primary btn-block btn-lg w-100 bg-success b-n fs-16">Confirmar</button>
                                            </td>
                                            <td className="w-20">

                                            </td>
                                            <td className="w-40">
                                                <button type="button" className="btn btn-primary btn-block btn-lg w-100 bg-warning b-n fs-16" onClick={this.props.onClose}>
                                                    Cerrar
                                                </button>
                                            </td>

                                        </tr>

                                    </table>
                                </td>
                                <td className="w-10"></td>
                            </tr>
                        </table>
                    </form>

                    <br></br>
                 
           
          </section>
        </div>
      );
    } catch (err) {
      return <div>Error al cargar el componente</div>;
    }
  }
}

export default ModalPeriod;
