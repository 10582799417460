import React, { useState } from 'react'
import { Link, NavLink, Router } from 'react-router-dom'
import { Component } from 'react';
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'


class NavBarLogged_Desktop extends Component {
  render() {
  
  return (
    //si el usuario está logueado y tiene vista de escritorio
    <div className="d-flex justify-content-center float-end">
          <table className="navbar-default">
            <tbody> 
              <tr className="w-50 mt-10-px">
              <td className='w-20 nav-btn rounded'>
                <div className='w-50 btn btn-sm btn-link'>
                {/* //btn btn-sm btn-block nav-btn*/}
                  <NavLink type="button" className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/">Inicio</NavLink>
                </div>
              </td>
              <td className='w-20 nav-btn rounded mt-20-px'>
                <div className='w-50 btn btn-sm btn-link'>
                  <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85  mt-10-px" to="/blog">Blog</NavLink>
                </div>
              </td>
                <td className='w-200 nav-btn rounded mt-20-px pt-pb-10-px'>
                  <div className="w-100 "> {/* Ajustamos el padding para más margen */}
                    <form
                      className="w-100 btn btn-sm btn-link"
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.props.showNavigatorModalNavbar();
                      }}
                    >
                      <button type="submit" className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85">
                        Más acciones
                      </button>
                    </form>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  )
      }
}



export default NavBarLogged_Desktop;