//import { NavBar_NL_ND } from './NavBar_NL_ND'
import React from 'react'
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import { Link, NavLink, Router } from 'react-router-dom'
import LoginModal from './LoginModal';
import { Component } from 'react';
import NavigatorModal from './NavigatorModal';


class NavBarLogged_NoD extends Component {
  render() {

    let masAcciones = <div></div>

    if (this.props.userRole){
      masAcciones = <div>
      <form onSubmit={(event) => {
        event.preventDefault()
        this.props.showNavigatorModalNavbar()}}>
        <button  type="submit" className="button fs-17 text-decoration-none">
          <b>+</b>
          {/* Esto debería llevarme al + original */}
         
        </button>
      </form>
    </div>
    }
    return (
      // <div className="Navbar navbar navbar-expand-sm bg-light fixed-top max-h-100-px ">
      // <div className="leftSide w-100">
      // <img src={logo} className="d-inline-block h-47-px mt-10-px mb-10-px ml-10-px" alt="Drive-Team"
      //         onError={(e) => {
      //             e.target.src = logoPNG;
      //         }}/>
      // </div> 
      // {/* //TODO rellenar esta parte */}
      
      <table className='w-100'>
      <tr className='w-100'>
        <td className='w-70'>
          <table>
            <tr>
              <td>
                <div className='w-100'>
                  <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/">Inicio</NavLink>
                </div>
              </td>
            </tr>
            <tr className='w-100'>
              <td>
                <div className='w-100'>
                  <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/blog">Blog</NavLink>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td className='w-100'>
          {masAcciones}
        </td>
      </tr>
    </table>
    )
  }
  }
  export default NavBarLogged_NoD;