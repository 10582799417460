import React, { Component } from "react";
import "./modal.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

class ModalActivity extends Component {
  constructor(props) {
    super(props);
    this.selectAllRef = React.createRef();
    this.state = {
      allChecked: false,
      comments: "Comentario",
      place: "Lugar",
      modeGood: "Rest",
    };
  }

  async componentDidMount() {
    await this.setState({ comments: "Comentario", place: "Lugar", modeGood: "Rest" });
  }

  async allCheckboxes() {
    if (this.state.allChecked) {
      await this.setState({ allChecked: false });
      // this.selectAllRef.current.checked = false;
    } else {
      await this.setState({ allChecked: true });

      // this.selectAllRef.current.checked = true;
    } 
  }

  //Se saca del form para una mejor legibilidad
  async handleSubmit(event) {
    event.preventDefault();
    let comments = this.comments.value;
    let place = this.place.value;
    let modeGoodVal = this.state.modeGood.value;
    let filteredActivitieForEdit = [];

    // Si todo está correcto, proceder con la edición de los detalles de la actividad
    if (this.state.allChecked) {
      for (let i = 0; i < this.props.filteredActivitieForEdit.length; i++) {
        filteredActivitieForEdit.push(this.props.filteredActivitieForEdit[i].id);
      }
    }
    this.props.editActivityDetails(comments, place, modeGoodVal, filteredActivitieForEdit);
  }

  async handleChangeModeGood(mode) {
    await this.setState({ modeGood: mode });
  }

  render() {
    var show = this.props.show
    var children = this.props.children
    var checkbox = <div></div>
    if (this.props.filteredActivitieForEdit.length > 0) {

      checkbox =<label style={{fontSize:"20px"}}>
                  <input type="checkbox" ref={this.allChecked} className="boxFuncion" onChange = {() => this.allCheckboxes()} />
                    Aplicar a todas las actividades
                </label>
    }
    var showHideClassName = show ? "modal display-block" : "modal display-none";
    let mode = { label: "Conducción", value: "Driving" }
  //console.log("llegando a de actividad")    

      return (
        <div className="text-center modal display-block">
          <section className="modal-main text-center b-r-10 b-1-s">
            <h5>{children}</h5>
            <form className="text-center" onSubmit={(event) => {
              event.preventDefault()
              let comments = this.comments.value
              let place = this.place.value
              let modeGoodVal = this.state.modeGood.value
              let filteredActivitieForEdit = []

              if (this.state.allChecked) {
                // make filteredActivitieForEdit justa  list of the filteredActivitieForEditObjects ids
                for (let i = 0; i < this.props.filteredActivitieForEdit.length; i++) {
                  filteredActivitieForEdit.push(this.props.filteredActivitieForEdit[i].id);
                }
              }
              this.props.editActivityDetails(comments, place, modeGoodVal, filteredActivitieForEdit)
            }}>
              <table className="w-100 text-center">
                <tr>
                  <td className="w-10"></td>
                  <td className="w-80">
                    <table className="w-100">
                      <tr>
                        <td>
                          Comentarios
                        </td>
                      </tr>
                      <tr className="w-100">
                        <td className="w-100">
                          <input
                            ref={(comments) => { this.comments = comments }}
                            className="form-control form-control-lg text-muted fs-14 w-100"
                            defaultValue={this.props.defaultComments}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Lugar
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            ref={(place) => { this.place = place }}
                            className="form-control form-control-lg text-muted fs-14"
                            defaultValue={this.props.defaultPlace}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Modalidad
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Dropdown
                            className="form-control-lg text-muted"
                            onChange={(mode) => this.handleChangeModeGood(mode)}
                            options={[
                              { value: "Driving", label: "Conducción" },
                              { value: "Working", label: "Otros Trabajos" },
                              { value: "Available", label: "Disponible" },
                              { value: "Rest", label: "Descanso" }
                            ]}
                          />
                        </td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </td>
                  <td className="w-10"></td>
                </tr>
                <tr>
                <td className="w-10"></td>
                  <td className="w-80">
                    <br></br>
                  </td>
                  <td className="w-10"></td>
                </tr>
                <tr>

                 <td className="w-10"></td>
                 <td className="w-80">
                  {checkbox}
                  </td>
                  <td className="w-10"></td>
                
                </tr>
               
                <tr>
                  <td className="w-10"></td>
                  <td className="w-80">
                    <table className="w-100">
                      <tr>
                      <td className="w-40">
                          <button type="submit" className="btn btn-primary btn-block btn-lg w-100 bg-success fs-16 b-n">
                            Confirmar</button>
                        </td>
                        <td className="w-20">
                        </td>
                        <td className="w-40">
                          <button type="button" className="btn btn-primary btn-block btn-lg w-100 bg-danger fs-16 b-n" onClick={this.props.onClose}>
                            Cerrar
                          </button>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td className="w-10"></td>
                </tr>
              </table>
            </form>
            <br></br>
          </section>
        </div>
      );
    // } catch (err) {
    //   return (<div>Error al cargar el componente</div>)
    // }

  }
}

export default ModalActivity;
