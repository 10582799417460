import React, { Component, useContext } from "react";
import logo from "../logonuevo.avif";
import "./modal.css";
import CustomSpinner from "./CustomSpinner";
import Navbar from "./Navbar";
import EmployeeDetails from "./EmployeeDetails";
import TripCreation from "./TripCreation";
import HolidayEmployee from "./HolidayEmployee";

class ModalEmployeeAlpha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      pages: "",
      page: 1,
      numReloads: 0,
      editingEmployeeId: null,
      viewingEmployeeId: null,
      isEditing: {},
      isViewing: {},
      isLoading: false,
      myEmployee: undefined,
      showEmployee: true,
      isCreate: false,
      createName: "",
      createApellidos: "",
      createIdentification: "",
      createCardNumber: "",
      createEmail: "",
      createPhone: "",
      createBaseSalary: "",
      createWeeklyHours: "",
      editingName: "",
      editingApellidos: "",
      editingIdentification: "",
      editingCardNumber: "",
      editingEmail: "",
      editingPhone: "",
      editingBaseSalary: "",
      editingWeeklyHours: "",
      isCreateFormComplete: false,
      isEditingFormComplete: true,
      disableIdentificationInput: false,
      disableCardNumberInput: false,
      originalIdentification: "",
      originalCardNumber: "",
    };
    this.displayPlaces = this.displayPlaces.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.seslectDisplayMode = this.seslectDisplayMode.bind(this);
    this.toggleCreateForm = this.toggleCreateForm.bind(this);
    this.createTrip = this.createTrip.bind(this);
  }

  async componentDidMount() {
    let init = this.props.listInit;
    let end = init + 10;

    await this.displayPlaces(init, end);
  }

  async componentDidUpdate() {
    let init = this.props.listInit;
    let end = init + 10;

    if (this.state.pages === "") {
      await this.displayPlaces(init, end);
    }
  }

  async editEmployee(id) {
    try {
      let employee;
      if (this.props.myEmployee) {
        if (this.props.myEmployee.id !== id) {
          employee = await this.props.search(id, 0, 0, {}, false, "", false, false);
        }
      } else {
        employee = await this.props.search(id, 0, 0, {}, false, "", false, false);
      }

      if (employee || this.props.myEmployee) {
        await this.props.showCreationFields();
      }
    } catch (err) {
      alert("error al intentar crear un empleado");
    }
  }

  async displayPlaces(init, end) {
    this.setState({ isLoading: true });
    try {
      let employeesList = await this.props.employeesList;
      let pages = Math.ceil(employeesList.length / 10);
      let page = Math.ceil(end / 10);
      let employees = await employeesList.slice(init, end);
      this.setState({
        pages: pages,
        page: page,
        employees: employees,
        isLoading: false, // Asegurarse de desactivar el loading al finalizar
      });
      await this.props.editEmployeeListInit(init);
      return pages;
    } catch (err) {
      alert("error al desplegar la lista de empleados");
      this.setState({ isLoading: false }); // Desactivar en caso de error también
    }
  }

  toggleCreateForm = () => {
    this.setState((prevState) => ({
      isCreate: !prevState.isCreate, // Esto cambia el estado entre true y false
      isCreateFormComplete: false,
    }));
  };

  toggleView = async (id, isClosing) => {
    // Si se ha pulsado el botón cuando su Nombre/estado es Cerrar
    if (isClosing) {
      this.setState((prevState) => ({
        isViewing: {
          ...prevState.isViewing,
          [id]: false,
        },
        viewingEmployeeId: null,
      }));
      return; // Salir de la función si es solo para cerrar
    }
    // Si se ha pulsado el botón cuando su nombre/estado es Ver
    this.setState({ isLoading: true });
    let myEmployee = await this.props.search(id, 0, 0, {}, false, "text", false, false);
    this.setState({ myEmployee: myEmployee });
    this.setState((prevState) => {
      const isAlreadyViewing = prevState.isViewing[id];
      const resetViewingStates = Object.keys(prevState.isViewing).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const resetEditingStates = Object.keys(prevState.isEditing).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      this.setState({ isLoading: false });

      return {
        isViewing: {
          ...resetViewingStates,
          [id]: !isAlreadyViewing,
        },
        isEditing: resetEditingStates,
        viewingEmployeeId: !isAlreadyViewing ? id : null,
        editingEmployeeId: null,
      };
    });
  };

  toggleEdit = (empl) => {
    this.setState((prevState) => {
      const id = empl.id;
      const isAlreadyEditing = prevState.isEditing[id];
      const resetEditingStates = Object.keys(prevState.isEditing).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const resetViewingStates = Object.keys(prevState.isViewing).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      return {
        isEditing: {
          ...resetEditingStates,
          [id]: !isAlreadyEditing,
        },
        isViewing: resetViewingStates,
        editingEmployeeId: !isAlreadyEditing ? id : null,
        viewingEmployeeId: null,
        // Habilitar siempre los input y el boton en caso de que se hayan desabilitado por validación
        disableIdentificationInput: false,
        disableCardNumberInput: false,
        isEditingFormComplete: true,
        // Guarda los valores originales de los input para la comparación y asi habilitar / deshabilitar
        originalIdentification: empl.identification,
        originalCardNumber: empl.cardNumber,
        ...(!isAlreadyEditing
          ? {
              editingName: empl.name,
              editingApellidos: empl.apellidos,
              editingIdentification: empl.identification,
              editingCardNumber: empl.cardNumber,
              editingEmail: empl.user.email,
              editingPhone: empl.user.phone,
              editingBaseSalary: empl.base_salary / 1000,
              editingWeeklyHours: empl.weekly_hours / 1000,
            }
          : {}),
      };
    });
  };

  InputDatos = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true }); // Activar el spinner antes de la operación de la base de datos

    try {
      let employeeData = null;
      if (this.state.isCreate) {
        employeeData = {
          name: this.state.createName,
          apellidos: this.state.createApellidos,
          identification: this.state.createIdentification,
          cardNumber: this.state.createCardNumber,
          email: this.state.createEmail,
          phone: this.state.createPhone,
          baseSalary: this.state.createBaseSalary,
          weeklyHours: this.state.createWeeklyHours,
        };
      } else if (this.state.isEditing) {
        employeeData = {
          name: this.state.editingName,
          apellidos: this.state.editingApellidos,
          identification: this.state.editingIdentification,
          cardNumber: this.state.editingCardNumber,
          email: this.state.editingEmail,
          phone: this.state.editingPhone,
          baseSalary: this.state.editingBaseSalary,
          weeklyHours: this.state.editingWeeklyHours,
        };
      }

      await this.props.createEmployeeAlpha(
        employeeData.name,
        employeeData.apellidos,
        employeeData.identification,
        employeeData.cardNumber,
        employeeData.baseSalary,
        employeeData.weeklyHours,
        employeeData.phone,
        employeeData.email
      );

      // Actualizar el estado con los nuevos valores de los empleados
      await this.displayPlaces(this.props.listInit, this.props.listInit + 10);
    } catch (error) {
      console.error("Error al guardar datos del empleado:", error);
    } finally {
      await this.setState({ isCreate: false });
      this.setState({ isLoading: false }); // Desactivar el spinner después de la operación
      await this.setState({ showModalListAlpha: false });
      await this.setState({ showModalListAlpha: true });
    }
  };

  checkCreateFormComplete = () => {
    const { createName, createApellidos, createIdentification, createCardNumber, createEmail, createPhone, createBaseSalary, createWeeklyHours } = this.state;
    if (
      createName.trim() !== "" &&
      createApellidos.trim() !== "" &&
      createIdentification.trim() !== "" &&
      createCardNumber.trim() !== "" &&
      createEmail.trim() !== "" &&
      createPhone.trim() !== "" &&
      createBaseSalary.trim() !== "" &&
      createWeeklyHours.trim() !== ""
    ) {
      this.setState({ isCreateFormComplete: true });
    } else {
      this.setState({ isCreateFormComplete: false });
    }
  };

  handleChangeIdentification = (event) => {
    const newIdentification = event.target.value;
    this.setState(
      (prevState) => ({
        editingIdentification: newIdentification,
        disableCardNumberInput: newIdentification.trim() === "" || newIdentification !== prevState.originalIdentification,
      }),
      this.checkEditingFormComplete
    );
  };

  handleChangeCardNumber = (event) => {
    const newCardNumber = event.target.value;
    this.setState(
      (prevState) => ({
        editingCardNumber: newCardNumber,
        disableIdentificationInput: newCardNumber.trim() === "" || newCardNumber !== prevState.originalCardNumber,
      }),
      this.checkEditingFormComplete
    );
  };

  checkEditingFormComplete = () => {
    const { editingName, editingApellidos, editingIdentification, editingCardNumber, editingEmail, editingPhone, editingBaseSalary, editingWeeklyHours } =
      this.state;

    if (
      editingName.trim() !== "" &&
      editingApellidos.trim() !== "" &&
      editingIdentification.trim() !== "" &&
      editingCardNumber.trim() !== "" &&
      editingEmail.trim() !== "" &&
      editingPhone.trim() !== "" &&
      !/^\s*$/.test(editingBaseSalary) &&
      !/^\s*$/.test(editingWeeklyHours)
    ) {
      this.setState({ isEditingFormComplete: true });
    } else {
      this.setState({ isEditingFormComplete: false });
    }
  };

  async seslectDisplayMode(DisplayMode) {
    this.setState({ isLoading: true });
    let isViewingEmployee = await this.state.isViewing;
    this.setState({ isViewing: {} });
    await this.props.seslectDisplayMode(DisplayMode);
    await this.setState({ isViewing: isViewingEmployee });
    this.setState({ isLoading: false });
  }

  async createTrip(origin, destination, tripDate, mode) {
    this.setState({ isLoading: true });
    let isViewingEmployee = await this.state.isViewing;
    this.setState({ isViewing: {} });
    await this.props.createTrip(origin, destination, tripDate, mode);
    await this.props.seslectDisplayMode("trips");
    await this.setState({ isViewing: isViewingEmployee });
    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading, isCreate, employees, isCreateFormComplete, isEditingFormComplete } = this.state;

    var show = this.props.show;
    //Comprueba si es ordenador o móvil para mostrarlo de una u otra forma
    if (this.props.isReloadTrips) {
      this.seslectDisplayMode("trips");
      this.props.toggleIsReloadTrips(false);
    } else if (this.props.isReloadActivities) {
      this.seslectDisplayMode("list");
      this.props.toggleIsReloadActivities(false);
    } else if (this.props.isReloadDayConsult) {
      this.seslectDisplayMode("dayConsult");
      this.props.toggleIsReloadDayConsult(false);
    }

    if (this.props.device === "desktop" && this.props.isFeatureEnabled) {
      //Comprueba si debe crear varias páginas o no
      if (this.state.pages > 1 && this.state.page !== "") {
        var page = this.state.page;
        var pages = this.state.pages;
        var init = (page - 1) * 10;
        var end = page * 10;
        var paginator = (
          <div className="w-100 text-center">
            <div>
              {" "}
              Página {page} de {pages}{" "}
            </div>
            <table className="w-100">
              <tbody className="w-100">
                <tr className="w-100">
                  <td className="w-25"></td>
                  <td className="w-25">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        if (page > 1) {
                          this.setState({ page: page - 1 });
                          init = init - 10;
                          end = end - 10;
                        }
                        this.displayPlaces(init, end);
                        this.props.actualizarEstadoYAlmacenar();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Anterior{" "}
                    </button>
                  </td>
                  <td className="w-25">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        if (page < pages) {
                          this.setState({ page: page + 1 });
                          init = init + 10;
                          end = end + 10;
                        }
                        this.displayPlaces(init, end);
                        this.props.actualizarEstadoYAlmacenar();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Siguiente{" "}
                    </button>
                  </td>
                  <td className="w-25"></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        <div></div>;
      }

      //Si la empresa no tiene empleados asignados
      if (employees.length === 0) {
        setTimeout(() => {
          // Verificar de nuevo si los empleados aún están en cero
          if (this.state.employees.length === 0) {
            this.setState({ showNoEmployeesMessage: true });
          }
        }, 500); // Retraso de 500 ms para doble comprobación

        if (this.state.showNoEmployeesMessage) {
          return (
            <div>
              <div id="content" className="mt-3  text-center">
                <p className="text-center">No tienes empleados asignados aún, para cargarlos pulsa "Crear Empleado" y sube su correspondiente TGD</p>
              </div>
            </div>
          );
        }
      }

      //Creamos el box con la lista de empleados y unos títulos descriptivos
      return (
        <div id="content" className="mt-80">
          {(isLoading || this.props.loading) && (
            <div className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center custom-spinner-loading-overlay">
              <CustomSpinner device={this.props.device} />
            </div>
          )}
          <Navbar
            userRole={this.props.userRole}
            stateHistory={this.props.stateHistory}
            stateFuture={this.props.stateFuture}
            isShowModalEmployeeAlpha={this.props.isShowModalEmployeeAlpha}
          />
          <form
            className="mb-3"
            onSubmit={(event) => {
              event.preventDefault();
              let _tripSearched = this._tripSearched.value;
              this.props.search(empl.id, 0, 0, {}, false, "", false, false);
            }}
          ></form>
          <div>
            <div className="pb-20 pt-10">
              <table className="w-90 mx-auto">
                <tr>
                  <td scope="col" className="text-start"></td>
                  <td scope="col" className="text-start"></td>
                  <td scope="col" className="text-start"></td>
                  <td scope="col" className="text-start"></td>
                  <td scope="col" className="text-start"></td>
                  <td scope="col" className="text-end">
                    {this.state.isCreate ? (
                      <button className="btn btn-danger w-170 mr-10" onClick={this.toggleCreateForm}>
                        Cerrar Empleado
                      </button>
                    ) : (
                      <button className="btn btn-primary w-170 mr-10" onClick={this.toggleCreateForm}>
                        Crear Empleado
                      </button>
                    )}
                  </td>
                </tr>
              </table>
            </div>
            <table className="w-90 mx-auto table table-hover rounded-2 overflow-hidden shadow">
              <thead>
                <tr className="">
                  <th scope="col" className="text-start bg-primary text-white">
                    Empleado
                  </th>
                  <th scope="col" className="text-start bg-primary text-white">
                    Identificador
                  </th>
                  <th scope="col" className="text-start bg-primary text-white">
                    Número de Tarjeta
                  </th>
                  <th scope="col" className="text-start bg-primary text-white">
                    Email
                  </th>
                  <th scope="col" className="text-start bg-primary text-white">
                    Teléfono
                  </th>
                  <th scope="col" className="text-end bg-primary text-white"></th>
                </tr>
              </thead>
              {isCreate && (
                <tr>
                  <td colspan="6">
                    <form onSubmit={(e) => this.InputDatos(e)}>
                      <div className="d-flex justify-content-center w-100">
                        <table className="p-10 fw-normal">
                          <tbody>
                            <tr>
                              <td className="p-10">
                                <label htmlFor="createName">Nombre:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="text"
                                  id="createName"
                                  onChange={(e) => this.setState({ createName: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createApellidos">Apellidos:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="text"
                                  id="createApellidos"
                                  onChange={(e) => this.setState({ createApellidos: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createIdentification">Identificación:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="text"
                                  id="createIdentification"
                                  onChange={(e) => this.setState({ createIdentification: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createCardNumber">Número de Tarjeta:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="text"
                                  id="createCardNumber"
                                  onChange={(e) => this.setState({ createCardNumber: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="p-10">
                                <label htmlFor="createEmail">Correo Electrónico:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="email"
                                  id="createEmail"
                                  onChange={(e) => this.setState({ createEmail: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createPhone">Teléfono:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="text"
                                  id="createPhone"
                                  onChange={(e) => this.setState({ createPhone: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createBaseSalary">Salario Base:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="number"
                                  min="0"
                                  id="createBaseSalary"
                                  onChange={(e) => this.setState({ createBaseSalary: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                              <td className="p-10">
                                <label htmlFor="createWeeklyHours">Horas Semanales:</label>
                                <br />
                                <input
                                  className="rounded-2 border-1 text-center"
                                  type="number"
                                  min="0"
                                  id="createWeeklyHours"
                                  onChange={(e) => this.setState({ createWeeklyHours: e.target.value }, this.checkCreateFormComplete)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" className="text-center p-10">
                                <button type="submit" className="btn btn-primary" disabled={!isCreateFormComplete}>
                                  Guardar
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </td>
                </tr>
              )}
              <tbody id="productList">
                {this.state.employees.map((empl, key) => {
                  return (
                    <React.Fragment key={empl.id}>
                      <tr>
                        <td scope="col" className="text-start">
                          {empl.name} {empl.apellidos}{" "}
                        </td>
                        <td scope="col" className="text-start">
                          {empl.identification}
                        </td>
                        <td scope="col" className="text-start">
                          {empl.cardNumber}
                        </td>
                        <td scope="col" className="text-start">
                          {empl.user.email}
                        </td>
                        <td scope="col" className="text-start">
                          {empl.user.phone}
                        </td>
                        <td scope="col" className="text-end">
                          {this.state.isViewing[empl.id] ? (
                            <button className="btn btn-outline-secondary w-80 mr-10" onClick={() => this.toggleView(empl.id, true)}>
                              Cerrar
                            </button>
                          ) : (
                            <button className="btn btn-outline-secondary w-80 mr-10" onClick={() => this.toggleView(empl.id)}>
                              Ver
                            </button>
                          )}
                          {this.state.isEditing[empl.id] ? (
                            <button className="btn btn-outline-secondary w-80" onClick={() => this.toggleEdit(empl)}>
                              Cerrar
                            </button>
                          ) : (
                            <button className="btn btn-outline-secondary w-80" onClick={() => this.toggleEdit(empl)}>
                              Editar
                            </button>
                          )}
                        </td>
                      </tr>
                      {this.state.isViewing[empl.id] && (
                        <tr>
                          <td colSpan="6">
                            <EmployeeDetails
                              deleteTrip={this.props.deleteTrip}
                              filterAlerts={this.props.filterAlerts}
                              filterDiets={this.props.filterDiets}
                              filtrarDietas={this.props.filtrarDietas}
                              limpiarDietas={this.props.limpiarDietas}
                              deshacerFiltrado={this.props.deshacerFiltrado}
                              putFilterDiets={this.props.putFilterDiets}
                              putFilterAlerts={this.props.putFilterAlerts}
                              selectFilterAlerts={this.props.selectFilterAlerts}
                              filteredActivities={this.props.filteredActivities}
                              sendMessage={this.props.sendMessage}
                              showModalPeriod={this.props.showModalPeriod}
                              showEditTripFields={this.props.showEditTripFields}
                              myEmployee={this.props.myEmployee}
                              strStatus={this.props.strStatus}
                              showModalActivity={this.props.showModalActivity}
                              showSendSmsActivity={this.props.showSendSmsActivity}
                              showGraphic={this.props.showGraphic}
                              thisDeposited={this.props.thisDeposited}
                              currentUser={this.props.currentUser}
                              dowloadActivityReport={this.props.dowloadActivityReport}
                              search={this.props.search}
                              seslectDisplayMode={this.seslectDisplayMode}
                              displayType={this.props.displayType}
                              activityListInit={this.props.activityListInit}
                              editActivityListInit={this.props.editActivityListInit}
                              showCreationFields={this.props.showCreationFields}
                              userRole={this.props.userRole}
                              editTripsListInit={this.props.editTripsListInit}
                              tripsListInit={this.props.tripsListInit}
                              device={this.props.device}
                              showCreateTripFields={this.props.showCreateTripFields}
                              showHolidays={this.props.showHolidays}
                              showError={this.props.showError}
                              editPeriodsListInit={this.props.editPeriodsListInit}
                              periodsListInit={this.props.periodsListInit}
                              dowloadEmployeeReport={this.props.dowloadEmployeeReport}
                              actualizarEstadoYAlmacenar={this.props.actualizarEstadoYAlmacenar}
                              volverAlPasado={this.props.volverAlPasado}
                              regresoAlFuturo={this.props.regresoAlFuturo}
                              ref={this.props.childRefEmployee}
                              ref2={this.props.childRefHandle}
                              ref3={this.props.childRefDisplayAct}
                              childHistory={this.props.childHistory}
                              childFuture={this.props.childFuture}
                              stateHistory={this.props.stateHistory}
                              stateFuture={this.props.stateFuture}
                              isShowModalEmployeeAlpha={this.props.isShowModalEmployeeAlpha}
                              loading={this.props.loading}
                              isShowHolidays={this.props.isShowHolidays}
                              isToggleShowHolidays={this.props.isToggleShowHolidays}
                              isToggleCreateTrip={this.props.isToggleCreateTrip}
                              toggleShowHolidays={this.props.toggleShowHolidays}
                              toggleCreateTrip={this.props.toggleCreateTrip}
                            />
                          </td>
                        </tr>
                      )}
                      {this.state.isViewing[empl.id] && (
                        <>
                          {this.props.isShowCreateTripFields && this.state.viewingEmployeeId === empl.id && (
                            <tr>
                              <td colSpan="6">
                                <TripCreation
                                  myEmployee={this.props.myEmployee}
                                  device={this.props.device}
                                  createTrip={this.createTrip}
                                  showError={this.props.showError}
                                  isShowModalEmployeeAlpha={this.props.isShowModalEmployeeAlpha}
                                />
                              </td>
                            </tr>
                          )}
                          {this.props.isShowHolidays && this.state.viewingEmployeeId === empl.id && (
                            <tr>
                              <td colSpan="6">
                                <HolidayEmployee
                                  device={this.props.device}
                                  showError={this.props.showError}
                                  endPoint={this.props.endPoint}
                                  userToken={this.props.userToken}
                                  myEmployee={this.props.myEmployee}
                                  isShowModalEmployeeAlpha={this.props.isShowModalEmployeeAlpha}
                                  showHolidays={this.props.showHolidays}
                                  toggleShowHolidays={this.props.toggleShowHolidays}
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      {this.state.isEditing[empl.id] && this.state.editingEmployeeId === empl.id && (
                        <tr>
                          <td colspan="6">
                            <form onSubmit={(e) => this.InputDatos(e)}>
                              <div className="d-flex justify-content-center w-100">
                                <table className="p-10">
                                  <tbody>
                                    <tr>
                                      <td className="p-10">
                                        <label htmlFor="editingName">Nombre:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="text"
                                          id="editingName"
                                          value={this.state.editingName}
                                          onChange={(e) => this.setState({ editingName: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingApellidos">Apellidos:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="text"
                                          id="editingApellidos"
                                          value={this.state.editingApellidos}
                                          onChange={(e) => this.setState({ editingApellidos: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingIdentification">Identificación:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="text"
                                          id="editingIdentification"
                                          value={this.state.editingIdentification}
                                          onChange={this.handleChangeIdentification}
                                          disabled={this.state.disableIdentificationInput}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingCardNumber">Número de Tarjeta:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="text"
                                          id="editingCardNumber"
                                          value={this.state.editingCardNumber}
                                          onChange={this.handleChangeCardNumber}
                                          disabled={this.state.disableCardNumberInput}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="p-10">
                                        <label htmlFor="editingEmail">Correo Electrónico:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="email"
                                          id="editingEmail"
                                          value={this.state.editingEmail}
                                          onChange={(e) => this.setState({ editingEmail: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingPhone">Teléfono:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="text"
                                          id="editingPhone"
                                          value={this.state.editingPhone}
                                          onChange={(e) => this.setState({ editingPhone: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingBaseSalary">Salario Base:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="number"
                                          min="0"
                                          id="editingBaseSalary"
                                          value={this.state.editingBaseSalary}
                                          onChange={(e) => this.setState({ editingBaseSalary: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                      <td className="p-10">
                                        <label htmlFor="editingWeeklyHours">Horas Semanales:</label>
                                        <br />
                                        <input
                                          className="rounded-2 border-1 text-center"
                                          type="number"
                                          min="0"
                                          id="editingWeeklyHours"
                                          value={this.state.editingWeeklyHours}
                                          onChange={(e) => this.setState({ editingWeeklyHours: e.target.value }, this.checkEditingFormComplete)}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4" className="text-center p-10">
                                        <button type="submit" className="btn btn-primary" disabled={!isEditingFormComplete}>
                                          Guardar
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </form>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          {paginator}
          <div className="text-center">
            <br></br>
            <button type="button" class="btn btn-primary btn-block w-50" onClick={this.props.onCloseListAlpha}>
              Volver atrás
            </button>
            <br></br>
          </div>
        </div>
      );
    }
    //Ejecucion para Mobile
    else {
      if (this.state.pages > 1 && this.state.page !== "") {
        var page = this.state.page;
        var pages = this.state.pages;
        var init = (page - 1) * 10;
        var end = page * 10;
        var paginator = (
          <div className="w-100 text-center">
            <div>
              {" "}
              Página {page} de {pages}{" "}
            </div>
            <table className="w-100">
              <tbody className="w-100">
                <tr className="w-100">
                  <td className="w-25"></td>
                  <td className="w-25">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        if (page > 1) {
                          this.setState({ page: page - 1 });
                          init = init - 10;
                          end = end - 10;
                        }
                        this.displayPlaces(init, end);
                        this.props.actualizarEstadoYAlmacenar();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Anterior{" "}
                    </button>
                  </td>
                  <td className="w-25">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        if (page < pages) {
                          this.setState({ page: page + 1 });
                          init = init + 10;
                          end = end + 10;
                        }
                        this.displayPlaces(init, end);
                        this.props.actualizarEstadoYAlmacenar();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      Siguiente{" "}
                    </button>
                  </td>
                  <td className="w-25"></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        <div></div>;
      }

      if (this.props.employeesList.length === 0) {
        return (
          <div>
            <div id="content" className="mt-3">
              <br></br>
              <p className="text-center"> No tienes empleados asignados aún, para cargarlos pulsa "Crear Empleado" y sube su correspondiente TGD</p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        );
      }

      return (
        <div id="content" className="mt-3">
          <form
            className="mb-3"
            onSubmit={(event) => {
              event.preventDefault();
              let _tripSearched = this._tripSearched.value;
              this.props.search(empl.id, 0, 0, {}, false, "", false, false);
            }}
          ></form>
          <div className="card mb-4">
            <div className="card-body">
              <table>
                <tbody id="productList">
                  {this.state.employees.map((empl, key) => {
                    var statusText;
                    var statusColor;

                    if (empl.base_salary != 0) {
                      statusText = "Editado";
                      statusColor = "green";
                    } else {
                      statusText = "Pendiente";
                      statusColor = "red";
                    }

                    return (
                      <tr key={key} className="w-100 text-center">
                        <td className="w-25 text-center">
                          {empl.name} <br></br> {empl.apellidos}
                        </td>
                        <td className="w-25 text-center">
                          {empl.identification} <br></br>{" "}
                          {{} ? (
                            <button
                              className="btn btn-outline-secondary fs-14p"
                              onClick={(event) => {
                                this.props.actualizarEstadoYAlmacenar();
                                this.props.search(empl.id, 0, 0, {}, false, "", false, false);
                              }}
                            >
                              Ver
                            </button>
                          ) : null}
                        </td>
                        <td className="w-25 text-center">
                          {statusText} <br></br>{" "}
                          {{} ? (
                            <button
                              className="btn btn-outline-secondary fs-14p"
                              onClick={(event) => {
                                this.props.actualizarEstadoYAlmacenar();
                                this.editEmployee(empl.id);
                              }}
                            >
                              Editar
                            </button>
                          ) : null}{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {paginator}
        </div>
      );
    }
  }
}

export default ModalEmployeeAlpha;
