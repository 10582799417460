import React from "react";
import Chart from "chart.js/auto";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 4);

        this.state = {
            startDate: startDate,
            endDate: new Date(),
            filteredData: []
        };
        this.chartRef = React.createRef();
        this.chartType = "bar";
        this.chartTitle = "Ratio de trabajo por conductor";
    }

    async componentDidMount() {
        await this.filterData();
        await this.createBarChart();
    }

    filterData = async () =>  {
        const { dataGraphic } = this.props;
        const { startDate, endDate } = this.state;
        const filteredData = dataGraphic.map(employee => {
            // Filter each employee's data within the date range
            const filteredWeeks = employee.data.filter(week => {
                const initDate = new Date(week.init);
                const termDate = new Date(week.term);
                return initDate >= startDate && termDate <= endDate;
            });
    
            // Return employee data with filtered weeks
            return { ...employee, data: filteredWeeks };
        }).filter(employee => employee.data.length > 0); // Keep only employees with data in the selected range
    
        await this.setState({ filteredData: filteredData});
        await this.createBarChart()
    }

    createBarChart = async () => {
        const { filteredData } = this.state;
        const popCanvas = this.chartRef.current.getContext("2d");
    
        // Destroy existing chart if it exists
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    
        const datasets = filteredData.map(employee => {
            const { totalDrivingHours, totalWorkingHours } = employee.data.reduce(
                (acc, week) => {
                    const weekStartDate = new Date(week.init);
                    const weekEndDate = new Date(week.term);

                    if (weekStartDate >= this.state.startDate && weekEndDate <= this.state.endDate) {
                        acc.totalDrivingHours += week.driving_hours;
                        acc.totalWorkingHours += week.working_hours;
                    }
                    return acc;
                },
                { totalDrivingHours: 0, totalWorkingHours: 0 }
            );
        
            return {
                label: ` ${employee.employee_name}`,
                data: [
                    {
                        x: totalDrivingHours / 60,
                        y: totalWorkingHours / totalDrivingHours,
                    },
                ],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 1,
            };
        });
    
        this.chartInstance = new Chart(popCanvas, {
            type: this.chartType,
            data: {
                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        width: '100%',
                        display: false,
                        position: 'left',
                        align: 'start',
                        labels: {
                            boxWidth: 20,
                            padding: 10,
                            usePointStyle: true,
                        },
                        maxItems: 3,
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Conductores',
                        },
                        type: 'category', 
                        position: 'bottom',
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Ratio de trabajo',
                        },
                        type: 'linear',
                        position: 'left',
                    },
                },
            },
        });
    };

    updateChart = async  () => {
        await this.filterData();
    }

    render() {

        return (
            <div>               
                <canvas ref={this.chartRef} className='w-70 h-70'/>
            </div>
        );
    }
}

export default BarChart;