// import DatePicker from 'react-datepicker';
// import Dropdown from 'react-dropdown';
import React, { Component, useState } from 'react';
import 'react-dropdown/style.css';
import './selectBox.css';
import notfound from '../notfound.webp';


class GeneralView extends Component {
  constructor(props) {
    super(props)
    this.selectAllRef = React.createRef();
    this.checkbox1Ref = React.createRef(),
    this.checkbox2Ref = React.createRef(),
    this.checkbox3Ref = React.createRef(),
    this.checkbox4Ref = React.createRef(),
    this.selectAlertRef = React.createRef(),
    this.state = {
      allChecked: true,
      dictOriginal: { 'availableCheck': 'true', 'drivingCheck': 'true', 'restingCheck': 'true', 'workingCheck': 'true' },
      Employee: this.props.myEmployee,
      role: '',
      status: '',
      dateDesde: new Date(),
      dateHasta: new Date(),
      dates: [],
      driving: [],
      working: [],
      resting: [],
      available: [],
      modes: [],
      name: '',
      appellidos: '',
      base_salary: 0,
      nocturnas: 0,
      filterAlerts: false,
      totalWorkingHours: 0,
      totalDrivingHours: 0,
      totalRestingHours: 0,
      totalAvailableHours: 0,
      totalOtherWorkingHours: 0,
      ratioDrTtl: 0,
      total_nat_feed_diet: 0,
      total_int_feed_diet: 0,
      total_nat_sleep_diet: 0,
      total_int_sleep_diet: 0,
      lastDate: '',
      lastPlaceDate: '',
      filters: {},
      pages: 0,
      page: 1,
      pagesTrips: 0,
      pageTrip: 1,
      activities: [],
      noData: false,
      graphData: [],
      showTrip: false,
      tripsToshow: [],
      showJornada: false,
      selectedPeriod: "weekReports",
      periodsToShow: [],
      dayConsultToShow: [],
      pagesPeriods: 1,
      pagePeriods: 1,
      pagesDays: 1,
      pageDays: 1,
      totalFest: 0,
      totalWeekEnd: 0,
      totalPlusKmNat: 0,
      totalPlusKmInt: 0,
      numReloads: 0,
      diets: {},
      nocturnas: 0,
      checkCalled:false,
      stateHistory: [],
      stateFuture: [],
      showGraphic: false
    }
  }

  async componentDidMount() {

    
    let filters = this.state.dictOriginal 

    let modesSelected

    if (filters.hasOwnProperty('modes')) {
      if (filters['modes'].length > 0) {
        modesSelected = filters['modes']
      } else {
        modesSelected = [{ label: 'Conducción', value: 'driving' },
        { label: 'Trabajo', value: 'working' },
        { label: 'Descanso', value: 'resting' },
        { label: 'Disponible', value: 'available' }]
      }
    } else {
      modesSelected = [{ label: 'Conducción', value: 'driving' },
      { label: 'Trabajo', value: 'working' },
      { label: 'Descanso', value: 'resting' },
      { label: 'Disponible', value: 'available' }]
    }

    if(this.props.device === 'desktop'){           
      this.handleChangeModes("allConflict")         
    }else{           
      this.handleChangeModesMobile("allConflict")          
    }

    this.setState({ modes: modesSelected })

  }
  
  actualizarEstadoYAlmacenarGeneral = (nuevoEstado) => {
    const { stateHistory, ...restOfState } = this.state;  
    const newState = { ...restOfState, ...nuevoEstado };
    this.setState(prevState => ({
      ...prevState,
      ...nuevoEstado,
      stateHistory: [...prevState.stateHistory, { ...prevState }]
    }), () => {      
    });    
  };


  volverAlPasadoGeneral = () => {
    this.setState({page: ""})
    const { stateHistory, stateFuture } = this.state;
  
    if (stateHistory.length >= 1) {
        const newState = stateHistory[stateHistory.length - 1];
        const updatedHistory = stateHistory.slice(0, -1);
  
        this.setState({
            ...newState,
            stateHistory: updatedHistory,
            stateFuture: [...stateFuture, { ...this.state }]
        });
    }     
  };


  regresoAlFuturoGeneral = () => {
    const { stateFuture } = this.state;
  
    if (stateFuture && stateFuture.length >= 1) {
        const nextState = stateFuture[stateFuture.length - 1];
        const updatedFuture = stateFuture.slice(0, -1);
  
        this.setState({
            ...nextState,
            stateFuture: updatedFuture
        });
    }    
  };

  async searchEditEmployee(key){
    try{
      let employee
      
        employee =  await this.props.search(this.state.activities[key]['employee_id'],this.state.activities[key]['start']-87000,this.state.activities[key]['start']+87000,{}, false)
      
      
      if (employee || this.props.myEmployee){
        await this.props.showCreationFields()
      }
    }catch(err){
      alert("error al buscar el empleado")
    }
  }

  async formatDate(date, showTime) {
    try {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      if (day.length < 2) {
        day = "0" + day
      }
      //let year = d.getFullYear();
      let hours = d.getHours().toString()
      if (hours.length < 2) {
        hours = "0" + hours
      }
      let minutes = d.getMinutes().toString()
      if (minutes.length < 2) {
        minutes = "0" + minutes
      }
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      let dateToShow
      if (showTime) {
        dateToShow = day + "-" + month + " " + hours + ":" + minutes;
      } else {
        dateToShow = day + "-" + month;
      }
      return dateToShow;
    } catch (err) {
      alert("Error al formatear la fecha")
    }
  }

  async findDictById(idToFind) {
    let employees = await this.props.employeesList
    return employees.find(dict => dict.id === idToFind);
  }

  async provisionalList(activitiesWithselectedMode, asked = true){
    let provisionalList = [];
    if (asked){
          // Filtrar las actividades con detalles no vacíos
        for (let x = 0; x < activitiesWithselectedMode.length - 1; x++) {
          let element = activitiesWithselectedMode[x];
          
          if (element["datails"]) {
              let value = element["datails"];
              //if (value[0]) {
                  //if (value[0] !== "") {
                      provisionalList.push(element);
                  //}
              //}
          }
      }
      
    }else{
          // Filtrar las actividades con detalles no vacíos
    for (let x = 0; x < activitiesWithselectedMode.length - 1; x++) {
      let element = activitiesWithselectedMode[x];
      
      if (element["datails"]) {
          let value = element["datails"];
          if (value[0]) {
              if (value[0] === "") {
                  provisionalList.push(element);
              }
          }else{
            provisionalList.push(element);
          }
      }else{
        provisionalList.push(element);
      }
  }
    }


    activitiesWithselectedMode = provisionalList; // Reemplazar la lista original con la lista provisional

    return activitiesWithselectedMode
  }


  showGraphicHandler = () => {
    this.setState({ showGraphic: true });
  };

  async displayActivities(init, end) {
    //try{
      // Obtener la lista de actividades desde this.props.generalView
      let activitiesList 
      let periodsSusList 
      try{
        activitiesList = await this.props.generalView.activities;
        periodsSusList = await this.props.generalView.periodsSusList;
        if (activitiesList.length < 1){
          activitiesList = await this.props.generalView
          periodsSusList = []
        }

      }catch(err){
        activitiesList = await this.props.generalView
        periodsSusList = []
      }


      var selected = [];

      // Revisa que checkboxes están seleccionados
      for (let m = 0; m < this.state.modes.length; m++) {
          let modeInList = await this.state.modes[m];
          let modeToLookUpFor;

          switch (modeInList['value']) {
              case "resting":
                  modeToLookUpFor = "Rest";
                  break;
              case "working":
                  modeToLookUpFor = "Working";
                  break;
              case "driving":
                  modeToLookUpFor = "Driving";
                  break;
              case "available":
                  modeToLookUpFor = "Available";
                  break;
          }

          selected.push(modeToLookUpFor); // Agregar el modo elegido a la lista 'selected'
      }

      // Filtrar las actividades que coinciden con los modos seleccionados
      var activitiesWithselectedMode = activitiesList.filter(({ type }) => selected.includes(type));

      // Procesar cada actividad y darle formato
      activitiesWithselectedMode= await this.formatActivities(activitiesWithselectedMode);
      periodsSusList = await this.formatPerids(periodsSusList);


      // unite the two lists into one and sort it by start time
      activitiesWithselectedMode = [...activitiesWithselectedMode, ...periodsSusList].sort((a, b) => a.start - b.start);

      // sort activities by start time on reverse
      activitiesWithselectedMode.sort((a, b) => b.start - a.start);


      let checkCalled = this.state.checkCalled;
      if (checkCalled) {
        activitiesWithselectedMode = await this.provisionalList(activitiesWithselectedMode);
      }else{
        activitiesWithselectedMode = await this.provisionalList(activitiesWithselectedMode, false);

      }

      if (this.state.filterAlerts && activitiesWithselectedMode.length > 0) {
          // Filtrar actividades según ciertos criterios
          for (let i = activitiesWithselectedMode.length - 1; i >= 0; i--) {
              var activity = activitiesWithselectedMode[i];
              var details = activity["datails"];

              if (details === undefined) {
                  details = null;
              }

              if (
                  (activity.type === "Working" || activity.type === "Available") &&
                  activity.duration > 60 &&
                  details === null
              ) {
                  continue; // Continuar con la siguiente actividad sin eliminar esta
              } else if (activity.provisional === true) {
                  continue; // Continuar con la siguiente actividad sin eliminar esta
              } else if (details !== null) {
                  continue; // Continuar con la siguiente actividad sin eliminar esta
              } else {
                  activitiesWithselectedMode.splice(i, 1); // Eliminar la actividad de la lista
              }
          }
      }

      // Calcular la cantidad de páginas basada en la cantidad de actividades
      let pages = Math.ceil(activitiesWithselectedMode.length / 10);
      this.setState({ pages: pages }); // Establecer la cantidad de páginas en el estado

      // Obtener las actividades que deben mostrarse en la página actual
      let activities = await activitiesWithselectedMode.slice(init, end);

      if (activities.length == 0 && this.state.numReloads < 10) {
          // Manejar la situación si no hay actividades y no se han recargado más de 10 veces
          let numReloads = this.state.numReloads + 1;
          this.setState({ numReloads: numReloads });
          this.props.editActivityListInit(1);
          this.setState({ page: 1 });
      }
      // // Procesar cada actividad y darle formato
      // activities= await this.formatActivities(activities);
      // periodsSusList = await this.formatPerids(periodsSusList);

      // // unite the two lists into one and sort it by start time
      // activities = [...activities, ...periodsSusList].sort((a, b) => a.start - b.start);

      // // sort activities by start time on reverse
      // activities.sort((a, b) => b.start - a.start);


      
      // Calcular la página actual y actualizar el estado con las actividades
      let page = Math.ceil(end / 10);
      this.setState({ activities: activities });
      this.setState({ page: page });
      this.props.editActivityListInit(0); // Finalizar la edición de la lista de actividades

      return pages; // Devolver la cantidad de páginas


    // } catch (err) {
    //   alert("Error al mostrar las actividades")
    // }
  }


  async formatActivities(activities){

    var countries = {
      "Spain":"España",
      "France":"Francia",
      "Italy":"Italia",
      "Belgium":"Bélgica",
      "Netherlands":"Países Bajos",
      "Germany":"Alemania",
      "Poland":"Polonia",
      "Czechia":"Rep. Checa",
      "Czech Republic":"Rep. Checa",
      "Denmark":"Dinamarca",
      "Slovakia":"Eslovaquia",
      "Hungary":"Hungría",
      "Austria":"Austria",
      "Switzerland":"Suiza",
      "Sweden":"Suecia",
      "Luxemburg":"Luxemburgo",
      "Finland":"Finlandia",
      "Norway":"Noruega",
      "Romania":"Rumanía",
      "Bulgaria":"Bulgaria",
      "Croatia":"Croacia",
      "Greece":"Grecia",
      "Slovenia":"Eslovenia",
      "Estonia":"Estonia",
      "Ireland":"Irlanda",
      "Lithuania":"Lituania",
      "Latvia":"Letonia",
      "Portugal":"Portugal",
      "Serbia":"Serbia",
      "Albania":"Albania"
    };
  

    // Procesar cada actividad y darle formato
    for (let i = 0; i < activities.length; i++) {
      let dateInt = parseInt(activities[i]["start"]) * 1000;
      let dateFormat = await this.formatDate(dateInt, true);
      activities[i]["startFormat"] = dateFormat;

      let type = activities[i]["type"];
      let typeFormat;
      let displaced = "";
      let place = activities[i]["place"];

      if (countries.hasOwnProperty(place)) {
        // Si existe, reemplazar el valor en la actividad
        activities[i]["place"] = countries[place];
      }

      let employee = await this.findDictById(activities[i]['employee_id']);
      let name = employee['name'];

      if (activities[i]["displaced"] === true) {
          displaced = "Desplazado";
      }

      let regionE = "";

      if (activities[i]["region"] != "Unknown") {
          regionE = ", " + activities[i]["region"];
      }

      switch (type) {
          case "Rest":
              typeFormat = "Descanso";
              break;
          case "Working":
              typeFormat = "Trabajando";
              break;
          case "Driving":
              typeFormat = "Conducción";
              break;
          case "Available":
              typeFormat = "Disponible";
              break;
          case "Desconocido":
              typeFormat = "Desconocido";
              break;
      }

      let dietFormat = "";
      let diet = activities[i]["dietType"];
      dietFormat = diet;

      let durationFormat = await this.formatOnlyTime(parseInt(activities[i]["duration"]));
      let nocturnasFormat;

      if (activities[i]["nocturnas"]) {
          nocturnasFormat = await this.formatOnlyTime(parseInt(activities[i]["nocturnas"]));
      } else {
          nocturnasFormat = "";
      }

      let colorConduccion;
      if (type === "Driving" && parseInt(activities[i]["duration"]) > 270) {
          colorConduccion = "red";
      }

      // Agregar propiedades con formato a la actividad
      activities[i]["typeFormat"] = typeFormat;
      activities[i]["durationFormat"] = durationFormat;
      activities[i]["dietFormat"] = dietFormat;
      activities[i]["displaced"] = displaced;
      activities[i]["regionE"] = regionE;
      activities[i]["colorConduccion"] = colorConduccion;
      activities[i]["nocturnasFormat"] = nocturnasFormat;
      activities[i]["name"] = name;
  }

    return activities
  }

  async formatPerids(periodsSusList){


  

    // Procesar cada actividad y darle formato
    for (let i = 0; i < periodsSusList.length; i++) {
      let dateInt = parseInt(periodsSusList[i]["init"]) * 1000;
      let dateFormat = await this.formatDate(dateInt, true);
      periodsSusList[i]["startFormat"] = dateFormat;

      let type = periodsSusList[i]["type"];
      let typeFormat;



      let employee = await this.findDictById(periodsSusList[i]['employee_id']);
      let name = employee['name'];

  

    

      switch (type) {
          case "Rest":
              typeFormat = "Descanso";
              break;
          case "Working":
              typeFormat = "Trabajando";
              break;
          case "Driving":
              typeFormat = "Conducción";
              break;
          case "Available":
              typeFormat = "Disponible";
              break;
          case "Desconocido":
              typeFormat = "Desconocido";
              break;
      }

      let dietFormat = "";
      let diet = periodsSusList[i]["dietType"];
      dietFormat = diet;

     

     

     

      // Agregar propiedades con formato a la actividad
      periodsSusList[i]["typeFormat"] = "Periodo";
      periodsSusList[i]["durationFormat"] = "";
      periodsSusList[i]["dietFormat"] = "";
      periodsSusList[i]["displaced"] = "";
      periodsSusList[i]["regionE"] = "";
      periodsSusList[i]["place"] = "";
      periodsSusList[i]["colorConduccion"] = "";
      periodsSusList[i]["nocturnasFormat"] = "";
      periodsSusList[i]["name"] = name;
      periodsSusList[i]["start"] = periodsSusList[i]["init"];
      periodsSusList[i]["kilometers"] = ""
      periodsSusList[i]["latitude"] = ""
      periodsSusList[i]["longitude"] = ""
      if (periodsSusList[i]["comments"]){
        periodsSusList[i]["datails"] = periodsSusList[i]["comments"]
      }
      else{
        periodsSusList[i]["datails"] = null
      }
      
  }

  


    return periodsSusList
  }


  async formatOnlyTime(minutes) {
    try {
      var durationFormat
      let durHours = Math.floor(minutes / 60)
      let durMinutes = minutes - (durHours * 60)
      let durMinutesRound = await Math.floor(durMinutes)
      let durMinutesString = await String(durMinutesRound)

      if (durMinutesString.length < 2) {
        durMinutesString = "0" + durMinutesString
      }

      durationFormat = String(durHours) + ":" + durMinutesString
      return durationFormat
    } catch (err) {
      setTimeout(() => window.location.reload(), 2000)
    }
  }


  async searchPending(){


    let checkCalled = this.state.checkCalled

    if (checkCalled){
      await this.setState({checkCalled:false})
    }else{
      await this.setState({checkCalled:true})
    }


    this.props.editActivityListInit(0)

    setTimeout(() => this.displayActivities(0, 10), 1);
    
  }


  async handleChangeModes(variableRecibe) {
    // try{
      let modesList = []
      let nameIt
      let dictValue
      let updatedDict = { ...this.state.dictOriginal };

      if(variableRecibe==="allConflict"){
        Object.keys(updatedDict).forEach(key => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({label:"Disponible", value:"available"})
          modesList.push({label:"Conducción", value:"driving"})
          modesList.push({label:"Descanso", value:"resting"})
          modesList.push({label:"Trabajo", value:"working"})
        }
      } 
      // else {
      //   const checkboxes = [this.checkbox1Ref.current, this.checkbox2Ref.current, this.checkbox3Ref.current, this.checkbox4Ref.current];
      //   const allChecked = checkboxes.every((checkbox) => checkbox.checked);
      //   const allUnchecked = checkboxes.every((checkbox) => !checkbox.checked);
      //   // this.setState({ allChecked: allChecked });
      //   // this.selectAllRef.current.checked = allChecked;
      //   if (allUnchecked) {
      //   //   this.allCheckboxes();
      //   }

      //   // this.selectAllRef.current.disabled = allChecked;
      // }
      
      if (updatedDict.hasOwnProperty(variableRecibe)) {
        updatedDict[variableRecibe] = !updatedDict[variableRecibe];
        this.setState({dictOriginal: updatedDict});
        setTimeout(() => verification(), 1);
        function verification(){
          for (let index in updatedDict) {
            if (updatedDict[index]) {
              switch (index) {
                case 'availableCheck':
                  nameIt = 'Disponible';
                  dictValue = 'available';
                  break;
                case 'drivingCheck':
                  nameIt = 'Conducción';
                  dictValue = 'driving';
                  break;
                case 'restingCheck':
                  nameIt = 'Descanso';
                  dictValue = 'resting';
                  break;
                case 'workingCheck':
                  nameIt = 'Trabajo';
                  dictValue = 'working';
                  break;
              }
              modesList.push({label:nameIt, value: dictValue})
            }
          }
        }
      } else if (variableRecibe == "all"){
        Object.keys(updatedDict).forEach(key => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({label:"Disponible", value:"available"})
          modesList.push({label:"Conducción", value:"driving"})
          modesList.push({label:"Descanso", value:"resting"})
          modesList.push({label:"Trabajo", value:"working"})
        }
      }

      let filters = this.state.filters
      filters['modes'] = modesList

      await this.setState({
        filters: filters,
        modes: modesList,
      })
    

      this.props.editActivityListInit(0)
      setTimeout(() => this.displayActivities(0, 10), 1);
    // }catch(err){
    //   alert("Error al seleccionar el modo")
    // }
  }




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////7






  async handleChangeModesMobile(variableRecibe) {
    //try{
      let modesList = []
      let nameIt
      let dictValue
      const updatedDict = { ...this.state.dictOriginal };
      
      if(variableRecibe==="allConflict"){
        Object.keys(updatedDict).forEach(key => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({label:"Disponible", value:"available"})
          modesList.push({label:"Conducción", value:"driving"})
          modesList.push({label:"Descanso", value:"resting"})
          modesList.push({label:"Trabajo", value:"working"})
        }
      } else {
        const checkboxes = [this.checkbox1Ref.current, this.checkbox2Ref.current, this.checkbox3Ref.current, this.checkbox4Ref.current];
        const allChecked = checkboxes.every((checkbox) => checkbox.checked);
        // const allUnchecked = checkboxes.every((checkbox) => !checkbox.checked);
        this.setState({ allChecked: allChecked });
        // if (allUnchecked) {
        //   this.allCheckboxes();
        // }
      }
    
      if(variableRecibe == "all"){
        Object.keys(updatedDict).forEach(key => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({label:"Disponible", value:"available"})
          modesList.push({label:"Conducción", value:"driving"})
          modesList.push({label:"Descanso", value:"resting"})
          modesList.push({label:"Trabajo", value:"working"})
        }
      }
      if (updatedDict.hasOwnProperty(variableRecibe)){
        updatedDict[variableRecibe] = !updatedDict[variableRecibe];
        this.setState({dictOriginal: updatedDict});
        setTimeout(() => verification(), 1);

        function verification(){
          for (let index in updatedDict) {
            if (updatedDict[index]) {
              switch (index) {
                case 'availableCheck':
                  nameIt = 'Disponible';
                  dictValue = 'available';
                  break;
                case 'drivingCheck':
                  nameIt = 'Conducción';
                  dictValue = 'driving';
                  break;
                case 'restingCheck':
                  nameIt = 'Descanso';
                  dictValue = 'resting';
                  break;
                case 'workingCheck':
                  nameIt = 'Trabajo';
                  dictValue = 'working';
                  break;
              }
              modesList.push({label:nameIt, value: dictValue})
            }
          }
        }
      }

      let filters = this.state.filters
      filters['modes'] = modesList

      await this.setState({
        filters: filters,
        modes: modesList,
      })

      this.props.editActivityListInit(0)
      setTimeout(() => this.displayActivities(0, 10), 1);
    // }catch(err){
    //   alert("Error al seleccionar el modo (2)")
    // }
  }

  async handleChangeDesde(dateDesde) {
    try {
      this.setState({
        dateDesde: dateDesde,
      })
    } catch (err) {
      alert("Error al indicar la fecha inicial")
    }
  }

  async handleChangeHasta(dateHasta) {
    try {
      //get desdeHasta timestamp
      this.setState({
        dateHasta: dateHasta
      })
    } catch (err) {
      alert("Error al indicar la fecha final")
    }
  }

  

  render() {
    
   //try {
    var display
    var textButtonColor = "white"
    var graphButtonColor = "white"
    var listButtonColor = "white"
    var tripsButtonColor = "white"
    var tripButton = <div></div>
    var jornadaButton = <div></div>
    var dayConsultButton = <div></div>
    var jornadaButtonColor = "white"
    var dayConsultButtonColor = "white"

    

    if (this.state.pages > 1 && this.state.page !== "") {
      // round up pages
      var page = this.state.page
      var pages = this.state.pages
      var init = (page - 1) * 10
      var end = page * 10

      var paginator =
        <div className='w-100 text-center'>
          <br></br>
          <div> Página {page} de {pages} </div>
          <table className='w-100 te-ali-cen'>
            <tbody className='w-100'>
              <tr className='w-100'>
                <td className='w-25'></td>
                <td className='w-25'>
                  <button onClick={(event) => {
                    event.preventDefault()
                    // this.props.actualizarEstadoYAlmacenar()
                    // this.actualizarEstadoYAlmacenarGeneral()
                    if (page > 1) {
                      this.setState({ page: page - 1 })
                      init = init - 10
                      end = end - 10
                    }

                    this.displayActivities(init, end)
                    
                  }} className="btn btn-outline-secondary">
                    Anterior
                  </button>
                </td>
                <td className='w-25'>
                  <button onClick={(event) => {
                    event.preventDefault()
                    // this.props.actualizarEstadoYAlmacenar()
                    // this.actualizarEstadoYAlmacenarGeneral()
                    if (page < pages) {
                      this.setState({ page: page + 1 })
                      init = init + 10
                      end = end + 10
                    }

                    this.displayActivities(init, end)
                    
                  }} className="btn btn-outline-secondary">
                    Siguiente
                  </button>
                </td>
                <td className='w-25'></td>
              </tr>
            </tbody>
          </table>
        </div>
    }

   


    let width = window.innerWidth * 60 / 100
    let height = window.innerHeight * 50 / 100

    tripButton

   

    var displayMode = this.props.displayType
    if (this.state.noData) {
      display = <div>No hay nada en estas fechas, cambia la selección</div>
    } else {
   

    
          var displayList

            
          let activitiesList = this.state.activities

          if (activitiesList.length === 0 && this.state.numReloads === 20 && this.state.checkCalled ===false) {
           
            setTimeout(() => window.location.reload(), 0)
          }


          if (this.props.device === 'desktop'){

            if (this.state.activities.length === 0) {
              displayList =
              <div id="content" className="mt-3">
                <div className="card mb-4">
                  {paginator}
                  <div className="card-body">
                    <table className="table w-100 fs-12">
                      <tbody id="productList">
                        <tr>
                          <td className="w-100">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <p className="fs-20">Tus empleados no tienen alertas actualmente. Revisa sus gráficos o consulta sus datos individualmente.</p>
                              <img className="mxw-200" src={notfound}/>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            }else{
              displayList =
              <div id="content" className="mt-3">
                <div className="card mb-4">
                  {paginator}
                  <div className="card-body">
                    <table className="table w-100 fs-12">
                      <thead>
                        <tr>
                        <th scope="col">Empleado</th>
                          <th scope="col">Actividad</th>
                          <th scope="col">Inicio</th>
                          <th scope="col">Duración</th>
                          <th scope="col">Nocturnidad</th>
                          <th scope="col">Estatus</th>
                          <th scope="col">Km</th>
                          <th scope="col">Lugar</th>
                          <th scope="col">Detalles</th>
                          <th scope="col">Editar</th>
                        </tr>
                      </thead>
                      <tbody id="productList">
                        {this.state.activities.map((empl, key) => {
                          var statusText = ""
                          var statusStyle = "green"
                          var latitude_longitude = ""
                          let message_button = <div></div>




                          if (this.props.userRole!=="employee"){
                            message_button = <button
                                                      className="btn btn-outline-secondary fs-12 h-34"
                                                      onClick={(event) => {
                                                  
                                                        this.props.showSendSmsActivity(this.state.activities[key])}}
                                                    >
                                                  Clarificar
                                                </button>

                          }
    
                          if (this.state.activities[key]["latitude"] && this.state.activities[key]["longitude"]) {
                            let lat = String(this.state.activities[key]["latitude"] / 1000)
                            let lon = String(this.state.activities[key]["longitude"] / 1000)
                            let url = "https://gps-coordinates.org/my-location.php?lat=" + lat + "&lng=" + lon
                            latitude_longitude = <a href={url} target="_blank">Ver ubicación</a>
                          }

                          var details = this.state.activities[key]["datails"]

                          if (this.state.activities[key]["provisional"] === true) {
                            statusText = ""
                            statusStyle = "red"
                          } 
                          
                          if (details !== null) {
                            if (details[0] !== " "){
                            statusStyle = "green"
                            statusText = "Editado"
                          }
                          }

                          var element
                          var elementLenght
                          const charachters = 20
                          var output = ""

                          if (details) {
                            for (let i = 0; i < details.length; i++) {
                              element = details[i]
                              let numberOfLines = element.length / charachters
                              for (let c = 0; c < numberOfLines; c++) {
                                let row = element.substring(c * charachters, (c + 1) * charachters)
                                output = output + <br></br> + row
                              }

                              output = output + <br></br>
                            }
                          }


                          return (
                            <tr key={key} className='w-100'>
                              <td  className="w-15">{this.state.activities[key]["name"]}</td>
                              <td  className="w-15">{this.state.activities[key]["typeFormat"]}</td>
                              <td className="w-15">{this.state.activities[key]["startFormat"]}</td>
                              <td className='w-100' style={{ width: "15%", color: this.state.activities[key]["colorConduccion"] }}><b>{this.state.activities[key]["durationFormat"]}</b></td>
                              <td className='w-5 text-primary'>{this.state.activities[key]["nocturnasFormat"]}</td>
                              <td className='w-15 text-success'>{statusText ? statusText + "\n" : ""}</td>
                              <td className='w-5 text-success'>{this.state.activities[key]["kilometers"]}</td>
                              <td className="w-15">{this.state.activities[key]["place"] + this.state.activities[key]["regionE"]}</td>
                              <td className='w-100 text-success fs-12 h-53-px w-s-prl'>
                                {message_button ? message_button : ""} 
                                {statusText ? statusText + "\n" : ""}
                                {latitude_longitude ? latitude_longitude : ""}
                              </td>

                              <td className="w-10 h-53-px text-end">
                                {
                                  {}
                                    ? <button
                                      className="btn btn-outline-secondary fs-12 h-34"
                                      onClick={(event) => {
                                        event.preventDefault()
                                        if (this.state.activities[key].typeFormat==="Periodo"){
                                          {this.props.seslectDisplayMode('dayConsult')}
                                        }else{
                                          {this.props.seslectDisplayMode('list')}
                                        }
                                        
                                        {this.props.putFilterAlerts(true)}
                                        {this.searchEditEmployee(key)}
                                        {this.props.actualizarEstadoYAlmacenar()}
                                      }}
                                    >
                                      Ver
                                    </button>
                                    : null
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            }



            var listControlPanel = 
              
              <div className="selectOptions w-100">
                
                
                <label className="fs-20">
                  <input type="checkbox" ref={this.checkbox1Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("availableCheck")} defaultChecked />
                  Disponible
                </label>
                <label className="fs-20">
                  <input type="checkbox" ref={this.checkbox2Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("drivingCheck")} defaultChecked />
                  Conducción
                </label>
                <label className="fs-20">
                  <input type="checkbox" ref={this.checkbox3Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("restingCheck")} defaultChecked />
                  Descanso
                </label>
                <label className="fs-20">
                  <input type="checkbox" ref={this.checkbox4Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("workingCheck")} defaultChecked />
                  Trabajando
                </label>
              </div>

            var alertControlPanel = 
              <div className="selectOptions w-100">
                <label className="fs-20">
                          <input type="checkbox" ref={this.checkbox4Ref} className="boxFuncion boxPosicion" onChange={() => this.searchPending()}  />
                          Consultados a conductor
                        </label>  
              </div>           


          } else {
         
         
          var alertControlPanel = <div className="selectOptions w-100">
          </div>

          displayList =
            <div id="content" className="mt-3">
              <div className="card mb-4" >
                {paginator}

                <div>
                  <br></br>
                    <table className="table w-100 fs-12 w-100">
                      <tbody className='w-100'>
                        <table  className='w-100 fs-12'>
                          <tr className='w-100'>
                            <td className="w-30 text-center"><b>Tipo</b></td>
                            <td className="w-20 text-center" ><b>País</b></td>
                            <td className="w-30 text-center"><b>Dieta</b></td>
                            <td className="w-20 text-center" ><b>Tiempo</b></td>
                          </tr>
                        </table>
                      {this.state.activities.map((empl, key) => {
                        var statusText = ""
                        var statusStyle = "green"
                        var details = this.state.activities[key]["datails"]

                        if (this.state.activities[key]["type"] === "Working" || this.state.activities[key]["type"] === "Available") {
                          statusText = ""
                          statusStyle = "red"
                        }
                        if (details !== null) {
                          if (details[0] !== "sent"){
                            statusStyle = "green"
                            statusText = "Editado"
                          }
                        }

                        var element
                        var elementLenght
                        const charachters = 20
                        var output = ""

                        if (details) {
                          for (let i = 0; i < details.length; i++) {
                            element = details[i]
                            let numberOfLines = element.length / charachters
                            for (let c = 0; c < numberOfLines; c++) {
                              let row = element.substring(c * charachters, (c + 1) * charachters)
                              output = output + <br></br> + row
                            }

                            output = output + <br></br>
                          }
                        }

                        return(
                          <table  className='w-100 fs-12'>
                            <tbody className='w-100'>
                              <tr key={key} className='w-100'>
                                <td className="w-30 text-center">{this.state.activities[key]["typeFormat"]}<br></br></td>
                                <td className="w-20 text-center" >{this.state.activities[key]["place"]}</td>
                                <td className="w-30 text-center">{this.state.activities[key]["dietFormat"]}</td>
                                <td className="w-20 text-center" >{this.state.activities[key]["durationFormat"]}</td>
                              </tr>
                              <tr key={key} className='w-100'>
                                <td className="w-30 text-center">{this.state.activities[key]["startFormat"]}</td>
                                <td className="w-20 text-success text-center">{this.state.activities[key]["displaced"]}</td>
                                <td className='w-30 text-success text-center'>{statusText}</td>
                                <td className="w-20 text-center" >
                                  {
                                    {}
                                    ? <button
                                      className="btn btn-outline-secondary w-100 fs-10 h-28"
                                      onClick={(event) => {  
                                        this.props.showModalActivity(this.state.activities[key])

                                      }}>
                                        Ver
                                      </button>
                                    :null
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>                                            
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }

        display =
          <div className='w-100'>
            <table className='w-100'>
              <tbody className='w-100'>
                
                <tr className='w-100'>
                  {listControlPanel}
                </tr>
                <tr>
                  {alertControlPanel}
                </tr>
                <tr>
                </tr>
                <tr>
                  {displayList}
                </tr>
              </tbody>
            </table>
          </div>
      
    }

   let checkLen = this.props.generalView
    // if (checkLen.length===0){
    //   return <div></div>
    // }else{
      return (
        <div className='w-100 mt-20-px'>
       
          <div className='w-100'>
            {display}
          </div>
        </div>
      )
    //}
   

    
    //  } catch (err) {
    //    setTimeout(() => window.location.reload(), 2000);
    //    return (<div>Error al cargar la lista de actividades</div>)
    // }
  }
}

export default GeneralView;
