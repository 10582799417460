// import countries from './countries.js';
import React, { Component, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./selectBox.css";

class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.selectAllRef = React.createRef();
    (this.checkbox1Ref = React.createRef()),
      (this.checkbox2Ref = React.createRef()),
      (this.checkbox3Ref = React.createRef()),
      (this.checkbox4Ref = React.createRef()),
      (this.checkbox5Ref = React.createRef()),
      (this.selectAlertRef = React.createRef()),
      (this.state = {
        allChecked: true,
        dictOriginal: { availableCheck: true, drivingCheck: true, restingCheck: true, workingCheck: true },
        Employee: this.props.myEmployee,
        role: "",
        status: "",
        dateDesde: new Date(),
        dateHasta: new Date(),
        dates: [],
        driving: [],
        working: [],
        resting: [],
        available: [],
        name: "",
        appellidos: "",
        base_salary: 0,
        nocturnas: 0,
        filterAlerts: false,
        filterDiets: false,
        totalWorkingHours: 0,
        totalDrivingHours: 0,
        totalRestingHours: 0,
        totalAvailableHours: 0,
        totalOtherWorkingHours: 0,
        ratioDrTtl: 0,
        total_nat_feed_diet: 0,
        total_int_feed_diet: 0,
        total_nat_sleep_diet: 0,
        total_int_sleep_diet: 0,
        lastDate: "",
        lastPlaceDate: "",
        filters: { precission: "months" },
        pages: 0,
        page: 1,
        pagesTrips: 0,
        pageTrip: 1,
        activities: [],
        activitiesDietsFilter: [],
        noData: false,
        modes: [],
        graphData: [],
        showTrip: false,
        tripsToshow: [],
        showJornada: false,
        selectedPeriod: "weekReports",
        periodsToShow: [],
        dayConsultToShow: [],
        pagesPeriods: 1,
        pagePeriods: 1,
        pagesDays: 1,
        pageDays: 1,
        totalFest: 0,
        totalWeekEnd: 0,
        totalPlusKmNat: 0,
        totalPlusKmInt: 0,
        numReloads: 0,
        diets: {},
        nocturnas: 0,
        stateHistory: this.props.stateHistory,
        stateFuture: this.props.stateFuture,
        filteredActivities: this.props.filteredActivities,
        extraHoursToPay: 0,
        estadosGuardados: this.props.estadosGuardados,
        tripsToshow: []
      });
  }

  async componentDidMount() {
    let graphData = [];
    let filterAlerts = await this.props.filterAlerts;

    await this.setState({ filterAlerts: filterAlerts });

    let filterDiets = await this.props.filterDiets;

    await this.setState({ filterDiets: filterDiets });

    let dataToAnalize = await this.props.myEmployee.dataToAnalize;

    //console.log("display", this.props.displayType);
    ////console.log("dataToAnalize",dataToAnalize)
    if (dataToAnalize.length === 0 && this.props.displayType !== "trips") {
      // if (dataToAnalize.length === 0) {

      this.setState({ noData: true });
    } else {
      let defaultStart = new Date(await this.props.myEmployee.startString);
      let defaultEnd = new Date(await this.props.myEmployee.endString);

      let nocturnas = await this.formatOnlyTime(this.props.myEmployee.diets["hours_noct"]);
      let totalWorkingHours = await this.formatOnlyTime(this.props.myEmployee.totalWorkingHours);
      let totalDrivingHours = await this.formatOnlyTime(this.props.myEmployee.totalDrivingHours);
      let totalRestingHours = await this.formatOnlyTime(this.props.myEmployee.totalRestingHours);
      let totalAvailableHours = await this.formatOnlyTime(this.props.myEmployee.totalAvailableHours);
      let totalOtherWorkingHours = await this.formatOnlyTime(this.props.myEmployee.totalOtherWorkingHours);
      let extraHoursToPay = await this.formatOnlyTime(this.props.myEmployee.net_extra_hours);

      this.setState({ diets: await this.props.myEmployee.diets });

      let filters = this.props.myEmployee["filters"];

      this.setState({ dateDesde: defaultStart, dateHasta: defaultEnd, filters: filters });
      let selectedPrecission = {};
      let selectedPeriod;

      let mail = this.props.myEmployee.mail;

      if (mail !== "") {
        this.setState({ showTrip: true });
      }
      if (filters) {
        // check if theres a precission filter
        if (filters.hasOwnProperty("precission")) {
          switch (filters["precission"]) {
            case "minutes":
              selectedPrecission = { label: "Minutos", value: "minutes" };
              break;
            case "hours":
              selectedPrecission = { label: "Horas", value: "hours" };
              break;
            case "days":
              selectedPrecission = { label: "Dias", value: "days" };
              break;
            case "weeks":
              selectedPrecission = { label: "Semanas", value: "weeks" };
              break;
            case "months":
              selectedPrecission = { label: "Meses", value: "months" };
              break;
            default:
              selectedPrecission = { label: "Meses", value: "months" };
          }
        } else {
          selectedPrecission = { label: "Meses", value: "months" };
        }
        if (filters.hasOwnProperty("period")) {
          selectedPeriod = filters["period"];
        } else {
          selectedPeriod = { label: "Semanas", value: "weekReports" };
        }
      } else {
        selectedPrecission = { label: "Meses", value: "months" };
      }

      this.setState({ selectedPeriod: selectedPeriod });
      this.setState({ selectedPrecission: selectedPrecission });

      let modesSelected;

      // check if there is an element 'modes' in the filters
      if (filters.hasOwnProperty("modes")) {
        if (filters["modes"].length > 0) {
          modesSelected = filters["modes"];
        } else {
          modesSelected = [
            { label: "Conducción", value: "driving" },
            { label: "Trabajo", value: "working" },
            { label: "Descanso", value: "resting" },
            { label: "Disponible", value: "available" },
          ];
        }
      } else {
        modesSelected = [
          { label: "Conducción", value: "driving" },
          { label: "Trabajo", value: "working" },
          { label: "Descanso", value: "resting" },
          { label: "Disponible", value: "available" },
        ];
      }


      this.setState({ modes: modesSelected });

      let dates = await this.props.myEmployee.graphDetails["dates"];

      // //console.log("displaytype", this.props.displayType)
      if (dates.length === 0 && this.props.displayType !== "trips") {
        // if (dates.length === 0) {
        //this.setState({ noData: true })
      } else {
        if (this.props.displayType === "graphs") {
          let graphDetails = await this.props.myEmployee.graphDetails;
          let elementGeneral;

          for (let m = 0; m < modesSelected.length; m++) {
            elementGeneral = { type: "bar", x: dates, y: graphDetails[modesSelected[m].value], name: modesSelected[m].value, metadata: modesSelected[m].value };
            graphData.push(elementGeneral);
          }
        } else if (this.props.displayType === "list") {
          if (this.props.device === "desktop") {
            this.handleChangeModes("allConflict");
          } else {
            this.handleChangeModesMobile("allConflict");
          }
        } else if (this.props.displayType === "trips") {
          let initTrips = this.props.tripsListInit;
          let endTrips = initTrips + 10;
          this.displayTrips(initTrips, endTrips);
        } else if (this.props.displayType === "periods") {
          let initPeriods = this.props.periodsListInit;
          let endPeriods = initPeriods + 10;
          this.displayPeriods(initPeriods, endPeriods);
        } else if (this.props.displayType === "dayConsult") {
          let initPeriods = this.props.periodsListInit;
          let endPeriods = initPeriods + 10;

          if (this.props.device === "desktop") {
            await this.dayConsult(initPeriods, endPeriods);
          } else {
            await this.dayConsultMobile(initPeriods, endPeriods);
          }
        }

        let defaultStart;
        let defaultEnd;

        try {
          defaultStart = new Date(this.props.myEmployee.startString);
          defaultEnd = new Date(this.props.myEmployee.endString);
        } catch (err) {
          this.props.setLoading(false);
          defaultStart = new Date();
          defaultEnd = new Date();
        }

        let ratioBeutyfull = Math.floor(this.props.myEmployee.ratioDrTtl);

        this.setState({
          dateDesde: defaultStart,
          dateHasta: defaultEnd,
          name: this.props.myEmployee.name,
          appellidos: this.props.myEmployee.appellidos,
          base_salary: this.props.myEmployee.base_salary,
          nocturnas: nocturnas,
          totalWorkingHours: totalWorkingHours,
          totalDrivingHours: totalDrivingHours,
          totalRestingHours: totalRestingHours,
          totalAvailableHours: totalAvailableHours,
          totalOtherWorkingHours: totalOtherWorkingHours,
          ratioDrTtl: this.props.myEmployee.ratioDrTtl,
          total_nat_feed_diet: this.props.myEmployee.diets["total_nat_feed_diet"],
          total_int_feed_diet: this.props.myEmployee.diets["total_int_feed_diet"],
          total_nat_sleep_diet: this.props.myEmployee.diets["total_nat_sleep_diet"],
          total_int_sleep_diet: this.props.myEmployee.diets["total_int_sleep_diet"],
          lastDate: this.props.myEmployee.lastDate,
          lastPlaceDate: this.props.myEmployee.lastPlaceDate,
          totalFest: this.props.myEmployee.diets["totalFest"],
          totalWeekEnd: this.props.myEmployee.diets["totalWeekEnd"],
          totalPlusKmNat: this.props.myEmployee.diets["totalPlusKmNat"],
          totalPlusKmInt: this.props.myEmployee.diets["totalPlusKmInt"],
          extraHoursToPay: extraHoursToPay,
        });
      }
    }
  }

  async searchEditEmployeeDetails(key) {
    try {
      let employee;

      if (this.props.isShowModalEmployeeAlpha) {
        this.props.seslectDisplayMode("list");
      }
      employee = await this.props.search(
        this.state.dayConsultToShow[key]["employee_id"],
        this.state.dayConsultToShow[key]["init"] - 100,
        this.state.dayConsultToShow[key]["term"] + 100,
        {},
        true,
        "list"
      );
      if (employee) {
        await this.props.showCreationFields();
      }
    } catch (err) {
      alert("error al intentar buscar un empleado");
    }
  }

  actualizarEstadoYAlmacenarEmployees = (nuevoEstado) => {
    const { stateHistory, ...restOfState } = this.state;
    const newState = { ...restOfState, ...nuevoEstado };

    this.setState((prevState) => ({
      ...prevState,
      ...nuevoEstado,
      stateHistory: [...prevState.stateHistory, { ...prevState }],
    }));
  };

  volverAlPasadoEmployees = () => {
    const { stateHistory, stateFuture } = this.state;

    if (stateHistory.length >= 1) {
      const newState = stateHistory[stateHistory.length - 1];
      const updatedHistory = stateHistory.slice(0, -1);


      this.setState({
        ...newState,
        stateHistory: updatedHistory,
        stateFuture: [...stateFuture, { ...this.state }],
      });


      this.props.seslectDisplayMode(newState.displayType);
    }
  };

  regresoAlFuturoEmployees = () => {
    const { stateFuture } = this.state;

    if (stateFuture && stateFuture.length >= 1) {
      const nextState = stateFuture[stateFuture.length - 1];
      const updatedFuture = stateFuture.slice(0, -1);

      this.setState({
        ...nextState,
        stateFuture: updatedFuture,
      });
      this.props.seslectDisplayMode(nextState.displayType);
    }
  };

  handleClick = async (key) => {
    await this.setState({ activities: [] });
    await this.props.limpiarDietas();
    await this.props.selectFilterAlerts(false);
    await this.searchEditEmployeeDetails(key);
  };

  async formatDate(date, showTime) {
    try {
      let d = new Date(date);
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      if (day.length < 2) {
        day = "0" + day;
      }
      //let year = d.getFullYear();
      let hours = d.getHours().toString();
      if (hours.length < 2) {
        hours = "0" + hours;
      }
      let minutes = d.getMinutes().toString();
      if (minutes.length < 2) {
        minutes = "0" + minutes;
      }
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      let dateToShow;
      if (showTime) {
        dateToShow = day + "-" + month + " " + hours + ":" + minutes;
      } else {
        dateToShow = day + "-" + month;
      }
      return dateToShow;
    } catch (err) {
      alert("Error al formatear la fecha");
    }
  }

  async displayActivities(init, end) {
    try {
      //let activitiesList = await this.props.myEmployee.dataToAnalize;
      let activitiesList = this.props.filteredActivities;

      if (activitiesList.length === 0) {
        activitiesList = await this.props.myEmployee.dataToAnalize;
      }

      var selected = [];

      for (let m = 0; m < this.state.modes.length; m++) {
        let modeInList = await this.state.modes[m];
        let modeToLookUpFor;

        switch (modeInList["value"]) {
          case "resting":
            modeToLookUpFor = "Rest";
            break;
          case "working":
            modeToLookUpFor = "Working";
            break;
          case "driving":
            modeToLookUpFor = "Driving";
            break;
          case "available":
            modeToLookUpFor = "Available";
            break;
        }

        selected.push(modeToLookUpFor);
      }

      var countries = {
        Spain: "España",
        France: "Francia",
        Italy: "Italia",
        Belgium: "Bélgica",
        Netherlands: "Países Bajos",
        Germany: "Alemania",
        Poland: "Polonia",
        Czechia: "Rep. Checa",
        "Czech Republic": "Rep. Checa",
        Denmark: "Dinamarca",
        Slovakia: "Eslovaquia",
        Hungary: "Hungría",
        Austria: "Austria",
        Switzerland: "Suiza",
        Sweden: "Suecia",
        Luxemburg: "Luxemburgo",
        Finland: "Finlandia",
        Norway: "Noruega",
        Romania: "Rumanía",
        Bulgaria: "Bulgaria",
        Croatia: "Croacia",
        Greece: "Grecia",
        Slovenia: "Eslovenia",
        Estonia: "Estonia",
        Ireland: "Irlanda",
        Lithuania: "Lituania",
        Latvia: "Letonia",
        Portugal: "Portugal",
        Serbia: "Serbia",
        Albania: "Albania",
      };

      const activitiesWithselectedMode = activitiesList.filter(({ type }) => selected.includes(type));
      if (this.state.filterAlerts && activitiesWithselectedMode.length > 0) {
        for (let i = activitiesWithselectedMode.length - 1; i >= 0; i--) {
          const activity = activitiesWithselectedMode[i];
          var details = activity["datails"];

          if (details === undefined) {
            details = null;
          }
          if ((activity.type === "Working" || activity.type === "Available") && activity.duration > 60 && details === null) {
            continue; // Continuar con la siguiente actividad sin eliminar esta
          } else if (activity.provisional === true) {
            continue; // Continuar con la siguiente actividad sin eliminar esta
          } else if (details !== null) {
            continue; // Continuar con la siguiente actividad sin eliminar esta
          } else {
            activitiesWithselectedMode.splice(i, 1); // Eliminar la actividad de la lista
          }
        }
      }

      let pages = Math.ceil(activitiesWithselectedMode.length / 10);
      this.setState({ pages: pages });

      let activities = await activitiesWithselectedMode.slice(init, end);

      if (activities.length === 0) {
        if (this.state.numReloads < 10) {
          // Si aún no se ha alcanzado el límite de recargas
          let myEmployeeID = this.props.myEmployee.id;
          let filters = this.state.filters;
          let desde = this.state.dateDesde;

          if (desde === "") {
            desde = 0;
          } else {
            desde = parseInt(desde.getTime() / 1000);
          }

          let hasta = this.state.dateHasta;

          if (hasta === "") {
            hasta = 0;
          } else {
            hasta = parseInt(hasta.getTime() / 1000);
          }

          let numReloads = this.state.numReloads + 1;
          this.setState({ numReloads: numReloads });
          this.props.editActivityListInit(1);
          this.setState({ page: 1 });

          if (numReloads >= 10) {
            return;
          }
        }
      }

      for (let i = 0; i < activities.length; i++) {
        let dateInt = parseInt(activities[i]["start"]) * 1000;
        let dateFormat = await this.formatDate(dateInt, true);
        activities[i]["startFormat"] = dateFormat;
        let type = activities[i]["type"];
        let typeFormat;
        let displaced = "";
        let place = activities[i]["place"];

        if (countries.hasOwnProperty(place)) {
          activities[i]["place"] = countries[place];
        }

        if (activities[i]["displaced"] === true) {
          displaced = "Desplazado";
        }

        let regionE = "";

        if (activities[i]["region"] != "Unknown") {
          regionE = ", " + activities[i]["region"];
        }

        switch (type) {
          case "Rest":
            typeFormat = "Descanso";
            break;
          case "Working":
            typeFormat = "Trabajando";
            break;
          case "Driving":
            typeFormat = "Conducción";
            break;
          case "Available":
            typeFormat = "Disponible";
            break;
          case "Desconocido":
            typeFormat = "Desconocido";
            break;
        }

        let dietFormat = "";
        let diet = activities[i]["dietType"];

        dietFormat = diet;

        let durationFormat = await this.formatOnlyTime(parseInt(activities[i]["duration"]));
        let nocturnasFormat;

        if (activities[i]["nocturnas"]) {
          nocturnasFormat = await this.formatOnlyTime(parseInt(activities[i]["nocturnas"]));

        } else {
          nocturnasFormat = "";
        }

        let colorConduccion;
        if (type === "Driving" && parseInt(activities[i]["duration"]) > 270) {
          colorConduccion = "red";
        }

        activities[i]["typeFormat"] = typeFormat;
        activities[i]["durationFormat"] = durationFormat;
        activities[i]["dietFormat"] = dietFormat;
        activities[i]["displaced"] = displaced;
        activities[i]["regionE"] = regionE;
        activities[i]["colorConduccion"] = colorConduccion;
        activities[i]["nocturnasFormat"] = nocturnasFormat;
      }

      let page = Math.ceil(end / 10);
      this.setState({ activities: activities });
      this.setState({ page: page });
      this.props.editActivityListInit(0);

      return pages;
    } catch (err) {
      alert("Error al mostrar las actividades");
    }

  }

  async formatOnlyTime(minutes) {
    try {
      var durationFormat;
      let durHours = Math.floor(minutes / 60);
      let durMinutes = minutes - durHours * 60;
      let durMinutesRound = await Math.floor(durMinutes);
      let durMinutesString = await String(durMinutesRound);

      if (durMinutesString.length < 2) {
        durMinutesString = "0" + durMinutesString;
      }

      durationFormat = String(durHours) + ":" + durMinutesString;
      return durationFormat;
    } catch (err) {
      setTimeout(() => window.location.reload(), 2000);
    }
  }

  async displayTrips(init, end) {

    try {
      let tripsList = await this.props.myEmployee.trips;
      tripsList = await tripsList.filter((trip) => trip.trip_date !== "");

      // //console.log("thispropsmyemployee", this.props.myEmployee.trips);
      //console.log("triplist", tripsList);

      let pagesTrips = Math.ceil(tripsList.length / 10);
      let pageTrip = this.state.pageTrip > pagesTrips ? pagesTrips : this.state.pageTrip;
      let initTrips = (pageTrip - 1) * 10;
      let endTrips = pageTrip * 10;

      let tripsToshow = await tripsList.slice(initTrips, endTrips);

      for (let i = 0; i < tripsToshow.length; i++) {
        let dateInt = parseInt(tripsToshow[i]["trip_date"]) * 86400000 + Date.parse(new Date(2022, 0, 1));
        let dateFormat = await this.formatDate(dateInt, false);
        tripsToshow[i]["dateToShow"] = dateFormat;

        let modality = tripsToshow[i]["mode"];
        let modalityFormat;

        switch (modality) {
          case "complete":
            modalityFormat = "Completa";
            break;
          case "empty":
            modalityFormat = "En vacío";
            break;
          case "partial":
            modalityFormat = "Grupaje";
            break;
          default:
            modalityFormat = "No indicado";
            break;
        }

        tripsToshow[i]["modeToShow"] = modalityFormat;

        let distance = parseInt(tripsToshow[i]["minAmount"]);
        let duration;
        if (isNaN(distance)) {
          duration = 0;
        } else {
          duration = distance / 60;
        }
        var durationFormat;

        let durHours = Math.floor(duration / 60);
        let durMinutes = String(Math.floor(duration - durHours * 60));
        if (durMinutes.length < 2) {
          durMinutes = "0" + durMinutes;
        }
        durationFormat = String(durHours) + ":" + durMinutes;
        tripsToshow[i]["durationFormat"] = durationFormat;
      }

      this.setState({
        pagesTrips: pagesTrips,
        pageTrip: pageTrip,
        tripsToshow: tripsToshow,
      });

      this.props.editTripsListInit(initTrips);
      return pagesTrips;
    } catch (err) {
      alert("Error al mostrar los viajes");

    }

    let pageTrip = Math.ceil(end / 10);
    this.setState({ pageTrip: pageTrip });
    this.setState({ tripsToshow: tripsToshow });
    this.props.editTripsListInit(init);
    return pagesTrips;
    // } catch (err) {
    //   alert("Error al mostrar los viajes")
    // }
  }

  async handleChangePeriods(period) {
    try {
      let filters = this.state.filters;
      filters["period"] = period;

      await this.setState({
        filters: filters,
        selectedPeriod: period,
      });

      this.props.editPeriodsListInit(0);
      await this.displayPeriods(0, 10);
    } catch (err) {
      alert("Error al seleccionar la precisión");
    }
  }

  async displayPeriods(init, end) {
    try {
      let periodsList = await this.props.myEmployee.employee_complies;
      let periodSelected = this.state.selectedPeriod.value;

      periodsList = await periodsList[periodSelected];
      let pagesPeriods;
      let periodsToShow;
      try {
        pagesPeriods = Math.ceil(periodsList.length / 10);
        periodsToShow = await periodsList.slice(init, end);
      } catch (err) {
        pagesPeriods = 1;
        periodsToShow = [];
      }
      this.setState({ pagesPeriods: pagesPeriods });

      let workAlerts = "Correcto";
      let availableAlerts = "Correcto";
      let workAlertsColor = "Green";
      let availableAlertsColor = "Green";

      for (let i = 0; i < periodsToShow.length; i++) {
        workAlerts = "Correcto";
        availableAlerts = "Correcto";
        workAlertsColor = "Green";
        availableAlertsColor = "Green";

        if (periodsToShow[i].isGoingToSurpassWorked) {
          workAlerts = "Cercano";
          workAlertsColor = "orange";
        }

        if (periodsToShow[i].surpassedWorked) {
          workAlerts = "Superado";
          workAlertsColor = "red";
        }

        if (periodsToShow[i].isGoingToSurpassAvailabled) {
          availableAlerts = "Cercano";
          availableAlertsColor = "orange";
        }
        if (periodsToShow[i].surpassedAvailable) {
          availableAlerts = "Superado";
          availableAlertsColor = "red";
        }

        let dateInt = parseInt(periodsToShow[i].init) * 1000;
        let dateFormat = await this.formatDate(dateInt, false);

        let hoursWorked = await this.formatOnlyTime(periodsToShow[i].hoursWorked);
        let avWorkedHours = await this.formatOnlyTime(periodsToShow[i].avWorkedHours);
        let hoursAvailable = await this.formatOnlyTime(periodsToShow[i].hoursAvailable);
        let extraHours = await this.formatOnlyTime(periodsToShow[i].extraHours);

        periodsToShow[i]["dateFormatShow"] = await dateFormat;
        periodsToShow[i]["workAlertsShow"] = await workAlerts;
        periodsToShow[i]["workAlertsColor"] = await workAlerts;
        periodsToShow[i]["workAlertsColor"] = await workAlertsColor;
        periodsToShow[i]["availableAlertsShow"] = await availableAlerts;
        periodsToShow[i]["availableAlertsColor"] = await availableAlertsColor;
        periodsToShow[i]["hoursWorkedShow"] = await hoursWorked;
        periodsToShow[i]["avWorkedHoursShow"] = await avWorkedHours;
        periodsToShow[i]["hoursAvailableShow"] = await hoursAvailable;
        periodsToShow[i]["extraHoursShow"] = await extraHours;
      }

      let pagePeriods = Math.ceil(end / 10);
      await this.setState({ pagePeriods: pagePeriods });
      await this.setState({ periodsToShow: periodsToShow });
      this.props.editPeriodsListInit(init);
      return pagePeriods;
    } catch (err) {
      alert("Error al mostrar los periodos");
    }
  }

  async handleChangePrecission(precission) {
    try {
      let filters = this.state.filters;
      filters["precission"] = precission.value;

      this.setState({
        filters: filters,
        selectedPrecission: precission,
      });
    } catch (err) {
      alert("Error al seleccionar la precisión");
    }
  }

  async dayConsult(init, end, iterator) {
    try {
      let periodsListDayConsult = await this.props.myEmployee.employee_complies;

      periodsListDayConsult = await periodsListDayConsult["dailyReports"];
      let pagesDays;
      let dayConsultToShow;
      if (periodsListDayConsult) {
        pagesDays = Math.ceil(periodsListDayConsult.length / 10);
        dayConsultToShow = periodsListDayConsult.slice(init, end);
      } else {
        pagesDays = 1;
        dayConsultToShow = [];
        this.props.seslectDisplayMode("dayConsult");
        //this.props.search(myEmployeeID, desde, hasta, filters, true)
      }
      this.setState({ pagesDays: pagesDays });

      for (let i = 0; i < dayConsultToShow.length; i++) {
        let dateInt = (await parseInt(dayConsultToShow[i].init)) * 1000;

        if (isNaN(dateInt)) {
          await this.props.seslectDisplayMode("list");
          //}

          //)
        }

        let dateFormat = await this.formatDate(dateInt, false);

        let hoursAvailable = await this.formatOnlyTime(parseInt(dayConsultToShow[i].availableHours));

        let totalWorkingHours = await this.formatOnlyTime(parseInt(dayConsultToShow[i].workingHours));

        let diet = dayConsultToShow[i].dietType;

        let totalDrivingHours = await this.formatOnlyTime(parseInt(dayConsultToShow[i].drivingHours));

        let totalRestHours = await this.formatOnlyTime(parseInt(dayConsultToShow[i].restHours));
        let kilometers = dayConsultToShow[i].kilometers;
        let suspectful = "";
        let isSuspectFull = dayConsultToShow[i].suspList;
        if (isSuspectFull) {
          suspectful = "Sospechoso";
        }

        dayConsultToShow[i]["dateFormatShow"] = await dateFormat;
        dayConsultToShow[i]["totalWorkingHours"] = await totalWorkingHours;
        dayConsultToShow[i]["diet_amount_to_show"] = (await dayConsultToShow[i].dietAmount) / 1000;
        dayConsultToShow[i]["diet"] = await diet;
        dayConsultToShow[i]["kilometers"] = await kilometers;
        dayConsultToShow[i]["hoursAvailable"] = await hoursAvailable;
        dayConsultToShow[i]["totalDrivingHours"] = await totalDrivingHours;
        dayConsultToShow[i]["totalRestHours"] = await totalRestHours;
        dayConsultToShow[i]["suspectful"] = await suspectful;
      }


      let pageDays = Math.ceil(end / 10);


      await this.setState({ pageDays: pageDays });
      await this.setState({ dayConsultToShow: dayConsultToShow });
      this.props.editPeriodsListInit(init);
      return pageDays;
    } catch (err) {
      setTimeout(() => window.location.reload(), 2000);
      return <div>Error al cargar los detalles del empleado</div>;
    }
  }

  async dayConsultMobile(init, end) {
    try {
      let periodsListDayConsult = await this.props.myEmployee.employee_complies;
      periodsListDayConsult = await periodsListDayConsult["dailyReports"];

      let pagesDays;
      let dayConsultToShow;

      if (periodsListDayConsult) {
        pagesDays = Math.ceil(periodsListDayConsult.length / 10);
        dayConsultToShow = periodsListDayConsult.slice(init, end);
      } else {
        pagesDays = 1;
        dayConsultToShow = [];
        this.props.seslectDisplayMode("dayConsult");
        //this.props.search(myEmployeeID, desde, hasta, filters, true)
      }

      this.setState({ pagesDays: pagesDays });

      for (let i = 0; i < dayConsultToShow.length; i++) {
        let dateInt = parseInt(dayConsultToShow[i].init) * 1000;

        if (isNaN(dateInt)) {
          let myEmployeeID = this.props.myEmployee.id;
          let filters = this.state.filters;

          let desde = this.state.dateDesde;
          if (desde === "") {
            desde = 0;
          } else {
            desde = parseInt(desde.getTime() / 1000);
          }

          let hasta = this.state.dateHasta;
          if (hasta === "") {
            hasta = 0;
          } else {
            hasta = parseInt(hasta.getTime() / 1000);
          }
          //this.props.seslectDisplayMode('text')

          //setTimeout(() => this.props.search(myEmployeeID, desde, hasta, filters, true), 0)
          await setTimeout(() => window.location.reload(), 0);
          //this.props.seslectDisplayMode('dayConsult')
        }

        let dateFormat = await this.formatDate(dateInt, false);
        let hoursAvailable = await this.formatOnlyTime(parseInt(dayConsultToShow[i].availableHours));
        let diet = dayConsultToShow[i].dietType;
        let kilometers = dayConsultToShow[i].kilometers;

        let suspectful = "";
        if (dayConsultToShow[i].susList) {
          suspectful = "Sospechoso";
        }

        dayConsultToShow[i]["dateFormatShow"] = await dateFormat;
        dayConsultToShow[i]["diet"] = await diet;
        dayConsultToShow[i]["kilometers"] = await kilometers;
        dayConsultToShow[i]["hoursAvailable"] = await hoursAvailable;
        dayConsultToShow[i]["suspectful"] = await suspectful;
      }

      let pageDays = Math.ceil(end / 10);

      await this.setState({ pageDays: pageDays });
      await this.setState({ dayConsultToShow: dayConsultToShow });
      this.props.editPeriodsListInit(init);
      return pageDays;
    } catch (err) {
      setTimeout(() => window.location.reload(), 2000);
      return <div>Error al cargar los detalles del empleado</div>;
    }
  }

  async allCheckboxes() {
    try {
      const checkboxes = [this.checkbox1Ref.current, this.checkbox2Ref.current, this.checkbox3Ref.current, this.checkbox4Ref.current];

      this.setState((prevState) => ({
        allChecked: !prevState.allChecked,
      }));
      setTimeout(() => changeBoxes(this), 1);

      const changeBoxes = (component) => {
        component.checkbox1Ref.current.checked = component.state.allChecked;
        component.checkbox2Ref.current.checked = component.state.allChecked;
        component.checkbox3Ref.current.checked = component.state.allChecked;
        component.checkbox4Ref.current.checked = component.state.allChecked;

        if (this.props.device === "desktop") {
          if (this.state.allChecked) {
            component.selectAllRef.current.checked = component.state.allChecked;
            this.handleChangeModes("all");
          }
        } else {
          if (this.state.allChecked) {
            this.handleChangeModesMobile("all");
          }
        }
      };
    } catch (err) {
      alert("Error al seleccionar los checkboxes");
    }
  }

  async handleChangeModes(variableRecibe) {

    try {
      let modesList = [];
      let nameIt;
      let dictValue;
      let updatedDict = { ...this.state.dictOriginal };
      if (variableRecibe === "alertCheck") {
        await this.setState({ numReloads: 0 });
        await this.setState({ filterAlerts: this.selectAlertRef.current.checked });
        await this.props.putFilterAlerts();
      } else {
        if (variableRecibe === "allConflict") {
          Object.keys(updatedDict).forEach((key) => {
            updatedDict[key] = true;
          });
          this.setState({ dictOriginal: updatedDict });

          setTimeout(() => startList(), 1);

          function startList() {
            modesList.push({ label: "Disponible", value: "available" });
            modesList.push({ label: "Conducción", value: "driving" });
            modesList.push({ label: "Descanso", value: "resting" });
            modesList.push({ label: "Trabajo", value: "working" });
          }
        } else {
          const checkboxes = [this.checkbox1Ref.current, this.checkbox2Ref.current, this.checkbox3Ref.current, this.checkbox4Ref.current];
          const allChecked = checkboxes.every((checkbox) => checkbox.checked);
          const allUnchecked = checkboxes.every((checkbox) => !checkbox.checked);
          this.setState({ allChecked: allChecked });
          this.selectAllRef.current.checked = allChecked;
          if (allUnchecked) {
            this.allCheckboxes();
          }

          this.selectAllRef.current.disabled = allChecked;
        }

        if (updatedDict.hasOwnProperty(variableRecibe)) {
          updatedDict[variableRecibe] = !updatedDict[variableRecibe];
          this.setState({ dictOriginal: updatedDict });
          setTimeout(() => verification(), 1);

          function verification() {
            for (let index in updatedDict) {
              if (updatedDict[index]) {
                switch (index) {
                  case "availableCheck":
                    nameIt = "Disponible";
                    dictValue = "available";
                    break;
                  case "drivingCheck":
                    nameIt = "Conducción";
                    dictValue = "driving";
                    break;
                  case "restingCheck":
                    nameIt = "Descanso";
                    dictValue = "resting";
                    break;
                  case "workingCheck":
                    nameIt = "Trabajo";
                    dictValue = "working";
                    break;
                }
                modesList.push({ label: nameIt, value: dictValue });
              }
            }
          }
        } else if (variableRecibe == "all") {
          Object.keys(updatedDict).forEach((key) => {
            updatedDict[key] = true;
          });
          this.setState({ dictOriginal: updatedDict });


          setTimeout(() => startList(), 1);

          function startList() {
            modesList.push({ label: "Disponible", value: "available" });
            modesList.push({ label: "Conducción", value: "driving" });
            modesList.push({ label: "Descanso", value: "resting" });
            modesList.push({ label: "Trabajo", value: "working" });
          }

        }


        let filters = this.state.filters;
        filters["modes"] = modesList;

        await this.setState({
          filters: filters,
          modes: modesList,
        });
      }

      this.props.editActivityListInit(0);
      setTimeout(() => this.displayActivities(0, 10), 1);
    } catch (err) {
      alert("Error al seleccionar el modo");
    }

  }

  async handleChangeModesMobile(variableRecibe) {
    try {
      let modesList = [];
      let nameIt;
      let dictValue;
      const updatedDict = { ...this.state.dictOriginal };

      if (variableRecibe === "allConflict") {
        Object.keys(updatedDict).forEach((key) => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({ label: "Disponible", value: "available" });
          modesList.push({ label: "Conducción", value: "driving" });
          modesList.push({ label: "Descanso", value: "resting" });
          modesList.push({ label: "Trabajo", value: "working" });
        }
      } else {
        const checkboxes = [this.checkbox1Ref.current, this.checkbox2Ref.current, this.checkbox3Ref.current, this.checkbox4Ref.current];
        const allChecked = checkboxes.every((checkbox) => checkbox.checked);
        const allUnchecked = checkboxes.every((checkbox) => !checkbox.checked);
        this.setState({ allChecked: allChecked });
        if (allUnchecked) {
          this.allCheckboxes();
        }
      }

      if (variableRecibe == "all") {
        Object.keys(updatedDict).forEach((key) => {
          updatedDict[key] = true;
        });
        this.setState({ dictOriginal: updatedDict });

        setTimeout(() => startList(), 1);

        function startList() {
          modesList.push({ label: "Disponible", value: "available" });
          modesList.push({ label: "Conducción", value: "driving" });
          modesList.push({ label: "Descanso", value: "resting" });
          modesList.push({ label: "Trabajo", value: "working" });
        }
      }
      if (updatedDict.hasOwnProperty(variableRecibe)) {
        updatedDict[variableRecibe] = !updatedDict[variableRecibe];
        this.setState({ dictOriginal: updatedDict });
        setTimeout(() => verification(), 1);

        function verification() {
          for (let index in updatedDict) {
            if (updatedDict[index]) {
              switch (index) {
                case "availableCheck":
                  nameIt = "Disponible";
                  dictValue = "available";
                  break;
                case "drivingCheck":
                  nameIt = "Conducción";
                  dictValue = "driving";
                  break;
                case "restingCheck":
                  nameIt = "Descanso";
                  dictValue = "resting";
                  break;
                case "workingCheck":
                  nameIt = "Trabajo";
                  dictValue = "working";
                  break;
              }
              modesList.push({ label: nameIt, value: dictValue });
            }
          }
        }
      }

      let filters = this.state.filters;
      filters["modes"] = modesList;

      await this.setState({
        filters: filters,
        modes: modesList,
      });

      this.props.editActivityListInit(0);
      setTimeout(() => this.displayActivities(0, 10), 1);
    } catch (err) {
      alert("Error al seleccionar el modo (2)");
    }
  }

  async handleChangeDesde(dateDesde) {
    try {
      this.setState({
        dateDesde: dateDesde,
      });
    } catch (err) {
      alert("Error al indicar la fecha inicial");
    }
  }

  async handleChangeHasta(dateHasta) {
    try {
      //get desdeHasta timestamp
      this.setState({
        dateHasta: dateHasta,
      });
    } catch (err) {
      alert("Error al indicar la fecha final");
    }
  }

  handleCheckboxChange = () => {
    if (this.checkbox5Ref.current.checked) {
      this.props.filtrarDietas();
      setTimeout(() => this.displayActivities(0, 10), 250);
    } else {
      this.props.deshacerFiltrado();
      setTimeout(() => this.displayActivities(0, 10), 250);
    }
  };


  // toggleCreateTrip = () => {
  //   this.setState(
  //     (prevState) => ({
  //       isToggleCreateTrip: !prevState.isToggleCreateTrip,
  //       isToggleShowHolidays: false,
  //     }),
  //     () => {
  //       this.props.actualizarEstadoYAlmacenar();
  //       this.actualizarEstadoYAlmacenarEmployees();
  //       this.props.showCreateTripFields();
  //     }
  //   );
  // };

  // toggleShowHolidays = () => {
  //   this.setState(
  //     (prevState) => ({
  //       isToggleShowHolidays: !prevState.isToggleShowHolidays,
  //       isToggleCreateTrip: false,
  //     }),
  //     () => {
  //       this.props.actualizarEstadoYAlmacenar();
  //       this.actualizarEstadoYAlmacenarEmployees();
  //       this.props.showHolidays();
  //     }
  //   );
  // };

  render() {
    //try {
   

      //VARIABLES DE RENDER

      var display
      var textButtonColor = "white"
      var graphButtonColor = "white"
      var listButtonColor = "white"
      var tripsButtonColor = "white"
      var tripButton = <div></div>
      var jornadaButton = <div></div>
      var dayConsultButton = <div></div>
      var jornadaButtonColor = "white"
      var dayConsultButtonColor = "white"
      var registerDetails = <div></div>
      const multiselectWidth = this.state.windowWidth / 7 + "px"
      let width = window.innerWidth * 60 / 100
      let height = window.innerHeight * 50 / 100
      var controlpanel
      var displayMode = this.props.displayType
      var optionButons = <div></div>
      let empId = this.props.myEmployee.id  
      tripButton


      if(this.state.showTrip && this.props.device === "desktop"){
        tripButton = 
          <div className="positive button w-100 b-white ml-5-px"
            onClick={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()          
              this.props.seslectDisplayMode('trips')
            }}>
              Viajes
          </div>        
      }else if (this.state.showTrip){
        tripButton = 
          <div className="positive button w-100 bg-white"
            onClick={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()
              this.props.seslectDisplayMode('trips')
            }}>
              Viajes
          </div>
      }

      if (this.props.device === "desktop"){
        jornadaButton = 
          <div className="positive button w-100 b-white ml-5-px"
            onClick={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()
              this.props.seslectDisplayMode('periods')
            }}>
              Jornadas
          </div>
      }else{
        jornadaButton = 
          <div className="positive button w-50 b-white"
              onClick={(event) => {
                event.preventDefault()
                this.props.actualizarEstadoYAlmacenar()
                this.actualizarEstadoYAlmacenarEmployees()
                this.props.seslectDisplayMode('periods')
              }}>
                Jornadas
          </div>
      }

      if (this.props.device === "desktop"){
        dayConsultButton = 
          <div className="positive button w-50 b-white ml-5-px"
            onClick={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()
              this.props.seslectDisplayMode('dayConsult')
            }}>
              Consulta por día
          </div>
      }else{
        dayConsultButton = 
          <div className="positive button w-50 bg-white"
            onClick={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()
              this.props.seslectDisplayMode('dayConsult')
            }}>
              Consulta por día
          </div>
      }


    switch (this.props.displayType) {
      case "graphs":
        graphButtonColor = "#dddddb";
        break;
      case "text":
        textButtonColor = "#dddddb";
        break;
      case "periods":
        jornadaButtonColor = "#dddddb";
        break;
      case "trips":
        tripsButtonColor = "#dddddb";
        break;
      case "dayConsult":
        dayConsultButtonColor = "#dddddb";
        break;
      default:
        listButtonColor = "#dddddb";
        break;
    }
    var paginator = <div></div>
    if (this.state.pages > 1 && this.state.page !== "") {
      var page = this.state.page;
      var pages = this.state.pages;
      var init = (page - 1) * 10;
      var end = page * 10;
      
      }

      switch (this.props.displayType) {
        case 'graphs':
          graphButtonColor = "#dddddb"
          break;
        case 'text':
          textButtonColor = "#dddddb"
          break;
        case 'periods':
          jornadaButtonColor = "#dddddb"
          break;
        case 'trips':
          tripsButtonColor = "#dddddb"
          break;
        case 'dayConsult':
          dayConsultButtonColor = "#dddddb"
          break;
        default:
          listButtonColor = "#dddddb"
          break;
      }
      
      if (this.state.pages > 1 && this.state.page !== ""){
        var page = this.state.page
        var pages = this.state.pages
        var init = (page - 1) * 10
        var end = page * 10
        var paginator =
          <div className='w-100 text-center'>
            <br/>
            <div> Página {page} de {pages} </div>
            <table className='w-100'>
              <tbody className='w-100'>
                <tr className='w-100'>
                  <td className='w-25'>                  
                  </td>
                  <td  className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (page > 1) {
                        this.setState({ page: page - 1 });
                        init = init - 10;
                        end = end - 10;
                      }
                      this.displayActivities(init, end)}}>
                      Anterior
                    </button>
                  </td>
                  <td  className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (page < pages) {
                        this.setState({ page: page + 1 });
                        init = init + 10;
                        end = end + 10;
                      }
                      this.displayActivities(init, end)}}>
                      Siguiente
                    </button>
                  </td>
                  <td className='w-25'>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      }

      if (this.state.pagesTrips > 1 && this.state.pageTrip !== "") {
        var pageTrip = this.state.pageTrip
        var pagesTrips = this.state.pagesTrips
        var initTrips = (pageTrip - 1) * 10
        var endTrips = pageTrip * 10

        var paginatorTrips =
          <div className='w-100 text-center'>
            <br/>
            <div> Página {pageTrip} de {pagesTrips} </div>
            <table className='w-100'>
              <tbody className='w-100'>
                <tr className='w-100'>
                  <td className='w-25'>                  
                  </td>
                  <td  className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (pageTrip > 1) {
                        this.setState({ pageTrip: pageTrip - 1 });
                        initTrips = initTrips - 10;
                        endTrips = endTrips - 10;
                      }

                      this.displayTrips(initTrips, endTrips)}}>
                      Anterior
                    </button>
                  </td>
                  <td  className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (pageTrip < pagesTrips) {
                        this.setState({ pageTrip: pageTrip + 1 });
                        initTrips = initTrips + 10;
                        endTrips = endTrips + 10;
                      }
                      this.displayTrips(initTrips, endTrips)}}>
                      Siguiente
                    </button>
                  </td>
                  <td className='w-25'>                  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      }

      if (this.state.pagesDays > 1 && this.state.pageDays !== "") {

        var pageDays = this.state.pageDays
        var pagesDays = this.state.pagesDays
        var init = (pageDays - 1) * 10
        var end = pageDays * 10

        var paginatorDayConsult =
          <div className='w-100 text-center'>
            <br/>
            <div> Página {pageDays} de {pagesDays} </div>
            <table className='w-100'>
              <tbody className='w-100'>
                <tr className='w-100'>
                  <td className='w-25'></td>
                  <td className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (pageDays > 1) {
                        this.setState({ pageDays: pageDays - 1 });
                        init = init - 10;
                        end = end - 10;
                      }
                      this.dayConsult(init, end, 0)}}>
                      Anterior
                    </button>
                  </td>
                  <td className='w-25'>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                      event.preventDefault()

                      if (pageDays < pagesDays) {
                        this.setState({ pageDays: pageDays + 1 });
                        init = init + 10;
                        end = end + 10;
                      }
                      this.dayConsult(init, end, 0)}}>
                      Siguiente
                    </button>
                  </td>
                  <td className='w-25'>                  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      }

      if (this.props.device === 'desktop') {
        controlpanel =
          <table className="table-borderless w-100" >
            <tbody className='w-100'>
              <tr scope="row" className='w-100'>
                <td scope="col" className='w-60 text-left'>
                  <b className='fs-22'>{this.state.name} {this.state.appellidos}</b>
                </td>
                <td scope="col" className='w-40'>
                  <table className="table-borderless w-100">
                    <tbody className='w-100'>
                      <tr scope="row" className='w-100'>
                        <td scope="col" className='w-50 text-left'>
                          Desde
                        </td>
                        <td scope="col" className='w-50 text-left'>

                          Hasta
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td> 
              </tr>
              

              <tr scope="row" className='w-100'>
                <td scope="col" className='w-60'>
                  <table className="table-borderless w-100">
                    <tbody className='w-100'>
                      <tr className='w-100 d-flex js-it-l'>
                        <td className='text-center'>
                          <div className="positive button bg-white mg-t-6px"

                            onClick={(event) => {
                              event.preventDefault();
                              this.props.actualizarEstadoYAlmacenar();
                              this.actualizarEstadoYAlmacenarEmployees();
                              this.props.seslectDisplayMode("text");
                            }}
                          >
                            Texto
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className="positive button bg-white mg-t-6px ml-5-px" 
                            
                              onClick={(event) => {
                              event.preventDefault()
                              this.props.actualizarEstadoYAlmacenar()
                              this.actualizarEstadoYAlmacenarEmployees()
                              this.props.seslectDisplayMode('list')
                            }}>
                            Actividad
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className="positive button bg-white mg-t-6px ml-5-px"
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.actualizarEstadoYAlmacenar()
                                this.actualizarEstadoYAlmacenarEmployees()
                                this.props.seslectDisplayMode('trips')
                            }}>

                            Viajes
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td scope="col" className='w-40'>
                  <table className="table-borderless w-100">
                    <tbody className='w-100'>
                      <tr className='w-100'>
                           <td scope="col"  ClassName="wid-20">
                          <div className="input-group  wid-100 ">
                              <DatePicker
                                  className="form-control form-control-lg"
                                  selected={this.state.dateDesde}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={this.handleChangeDesde.bind(this)}
                                  required
                              />
                          </div>
                        </td>
                        <td scope="col" className="wid-10"></td>
                           <td scope="col"  ClassName="wid-20">
                          <div className="input-group  wid-100 ">
                     
                          {/* <td scope="col" className="wid-20 mg-lef-10 mg-lf-310">
                          <div className="input-group mb-4 w-100 me-mr-20"> */}
                            <DatePicker
                              className="form-control form-control-lg"
                              selected={this.state.dateHasta}
                              dateFormat="dd/MM/yyyy"
                              onChange={this.handleChangeHasta.bind(this)}
                              required
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr scope="row" className='w-100'>
                <td scope="col"  className='w-60'>
                  <table className="table-borderless w-100">
                    <tbody className='w-100'>
                      <tr className='w-100 d-flex js-it-l'>
                        <td className='text-center'>
                          <div className="positive button w-100 bg-white "
                                onClick={(event) => {
                                  event.preventDefault()
                                  this.props.actualizarEstadoYAlmacenar()
                                  this.actualizarEstadoYAlmacenarEmployees()
                                  this.props.seslectDisplayMode('periods')
                            }}>
                            Jornadas
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className="positive button w-100 bg-white "
                              onClick={(event) => {
                                  event.preventDefault()
                                  this.props.actualizarEstadoYAlmacenar()
                                  this.actualizarEstadoYAlmacenarEmployees()
                                  this.props.seslectDisplayMode('dayConsult')
                            }}>

                            Consulta por día
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td scope="col" className='w-100 d-flex justify-content-center align-content-right mb-20-px'>
                  <form className='w-100 mg-t-6px'

                    onSubmit={(event) => {
                      event.preventDefault();
                      let myEmployeeID = this.props.myEmployee.id;
                      let filters = this.state.filters;
                      let desde = this.state.dateDesde;
                      if (desde === "") {
                        desde = 0;
                      } else {
                        desde = parseInt(desde.getTime() / 1000);
                      }
                      let hasta = this.state.dateHasta;
                      if (hasta === "") {
                        hasta = 0;
                      } else {
                        hasta = parseInt(hasta.getTime() / 1000);
                      }
                      this.props.search(myEmployeeID, desde, hasta, filters, true)
                      this.props.actualizarEstadoYAlmacenar()
                      this.actualizarEstadoYAlmacenarEmployees()
                      this.componentDidMount()
                    }}>
                    <button 
                      type="submit" className="btn btn-primary btn-block btn-lg wid-100">
                        Buscar
                    </button>

                   

                  </form>
                </td>
              </tr>
            </tbody>
          </table>
     
    } else {

      //console.log("estoy que quiro enseñar")
      if (this.props.userRole === "employee") {
        <div></div>;
      } else {
        controlpanel = (
          <table className="table-borderless" style={{ width: "100%" }}>
            <tbody style={{ width: "100%" }}>
              <tr scope="row" style={{ width: "100%" }}>
                <td scope="col" style={{ width: "100%", textAlign: "center" }}>
                  <table className="table-borderless" style={{ width: "100%" }}>
                    <tbody style={{ width: "100%" }}>
                      <tr scope="row" style={{ width: "100%" }}>
                        <td scope="col" style={{ width: "50%", textAlign: "center" }}>
                          Desde
                        </td>
                        <td scope="col" style={{ width: "50%", textAlign: "center" }}>
                          Hasta
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr scope="row" style={{ width: "100%" }}>
                <td scope="col" style={{ width: "100%" }}>
                  <table className="table-borderless" style={{ width: "100%" }}>
                    <tbody style={{ width: "100%" }}>
                      <tr scope="row" style={{ width: "100%" }}>
                        <td scope="col" style={{ width: "50%" }}>
                          <div className="input-group mb-4" style={{ width: "90%", marginLeft: "5%", marginRight: "5%" }}>
                            <DatePicker
                              className="form-control form-control-lg"
                              selected={this.state.dateDesde}
                              dateFormat="dd/MM/yyyy"
                              onChange={this.handleChangeDesde.bind(this)}
                              required
                            />
                          </div>
                        </td>
                        <td scope="col" style={{ width: "50%" }}>
                          <div className="input-group mb-4" style={{ width: "90%", marginLeft: "5%", marginRight: "5%" }}>
                            <DatePicker
                              className="form-control form-control-lg"
                              selected={this.state.dateHasta}
                              dateFormat="dd/MM/yyyy"
                              onChange={this.handleChangeHasta.bind(this)}
                              required
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr scope="row" style={{ width: "100%" }}>
                <td scope="col" style={{ width: "100%" }}>
                  <form
                    style={{ width: "50%", marginLeft: "25%" }}
                    onSubmit={(event) => {
                      event.preventDefault();
                      let myEmployeeID = this.props.myEmployee.id;
                      let filters = this.state.filters;
                      let desde = this.state.dateDesde;
                      if (desde === "") {
                        desde = 0;
                      } else {
                        desde = parseInt(desde.getTime() / 1000);
                      }
                      let hasta = this.state.dateHasta;
                      if (hasta === "") {
                        hasta = 0;
                      } else {
                        hasta = parseInt(hasta.getTime() / 1000);
                      }
                      this.props.search(myEmployeeID, desde, hasta, filters, true);
                      this.props.actualizarEstadoYAlmacenar();
                      this.actualizarEstadoYAlmacenarEmployees();
                      this.componentDidMount();
                    }}
                  >
                    <button type="submit" style={{ width: "100%" }} className="btn btn-primary btn-block btn-lg">

                      Consultar
                    </button>
                  </form>
                  <br />
                </td>
              </tr>
              <tr style={{ fontSize: 6 }}>
                <td scope="col" style={{ width: "100%" }}>
                  <table className="table-borderless" style={{ width: "100%" }}>
                    <tbody style={{ width: "100%" }}>
                      <tr scope="row" style={{ width: "100%" }}>
                        <td scope="col" style={{ width: "48%" }}>
                          <div
                            className="positive button"
                            style={{ width: "100%", backgroundColor: textButtonColor }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.actualizarEstadoYAlmacenar();
                              this.actualizarEstadoYAlmacenarEmployees();
                              this.props.seslectDisplayMode("text");
                            }}
                          >
                            Texto
                          </div>
                        </td>
                        <td scope="col" style={{ width: "4%" }}></td>
                        <td scope="col" style={{ width: "48%" }}>
                          <div
                            className="positive button"
                            style={{ width: "100%", backgroundColor: listButtonColor }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.actualizarEstadoYAlmacenar();
                              this.actualizarEstadoYAlmacenarEmployees();
                              this.props.seslectDisplayMode("list");
                            }}
                          >
                            Actividad
                          </div>
                        </td>
                      </tr>
                      <br />
                      <tr scope="row" style={{ width: "100%" }}>
                        <td style={{ width: "48%" }}>{tripButton}</td>
                        <td scope="col" style={{ width: "4%" }}></td>
                        <td style={{ width: "48%" }}>
                          <div
                            className="positive button"
                            style={{ width: "100%", backgroundColor: dayConsultButtonColor }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.actualizarEstadoYAlmacenar();
                              this.actualizarEstadoYAlmacenarEmployees();
                              this.props.seslectDisplayMode("dayConsult");
                            }}
                          >
                            Consulta por día
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        );
     
    }
  }

      if (this.state.noData && displayMode !== "trips") {
        // if (this.state.noData){

        //console.log("aqui no debería estar")
        display = <div>No hay nada en estas fechas, cambia la selección</div>;
      } else {
        
        if (displayMode === "text" || displayMode === "graphs") {
          let country = "";

          if (this.props.myEmployee) {
            if (this.props.myEmployee.country) {
              country = this.props.myEmployee.country;
            }

          }
        }

        //console.log("llegando al asunto de render", displayMode)
        if (displayMode === "text") {
          // display = 
          //   <div>
          //     <table className="table-borderless" style={{ width: "100%" }}>
          //       <tbody>
          //         <tr scope="row" style={{ width: "100%" }}>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             Horas de conducción
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             Descansos
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             Disponible
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             Otros trabajos
          //           </td>
          //         </tr>
          //         <tr scope="row" style={{ color: "green" }}>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             <b style={{ fontSize: 22 }}>{this.state.totalDrivingHours}</b> h:m
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             <b style={{ fontSize: 22 }}>{this.state.totalRestingHours}</b> h:m
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             <b style={{ fontSize: 22 }}>{this.state.totalAvailableHours}</b> h:m
          //           </td>
          //           <td scope="col" style={{ width: "25%", textAlign: "left" }}>
          //             <b style={{ fontSize: 22 }}>{this.state.totalOtherWorkingHours}</b> h:m
          //           </td>
          //           <br></br>
          //           <br></br>
          //         </tr>
          //         <tr scope="row">
          //           <td scope="col" style={{ width: "25%" }}>
          //             Total dietas
          //           </td>
          //           <td scope="col" style={{ width: "25%" }}>
          //             Horas en Nocturnidad
          //           </td>
          //           <td scope="col" style={{ width: "25%" }}>
          //             Horas extras trimestre
          //           </td>
          //         </tr>
          //         <tr scope="row" style={{ color: "green" }}>
          //           <td>
          //             <b style={{ fontSize: 22 }}>
          //               {Math.round(
          //                 (this.state.total_nat_feed_diet +
          //                   this.state.total_nat_sleep_diet +
          //                   this.state.total_int_feed_diet +
          //                   this.state.total_int_sleep_diet +
          //                   this.state.totalPlusKmNat +
          //                   this.state.totalPlusKmInt +
          //                   this.state.totalFest +
          //                   this.state.totalWeekEnd) *
          //                   100
          //               ) / 100}
          //             </b>{" "}
          //             €
          //           </td>
          //           <td>
          //             <b style={{ fontSize: 22 }}>{this.state.nocturnas}</b> h:m
          //           </td>
          //           <td>
          //             <b style={{ fontSize: 22 }}>{this.state.extraHoursToPay}</b> h:m
          //           </td>
          //         </tr>
          //         <br></br>
          //         <tr>
          //           <td style={{ width: "25%" }}>Completa Nacional</td>
          //           <td style={{ width: "25%" }}>Parcial Nacional</td>
          //           <td style={{ width: "25%" }}>Completa Internacional</td>
          //           <td style={{ width: "25%" }}>Parcial Internacional</td>
          //         </tr>
          //         <tr scope="row" style={{ color: "green" }}>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_nat_sleep_diet"]}</b>
          //             <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["total_nat_sleep_diet"]} €</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Días con otras:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_nat_feed_diet"]}</b>
          //             <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["total_nat_feed_diet"]} €</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_int_sleep_diet"]}</b> <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["total_int_sleep_diet"]} €</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Días con otras:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_int_feed_diet"]}</b>
          //             <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["total_int_feed_diet"]} €</b>
          //           </td>
          //         </tr>
          //         <br></br>
          //         <tr>
          //           <td style={{ width: "25%" }}>Plus Km Nacional</td>
          //           <td style={{ width: "25%" }}>Plus Km Inter</td>
          //           <td style={{ width: "25%" }}>Plus festivos</td>
          //           <td style={{ width: "25%" }}>Plus Fin Semana</td>
          //         </tr>
          //         <tr scope="row" style={{ color: "green" }}>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_KmNat"]}</b> <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["totalPlusKmNat"]} €</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_KmInt"]}</b> <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["totalPlusKmInt"]} €</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}>{this.state.diets["num_Fest"]}</b> <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}>{this.state.diets["totalFest"]}</b>
          //           </td>
          //           <td style={{ width: "25%" }}>
          //             Nº:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["num_WeekEnd"]}</b>
          //             <br></br>
          //             Total:
          //             <b style={{ fontSize: 16 }}> {this.state.diets["totalWeekEnd"]} €</b>
          //           </td>
          //         </tr>
          //         <br />
          //       </tbody>
          //     </table>
          //   </div>

          if (this.props.device === 'desktop'){
            display =
              <div>
                <table className="table-borderless w-100">
                  <tbody>
                    <tr scope="row" className='w-100'>
                      <td scope="col" className='w-25 text-left'>Horas de conducción</td>
                      <td scope="col" className='w-25 text-left'>Descansos</td>
                      <td scope="col" className='w-25 text-left'>Disponible</td>
                      <td scope="col" className='w-25 text-left'>Otros trabajos</td>
                    </tr>
                    <tr scope="row" className='text-success'>
                      <td scope="col" className='w-25 text-left'><b className='fs-22'>{this.state.totalDrivingHours}</b> h:m</td>
                      <td scope="col" className='w-25 text-left'><b className='fs-22'>{this.state.totalRestingHours}</b> h:m</td>
                      <td scope="col" className='w-25 text-left'><b className='fs-22'>{this.state.totalAvailableHours}</b> h:m</td>
                      <td scope="col" className='w-25 text-left'><b className='fs-22'>{this.state.totalOtherWorkingHours}</b> h:m</td>
                      <br></br><br></br>
                    </tr>
                    <tr scope="row">
                      <td scope="col" className='w-25'>Total dietas</td>
                      <td scope="col" className='w-25'>Horas en Nocturnidad</td>
                      <td scope="col" className='w-25'>Horas extras trimestre</td>
                    </tr>
                    <tr scope="row" className='text-success'>
                      <td>
                        <b className='fs-22'>{Math.round((this.state.total_nat_feed_diet + this.state.total_nat_sleep_diet + this.state.total_int_feed_diet + this.state.total_int_sleep_diet + this.state.totalPlusKmNat + this.state.totalPlusKmInt + this.state.totalFest + this.state.totalWeekEnd) * 100) / 100}</b> €
                      </td>
                      <td>
                        <b className='fs-22'>{this.state.nocturnas}</b> h:m
                      </td>
                      <td>
                        <b className='fs-22'>{this.state.extraHoursToPay}</b> h:m
                      </td>
                    </tr>
                    <br></br>
                    <tr>
                      <td className='w-25'>
                        Completa Nacional
                      </td>
                      <td className='w-25'>
                        Parcial Nacional
                      </td>
                      <td className='w-25'>
                        Completa Internacional
                      </td>
                      <td className='w-25'>
                        Parcial Internacional
                      </td>
                    </tr>
                    <tr scope="row" className='text-success'>
                      <td className='w-25'>
                        Nº:<b className='fs-16'> {this.state.diets["num_nat_sleep_diet"]}</b><br></br>
                        Total:<b className='fs-16'> {this.state.diets["total_nat_sleep_diet"]}  €</b>
                      </td>
                      <td className='w-25'>
                        Días con otras:<b className='fs-16'> {this.state.diets["num_nat_feed_diet"]}</b><br></br>
                        Total:<b className='fs-16'> {this.state.diets["total_nat_feed_diet"]} €</b>
                      </td>
                      <td className='w-25'>
                        Nº:<b className='fs-16'> {this.state.diets["num_int_sleep_diet"]}</b> <br></br>
                        Total:<b className='fs-16'> {this.state.diets["total_int_sleep_diet"]}  €</b>
                      </td>
                      <td className='w-25'>
                        Días con otras:<b className='fs-16'> {this.state.diets["num_int_feed_diet"]}</b><br></br>
                        Total:<b className='fs-16'> {this.state.diets["total_int_feed_diet"]}  €</b>
                      </td>
                    </tr>
                    <br></br>
                    <tr>
                      <td className='w-25'>
                        Plus Km Nacional
                      </td>
                      <td className='w-25'>
                        Plus Km Inter
                      </td>
                      <td className='w-25'>
                        Plus festivos
                      </td>
                      <td className='w-25'>
                        Plus Fin Semana
                      </td>
                    </tr>
                    <tr scope="row" className='text-success'>
                      <td className='w-25'>
                        Nº:<b className='fs-16'> {this.state.diets["num_KmNat"]}</b> <br></br>
                        Total:<b className='fs-16'> {this.state.diets["totalPlusKmNat"]}  €</b>
                      </td>
                      <td className='w-25'>
                        Nº:<b className='fs-16'> {this.state.diets["num_KmInt"]}</b> <br></br>
                        Total:<b className='fs-16'> {this.state.diets["totalPlusKmInt"]}  €</b>
                      </td>
                      <td className='w-25'>
                        Nº:<b className='fs-16'>{this.state.diets["num_Fest"]}</b> <br></br>
                        Total:<b className='fs-16'>{this.state.diets["totalFest"]}</b>
                      </td>
                      <td className='w-25'>
                        Nº:<b className='fs-16'> {this.state.diets["num_WeekEnd"]}</b><br></br>
                        Total:<b className='fs-16'> {this.state.diets["totalWeekEnd"]}  €</b>
                      </td>
                    </tr>
                    <br/>
                  </tbody>
                </table>
                Primer dato Lugar: {this.state.lastPlaceDate}    Último dato: {this.state.lastDate}
                <br/><br/>
              </div>
          }else{
            display =
              <div>
                <table className="table-borderless w-100">
                  <tbody className='w-100'>
                  <br/>
                    <tr scope="row" className='w-100'>
                      <td scope="col" className='w-33 text-center'>Descansos</td>
                      <td scope="col" className='w-33 text-center'>Disponible</td>
                      <td scope="col" className='w-33 text-center'>Conducción</td>
                    </tr>
                    <tr scope="row" className='text-success w-100'>
                      <td scope="col" className='w-33 text-center'>
                        <b className='fs-16'>{this.state.totalRestingHours}</b> h:m
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        <b className='fs-16'>{this.state.totalAvailableHours}</b> h:m
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        <b className='fs-16'>{this.state.totalDrivingHours}</b> h:m
                      </td>
                    </tr>
                    <br/>
                    <tr scope="row" className='w-100'>
                      <td scope="col" className='w-33 text-center'>Otros</td>
                      <td scope="col" className='w-33 text-center'>Total dietas</td>
                      <td scope="col" className='w-33 text-center'>Nocturnas</td>
                    </tr>
                    <tr scope="row" className='text-success w-100'>
                      <td scope="col" className='w-25 text-center'>
                        <b className='fs-16'>{this.state.totalOtherWorkingHours}</b> h:m
                      </td>
                      <td className='w-25 text-center'>
                        <b className='fs-16'>{Math.round((this.state.total_nat_feed_diet + this.state.total_nat_sleep_diet + this.state.total_int_feed_diet + this.state.total_int_sleep_diet + this.state.totalPlusKmNat + this.state.totalPlusKmInt + this.state.totalFest + this.state.totalWeekEnd) * 100) / 100}</b> €
                      </td>
                      <td className='w-25 text-center'>
                        <b className='fs-16'>{this.state.nocturnas}</b> h:m
                      </td>
                    </tr>
                    <br/>
                    <tr scope="row" className='w-100'>
                      <td scope="col" className='w-33 text-center'>
                        Com. Nac
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Par. Nac
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Plus Km Nac
                      </td>
                    </tr>
                    <tr scope="row" className='text-success w-100'>
                      <td scope="col" className='w-33 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_nat_sleep_diet"]}</b><br/>
                        Total:<b className='fs-16'> {this.state.diets["total_nat_sleep_diet"]} €</b>
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Días:<b className='fs-16'> {this.state.diets["num_nat_feed_diet"]}</b><br/>
                        Total:<b className='fs-16'> {this.state.diets["total_nat_feed_diet"]} €</b>
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_KmNat"]}</b> <br/>
                        Total:<b className='fs-16'> {this.state.diets["totalPlusKmNat"]} €</b>
                      </td>
                    </tr>
                    <br/>
                    <tr scope="row" className='w-100'>
                      <td scope="col" className='w-33 text-center'>
                        Com. Inter
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Par. Inter
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Plus Km Inter
                      </td>
                    </tr>
                    <tr scope="row" className='text-success w-100'>
                      <td scope="col" className='w-33 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_int_sleep_diet"]}</b> <br/>
                        Total:<b className='fs-16'> {this.state.diets["total_int_sleep_diet"]} €</b>
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Días:<b className='fs-16'> {this.state.diets["num_int_feed_diet"]}</b><br/>
                        Total:<b className='fs-16'> {this.state.diets["total_int_feed_diet"]} €</b>
                      </td>
                      <td scope="col" className='w-33 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_KmInt"]}</b> <br/>
                        Total:<b className='fs-16'> {this.state.diets["totalPlusKmInt"]} €</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-borderless w-100">
                  <tbody className='w-100'>
                  <br/>
                    <tr scope="row" className='w-100'>
                      <td className='w-48 text-center'>
                        Plus festivos
                      </td>
                      <td className='w-4'></td>
                      <td className='w-48 text-center'>
                        Plus Fin Semana
                      </td>
                    </tr>
                    <tr scope="row" className='text-success w-100'>
                      <td className='w-48 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_Fest"]}</b> <br></br>
                        Total:<b className='fs-16'> {this.state.diets["totalFest"]} €</b>
                      </td>
                      <td className='w-4'></td>
                      <td className='w-48 text-center'>
                        Nº:<b className='fs-16'> {this.state.diets["num_WeekEnd"]}</b><br></br>
                        Total:<b className='fs-16'> {this.state.diets["totalWeekEnd"]} €</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                Última Actividad: {this.state.lastDate}
                <br></br>
              </div>
              
          }
        }else if (displayMode === 'list'){
          var displayList
          let totalWorkingHours = this.props.myEmployee.totalWorkingHours            
          let activitiesList = this.state.activities

          if (activitiesList.length === 0 && totalWorkingHours > 0 && this.state.numReloads === 10 && !this.state.filterAlerts){            
              setTimeout(() => window.location.reload(), 0)
          }        
            
          if (this.props.device === 'desktop'){
            var listControlPanel = 
              <div className="selectOptions w-100'">
                <label className='fs-20'>
                  <input type="checkbox" ref={this.selectAllRef} className="boxFuncion" onChange = {() => this.allCheckboxes()} disabled="true" defaultChecked/>
                    Seleccionar Todo
                </label>
                <label className='fs-20'>
                  <input type="checkbox"ref={this.checkbox1Ref} className="boxFuncion boxPosicion" onChange={() => {
                    this.handleChangeModes("availableCheck");
                    this.props.actualizarEstadoYAlmacenar();
                    this.actualizarEstadoYAlmacenarEmployees()
                  }} defaultChecked/>
                    Disponible
                </label>
                <label className='fs-20'>
                  <input type="checkbox" ref={this.checkbox2Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("drivingCheck")} defaultChecked />
                  Conducción
                </label>
                <label className='fs-20'>
                  <input type="checkbox" ref={this.checkbox3Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("restingCheck")} defaultChecked />
                  Descanso
                </label>
                <label className='fs-20'>
                  <input type="checkbox" ref={this.checkbox4Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModes("workingCheck")} defaultChecked />
                  Trabajando
                </label>
              </div>

            var alertControlPanel = 
              <div className="selectOptions w-100">
                <label className='fs-20'>
                  <input type="checkbox" ref={this.selectAlertRef} defaultChecked={this.props.filterAlerts} className="boxFuncion" onChange={() => {      
                    this.handleChangeModes("alertCheck");}}/>
                    Filtrar por alertas
                </label>
                <label className='fs-20 ml-10-px'>
                  <input type="checkbox" ref={this.checkbox5Ref} defaultChecked={this.props.filterDiets} className="boxFuncion" onChange={this.handleCheckboxChange}/>
                    Dietas

                </label>
              </div>
            
  
            displayList = 
              <div id="content" className="mt-3">
                <div className="card mb-4">
                  {paginator}
                  <div className="card-body">

                    <table className="table fs-12">

                      <thead>
                        <tr>
                          <th scope="col">Actividad</th>
                          <th scope="col">Inicio</th>
                          <th scope="col">Duración</th>
                          <th scope="col">Nocturnidad</th>
                          <th scope="col">Dieta</th>
                          <th scope="col">Km</th>
                          <th scope="col">Coconducción</th>
                          <th scope="col">Lugar</th>
                          <th scope="col">Detalles</th>
                          <th scope="col">Editar</th>
                        </tr>
                      </thead>
                      <tbody id="productList">
                        {this.state.activities.map((empl, key) => {
                          var statusText = ""
                          var statusTextCrew = ""
                          var statusStyle = "green"
                          var latitude_longitude = ""
                          let message_button = <div></div>
                          var details = this.state.activities[key]["datails"]
                          var element
                          var elementLenght
                          const charachters = 20
                          var output = ""

                          if (this.props.userRole!=="employee"){
                            message_button = 
                              <button className="btn btn-outline-secondary fs-12 h-34" 
                                      onClick={(event) => {                                              
                                        this.props.showSendSmsActivity(this.state.activities[key])}}>
                                Clarificar
                              </button>
                          }

                          if (this.state.activities[key]["latitude"] && this.state.activities[key]["longitude"]){
                            let lat = String(this.state.activities[key]["latitude"] / 1000)
                            let lon = String(this.state.activities[key]["longitude"] / 1000)
                            let url = "https://gps-coordinates.org/my-location.php?lat=" + lat + "&lng=" + lon
                            latitude_longitude = <a href={url} target="_blank">Ver ubicación</a>
                          }

                          if ((this.state.activities[key]["type"] === "Working" || this.state.activities[key]["type"] === "Available") && this.state.activities[key]["duration"] > 60 && details === null){
                            statusText = "Editar"
                            statusStyle = "red"
                          }else if (this.state.activities[key]["provisional"] === true){
                            statusText = "Editar"
                            statusStyle = "red"

                          }
  
                          if (details !== null) {
                            if (details[0] !== " ") {
                              statusText = "Editado";
                              statusStyle = "green";
                            }
                          }
  
                          if (details) {
                            for (let i = 0; i < details.length; i++) {
                              element = details[i];
                              let numberOfLines = element.length / charachters;
                              for (let c = 0; c < numberOfLines; c++) {
                                let row = element.substring(c * charachters, (c + 1) * charachters);
                                output = output + <br></br> + row;
                              }
                              output = output + <br></br>;
                            }
                          }
                          if (!this.state.activities[key]["crew"]) {
                            statusTextCrew = "No";
                          } else {
                            statusTextCrew = "Si";
                          }
  
                          if (this.props.userRole !== "employee") {
                            const clarificarButtonIsDisabled = statusText === "Editado";
                            message_button = (
                              <button
                                style={{ fontSize: "12px", height: "34px" }}
                                className="btn btn-outline-secondary"
                                disabled={clarificarButtonIsDisabled}
                                onClick={(event) => {
                                  this.props.showSendSmsActivity(this.state.activities[key]);
                                }}
                              >
                                Clarificar
                              </button>
                            );
                          }
  
                          if (this.state.activities[key]["latitude"] && this.state.activities[key]["longitude"]) {
                            let lat = String(this.state.activities[key]["latitude"] / 1000);
                            let lon = String(this.state.activities[key]["longitude"] / 1000);
                            let url = "https://gps-coordinates.org/my-location.php?lat=" + lat + "&lng=" + lon;
                            latitude_longitude = (
                              <a href={url} target="_blank">
                                Ver ubicación
                              </a>
                            );
                          }
  
                          return (
                            <tr key={key} className='w-100'>
                              <td className='w-15'>{this.state.activities[key]["typeFormat"]}</td>
                              <td className='w-15'>{this.state.activities[key]["startFormat"]}</td>
                              <td style={{ width: "15%", color: this.state.activities[key]["colorConduccion"] }}><b>{this.state.activities[key]["durationFormat"]}</b></td>
                              <td className='w-5 text-primary'>{this.state.activities[key]["nocturnasFormat"]}</td>
                              <td className='w-15 text-success'>{this.state.activities[key]["dietFormat"]}</td>
                              <td className='w-5 text-success'>{this.state.activities[key]["kilometers"]}</td>
                              <td className='w-5 text-success'>{this.state.activities[key]["crew"]}
                                <tr>{statusTextCrew ? statusTextCrew : ""}</tr>
                              </td>
                              <td className='w-10'>{this.state.activities[key]["place"] + this.state.activities[key]["regionE"]}</td>
                              <td className='w-15 text-success fs-12 h-53-px w-s'>

                                <tr>{message_button ? message_button : ""}</tr>
                                <tr>{statusText ? statusText + "\n" : ""}</tr>
                                <tr>{latitude_longitude ? latitude_longitude : ""}</tr>
                              </td>

                              <td className='w-10 h-53-px text-right'>
                                {
                                  {}
                                    ? 
                                      <button className="btn btn-outline-secondary fs-12 h-34" 
                                              onClick={(event) => {
                                                this.props.showModalActivity(this.state.activities[key])}}>
                                        Editar
                                      </button>
                                    : null
                                }

                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          }else{
            if(this.props.userRole === "employee"){
              var  listControlPanel = 
                <div className='w-100 text-center'>
                  ¿Tienes dudas?
                <br/>
                  <a className="btn btn-outline-secondary w-80 fs-14" href="https://www.youtube.com/watch?v=hpiWEw_rYyw&feature=youtu.be" target="_blank">
                    Mira nuestro tutorial
                  </a>
                </div>
            }else{
              listControlPanel =
                <div className="selectOptionsMobile w-100">
                  <br/>
                  <div className="rowSelectOptions w-100">
                    <div className="columnSelectOptions">
                      <label className='fs-20'>
                        <input type="checkbox" ref={this.checkbox1Ref} className="boxFuncion boxPosicion"
                              onChange={() => {
                                this.handleChangeModes("availableCheck");
                                this.props.actualizarEstadoYAlmacenar();
                                this.actualizarEstadoYAlmacenarEmployees()}} defaultChecked/>
                          Disponible
                      </label>
                      <label className='fs-20'>
                        <input type="checkbox" ref={this.checkbox2Ref} className="boxFuncion" onChange={() => this.handleChangeModesMobile("drivingCheck")} defaultChecked />
                          Conducción
                      </label>
                    </div>
                    <div className="columnSelectOptions">
                      <label className='fs-20'>
                        <input type="checkbox" ref={this.checkbox3Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModesMobile("restingCheck")} defaultChecked />
                          Descanso
                      </label>
                      <label className='fs-20'>
                        <input type="checkbox" ref={this.checkbox4Ref} className="boxFuncion boxPosicion" onChange={() => this.handleChangeModesMobile("workingCheck")} defaultChecked />
                          Trabajando

                      </label>
                    </div>
                  </div>
                </div>
              var alertControlPanel = 
                <div className="selectOptions w-100">
                  <label className='fs-20'>
                    <input type="checkbox" ref={this.selectAlertRef} defaultChecked={this.props.filterAlerts} className="boxFuncion" onChange={() => {this.handleChangeModes("alertCheck");}}/>
                      Filtrar por alertas
                  </label>
                  <label className='fs-20'>
                    <input type="checkbox" ref={this.checkbox5Ref} defaultChecked={this.props.filterDiets} className="boxFuncion boxPosicion" onChange={this.handleCheckboxChange}/>
                      Dietas

                  </label>
                </div>
             
            }
              displayList =
                <div id="content" className="mt-3">
                  <div className="card mb-4" >
                    {paginator}
                    <div>
                    <br/>
                      <table className="table-borderless fs-12 w-100">
                        <tbody className='w-100'>
                        <table  className="fs-12 w-100">
                          <tr className='w-100'>
                            <td className='w-30 text-center'><b>Tipo</b></td>
                            <td className='w-20 text-center'><b>País</b></td>
                            <td className='w-30 text-center'><b>Dieta</b></td>
                            <td className='w-20 text-center'><b>Tiempo</b></td>

                          </tr>
                        </table>
                        {this.state.activities.map((empl, key) => {
                          var statusText = "";
                          var statusStyle = "green";
                          var details = this.state.activities[key]["datails"];
                          var element;
                          var elementLenght;
                          const charachters = 20;
                          var output = "";
  
                          if (
                            (this.state.activities[key]["type"] === "Working" || this.state.activities[key]["type"] === "Available") &&
                            this.state.activities[key]["duration"] > 60 &&
                            details === null
                          ) {
                            statusText = " ";
                            statusStyle = "red";
                          } else if (this.state.activities[key]["provisional"] === true) {
                            statusText = " ";
                            statusStyle = "red";
                          }
                          if (details !== null) {
                            if (details[0] !== " ") {
                              statusText = "Editado";
                              statusStyle = "green";
                            }
                          }
  
                          if (details) {
                            for (let i = 0; i < details.length; i++) {
                              element = details[i];
                              let numberOfLines = element.length / charachters;
                              for (let c = 0; c < numberOfLines; c++) {
                                let row = element.substring(c * charachters, (c + 1) * charachters);
                                output = output + <br></br> + row;
                              }
                              output = output + <br></br>;
                            }
                          }

                          return(
                            <table  className="table-borderless w-100 fs-12">
                              <tbody className='w-100'>
                                <tr key={key} className='w-100'>
                                  <td className='w-30 text-center'>{this.state.activities[key]["typeFormat"]}<br></br></td>
                                  <td className='w-20 text-center'>{this.state.activities[key]["place"]}</td>
                                  <td className='w-30 text-center'>{this.state.activities[key]["dietFormat"]}</td>
                                  <td className='w-20 text-center'>{this.state.activities[key]["durationFormat"]}</td>
                                </tr>
                                <tr key={key} className='w-100'>
                                  <td className='w-30 text-center'>{this.state.activities[key]["startFormat"]}</td>
                                  <td className='w-20 text-success text-center'>{this.state.activities[key]["displaced"]}</td>                                
                                  <td className='w-30 text-center'>
                                    {
                                      {}
                                      ? 
                                        <button className="btn btn-outline-secondary w-80 fs-10 h-80" onClick={(event) => {  
                                          this.props.showModalActivity(this.state.activities[key])
                                        }}>
                                          Editar
                                        </button>
                                      :null
                                    }
                                    <br/><br/>
                                  </td>
                                  <td className='w-20 text-success text-center'>{statusText}</td>

                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           
          }

          //console.log("llegando al display aislado")

          display =
            <div className='w-100'>
              <table className='w-100'>
                <tbody className='w-100'>
                  <tr className='w-100'>
                    {listControlPanel}
                  </tr>
                  <tr>
                    {alertControlPanel}
                  </tr>
                  <tr>
                    {displayList}
                  </tr>
                </tbody>
              </table>
            </div>
        }else if (displayMode === 'trips'){
          var listControlPanel = <div className='w-100'></div>
          let displayList
          if (this.props.device === 'desktop') {
            displayList =

              <div id="content" className="mt-3">
                <div className="card mb-4">
                  {paginatorTrips}
                  <div className="card-body">

                    <table className="table fs-12">

                      <thead>
                        <tr>
                          <th scope="col">Origen</th>
                          <th scope="col">Destino</th>
                          <th scope="col">Duración</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Modo</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody id="productList">
                        {this.state.tripsToshow.map((trip, key) => {
                          var statusText = "";
                          var statusStyle = "green";

                          return (
                            <tr key={key} className='w-100'>
                              <td className='w-17'>{this.state.tripsToshow[key]["origin"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["destination"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["durationFormat"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["dateToShow"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["modeToShow"]}</td>
                              <td className='w-5 text-right h-50'>  {
                                      {}
                                        ? <button
                                          className="btn btn-outline-secondary fs-10 h-100"
                                          onClick={(event) => {
                                            this.props.showEditTripFields(this.state.tripsToshow[key]["id"])
                                          }}
                                        >
                                          Editar
                                        </button>
                                        : null
                                    }</td>
                              <td className='w-5 text-right h-50'>  {
                                      {}
                                        ? <button
                                          className="btn btn-outline-secondary fs-10 h-100"
                                          onClick={(event) => {
                                            this.props.hideTrip(this.state.tripsToshow[key]["id"]);
                                          }}
                                        >
                                          Borrar
                                        </button>
                                        : null
                                    }</td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          } else {
            displayList = (
              <div id="content" className="mt-3">
                <div className="card mb-4">
                  {paginatorTrips}
                  <div className="card-body">
                    <table className="table fs-12">

                      <thead>
                        <tr>
                          <th scope="col">Origen</th>
                          <th scope="col">Destino</th>
                          <th scope="col">Tiempo</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Modo</th>
                        </tr>
                      </thead>
                      <tbody id="productList">
                        {this.state.tripsToshow.map((trip, key) => {
                          var statusText = "";
                          var statusStyle = "green";

                          return (

                             <tr key={key} className='w-100'>
                              <td className='w-17'>{this.state.tripsToshow[key]["city1"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["city2"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["durationFormat"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["dateToShow"]}</td>
                              <td className='w-17'>{this.state.tripsToshow[key]["modeToShow"]}</td>
                              <td style={{ width: "5%", textAlign: "right", height: "50px" }}>
                                {" "}
                                {{} ? (
                                  <button
                                    className="btn btn-outline-secondary"
                                    style={{ fontSize: "10px", height: "100%" }}
                                    onClick={(event) => {
                                      this.props.showEditTripFields(this.state.tripsToshow[key]["id"]);
                                    }}
                                  >
                                    Editar
                                  </button>
                                ) : null}
                              </td>
                              <td style={{ width: "5%", textAlign: "right", height: "50px" }}>
                                {" "}
                                {{} ? (
                                  <button
                                    className="btn btn-outline-secondary"
                                    style={{ fontSize: "10px", height: "100%" }}
                                    onClick={(event) => {
                                      this.props.hideTrip(this.state.tripsToshow[key]["id"]);
                                    }}
                                  >
                                    Borrar
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          }

          display =
            <div className='w-100'>
              <table className='w-100'>
                <tbody className='w-100'>
                  <tr className='w-100'>
                    {listControlPanel}
                  </tr>
                  <tr>
                    {displayList}
                  </tr>
                </tbody>
              </table>
            </div>        
        
        }else if (displayMode === 'periods'){
            var paginatorJornadas = <div></div>
            let listControlPanelJornadas =
              <div>
                <Dropdown
                  value={this.state.selectedPeriod}
                  className="form-control-lg text-muted"
                  onChange={(period) => this.handleChangePeriods(period)}
                  options={[
                    { value: "weekReports", label: "Semanas" },
                    { value: "monthReports", label: "Meses" },
                    { value: "cuatrimestreReports", label: "Cuatrimestres" },
                    { value: "yearReports", label: "Años" },
                  ]}
                />
              </div>

            let displayListJornadas =
              <div id="content" className="mt-3">
                <div className="card mb-4" >
                  {paginatorJornadas}
                  <div className="card-body">
                    <table className="table fs-12">
                      <thead>
                        <tr>
                          <th scope="col" className='text-center'>Inicio</th>
                          <th scope="col" className='text-center'>Trabajado Promedio Semanal</th>
                          <th scope="col" className='text-center'>Trabajado Total</th>
                          <th scope="col" className='text-center'>Alertas trabajo</th>
                          <th scope="col" className='text-center'>Disponible Total</th>
                          <th scope="col" className='text-center'>Alertas Disponible</th>
                          <th scope="col" className='text-center'>Horas extra</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody id="productList">
                        {this.state.periodsToShow.map((period, key) => {
                          var statusText = ""
                          var statusStyle = "green"

                          return (
                            <tr key={key} className='w-100'>
                              <td className='text-center'>{this.state.periodsToShow[key]["dateFormatShow"]}</td>
                              <td className='text-center'>{this.state.periodsToShow[key]["avWorkedHoursShow"]}</td>
                              <td className='text-center'>{this.state.periodsToShow[key]["hoursWorkedShow"]}</td>
                              <td className='text-center'><b style={{ color: this.state.periodsToShow[key]["workAlertsColor"] }}>{this.state.periodsToShow[key]["workAlertsShow"]}</b></td>
                              <td className='text-center'>{this.state.periodsToShow[key]["hoursAvailableShow"]}</td>
                              <td className='text-center'><b style={{ color: this.state.periodsToShow[key]["availableAlertsColor"] }}>{this.state.periodsToShow[key]["availableAlertsShow"]}</b></td>
                              <td style={{ textAlign: "center", color: this.state.periodsToShow[key]["extraHours"] < 0 ? "red" : "green"}}>
                                {this.state.periodsToShow[key]["extraHoursShow"]}
                              </td>
                              <td className='text-center'></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
           
          ;

          let displayListMeses = (
            <div id="content" className="mt-3">

              <div className="card mb-4 m-a w-90">
                {paginatorJornadas}
                <div className="card-body">
                <table className="table fs-12 m-a w-100">
                    <thead>
                      <tr>
                        <th scope="col"className='text-center'>Inicio</th>
                        <th scope="col"className='text-center'>Trabajado Promedio Semanal</th>
                        <th scope="col"className='text-center'>Trabajado Total</th>
                        <th scope="col"className='text-center'>Dias trabajados</th>
                        <th scope="col"className='text-center'>Horas extra</th>

                      </tr>
                    </thead>

                    <tbody id="productList">
                      {this.state.periodsToShow.map((period, key) => {
                        if (this.state.periodsToShow[key]["extraHoursShow"] < 0) {
                          st;
                        }
                        return (

                          <tr key={key} className='w-100'>
                            <td className='text-center'>{this.state.periodsToShow[key]["dateFormatShow"]}</td>
                            <td className='text-center'>{this.state.periodsToShow[key]["avWorkedHoursShow"]}</td>
                            <td className='text-center'>{this.state.periodsToShow[key]["hoursWorkedShow"]}</td>
                            <td className='text-center'>{this.state.periodsToShow[key]["daysWorked"]}</td>
                            <td style={{ textAlign: "center", color: this.state.periodsToShow[key]["extraHours"] < 0 ? "red" : "green"}}>

                              {this.state.periodsToShow[key]["extraHoursShow"]}

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );

          if (this.state.selectedPeriod.value === "monthReports") {
    
            display =
              <div className='w-100'>
              <table className='w-100'>
                <tbody className='w-100'>
                  <tr className='w-100'>
                    {listControlPanelJornadas}
                  </tr>
                  
                  <tr>
                    
                    {displayListMeses}
                  </tr>
                </tbody>
              </table>
            </div>
            }
            else{ 

            display=
              <div className='w-100'>
                <table className='w-100'>
                  <tbody className='w-100'>
                    <tr className='w-100'>
                      {listControlPanelJornadas}
                    </tr>
                    
                    <tr>
                      {displayListJornadas}
                    </tr>
                  </tbody>
                </table>
              </div>}
          }else if (displayMode === 'dayConsult') {
            let listControlPanelDayConsult = <div className='w-100'></div>
            let displayListDayConsult
            
            if (this.props.device === 'desktop'){
              displayListDayConsult =
                <div id="content" className="mt-3">
                  <div className="card mb-4">
                    {paginatorDayConsult}
                    <div className="card-body">
                      <table className="table fs-12">
                        <thead>
                          <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Tipo de Dietas</th>
                            <th scope="col">Cantidad de Dieta</th>
                            <th scope="col">Total disponible</th>
                            <th scope="col">Total Conducido</th>
                            <th scope="col">Total Otros Trabajos</th>
                            <th scope="col">Total Descansos</th>
                            <th scope="col">Kilometros</th>
                            <th scope="col">Observaciones</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody id="productList">
                          {this.state.dayConsultToShow.map((period, key) => {
                            var statusText = ""
                            var statusStyle = "green"

                            return (
                              <tr key={key} className='w-100'>
                                <td className='w-100'>{this.state.dayConsultToShow[key]["dateFormatShow"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["diet"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["diet_amount_to_show"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["hoursAvailable"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["totalDrivingHours"]}</td>   
                                <td className='w-15'>{this.state.dayConsultToShow[key]["totalWorkingHours"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["totalRestHours"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["kilometers"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["comments"]} {this.state.dayConsultToShow[key]["suspectful"]}</td>
                                <td className='w-20 text-right'>
                                    {
                                      //Si el usuario es administrador, puede ver el botón Ver.
                                      this.props.userRole === "admin" || this.props.userRole === "manager"
                                        ? 
                                          <button className="btn btn-outline-secondary fs-12 h-34" onClick={() => {this.handleClick(key)}}>
                                            Ver
                                          </button>     
                                        : null
                                    }
                                </td>
                                <td className='w-20 text-right'>
                                  <button className="btn btn-outline-secondary fs-12 h-34"
                                          onClick={() => {
                                            this.props.actualizarEstadoYAlmacenar()
                                            this.props.showModalPeriod(this.state.dayConsultToShow[key])}}>
                                    Editar
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            }else {
              displayListDayConsult =
                <div id="content" className="mt-3">
                  <div className="card mb-4">
                    {paginatorDayConsult}
                    <div className="card-body">
                      <table className="table fs-12" >
                        <thead>
                          <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Dieta</th>
                            <th scope="col">Importe</th>
                            <th scope="col">Km</th>
                          </tr>
                        </thead>
                        <tbody id="productList">
                          {this.state.dayConsultToShow.map((period, key) => {
                            var statusText = ""
                            var statusStyle = "green"

                            return (
                              <tr key={key} className='w-100'>
                                <td className='w-10'>{this.state.dayConsultToShow[key]["dateFormatShow"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["diet"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["diet_amount_to_show"]}</td>
                                <td className='w-15'>{this.state.dayConsultToShow[key]["kilometers"]}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            }
          

            display =
              <div className='w-100'>
                <table className='w-100'>
                  <tbody className='w-100'>
                    <tr className='w-100'>
                      {listControlPanelDayConsult}
                    </tr>
                    <tr>
                      {displayListDayConsult}
                    </tr>
                  </tbody>
                </table>

              </div>
            ;
          }

    if (this.props.userRole==="admin" || this.props.userRole === "manager"){
      optionButons = 
        <div>
          
            <form onSubmit={(event) => { event.preventDefault(); this.props.actualizarEstadoYAlmacenar();this.actualizarEstadoYAlmacenarEmployees();this.props.showCreationFields() }}>
              <button type="submit" className="btn btn-primary btn-block btn-lg w-100 fs-16">
                Editar Empleado
              </button>
            </form>
          
          <form onSubmit={(event) => {
            event.preventDefault()
            this.props.actualizarEstadoYAlmacenar()
            this.actualizarEstadoYAlmacenarEmployees()
            this.props.showCreateTripFields()}}>
        <br/>
            <button type="submit" className='btn btn-primary btn-block btn-lg w-100 fs-16'>
              Crear viaje
            </button>
          </form>
          <form onSubmit={(event) => {
            event.preventDefault()
            this.props.actualizarEstadoYAlmacenar()
            this.actualizarEstadoYAlmacenarEmployees()
            this.props.dowloadEmployeeReport(empId)}}>
          <br/>
            <button type="submit" className='btn btn-primary btn-block btn-lg w-100 fs-16'>
              Descargar reporte
            </button>
          </form>
          <form onSubmit={(event) => {
              event.preventDefault()
              this.props.actualizarEstadoYAlmacenar()
              this.actualizarEstadoYAlmacenarEmployees()
              this.props.showHolidays()
            }}>
          <br/>
            <button type="submit" className="btn btn-primary btn-block btn-lg w-100 fs-16">
              Consultar vacaciones
            </button>
          </form>
        </div>
    }


    
    


  }

  //console.log("llegando al return de employee details")
  return (
    <div className='w-100'>
      <div className='w-100'>
        {controlpanel}
      </div>
      <div className='w-100'>
        {display}
      </div>
      {registerDetails}
      {optionButons}
    </div>
  )

  }

      // } catch (err) {
    //   setTimeout(() => window.location.reload(), 2000);
    //   return <div>Error al cargar los detalles del empleado</div>;
    // }
}


export default EmployeeDetails;
