import React, { Component } from "react";

import loaderbar from "../loaderbar.gif";

class CustomSpinner extends Component {
  render() {
    const device = this.props.device;
    if (device === "desktop") {
      return (
        <div className="custom-spinner-loading-overlay">
          <img src={loaderbar} alt="Spinner personalizado" />
        </div>
      );
    } else {
      return (
        <div className="custom-spinner-loading-overlay">
          <img style={{ maxWidth: "250px" }} src={loaderbar} alt="Spinner personalizado" />
        </div>
      );
    }
  }
}

export default CustomSpinner;
