import React, { useState } from 'react';
import { Link, NavLink, Router } from 'react-router-dom';
import { Component } from 'react';
import Logout from './Logout';
import logo from '../logo.webp';
import logoPNG from '../logonuevo.png';

class NavBarLogged_Desktop_from_about extends Component {

  render() {

    const apagador = () => {
      //console.log("Apaga");
      {Logout.handleLogout}
      <>

      </>
      
    };

    let  device;
    let ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      device = "tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      device = "mobile";
    }else{
      device = "desktop";
    }

    const token = localStorage.getItem("TraxainUserToken");
    var logginButtons;
      if(token !== null && token !== "null"){
        logginButtons = <Logout />;
      }else {
        logginButtons = <div>
          <table>
            <tr>
            <td className='w-20 nav-btn rounded'>
                                <div className='w-50 btn btn-sm btn-link'>
                                  <NavLink className="btn btn-sm btn-block nav-btn fs-5 ml-60-px mr-60-px mt-10-px" to="/register">Registrarse</NavLink>
                                </div>
                              </td>
                              {/* Registro */}
                              {/* Iniciar sesion*/}
                              <td className='w-20 nav-btn rounded '>
                                <div className='w-195 btn btn-sm btn-link'>
                                    <NavLink className='btn btn-sm btn-block nav-btn fs-5 mt-10-px' to="/login">Iniciar sesión</NavLink>
                                </div>
            </td>
            </tr>
          </table>
          </div>       
      }

//console.log("NavBarLogged_Desktop_from_about");
 if (device === "desktop") {
    return (
      <nav       className="navbar fixed-top bg-white shadow w-100 h-12">
            <table   className='w-100 h-90'>
              <tbody className='w-100 h-100'>
                <tr  className='w-100 h-10'>
                  <br></br>
                </tr>
                <tr className='w-100 h-80'>
      <div className="navbar custom-navbar">
        <div className="d-flex justify-content-start align-items-center">
                      <img src={logo} className="d-inline-block p-10-px h-67 w-67" alt="Drive-Team"
                      onError={(e) => {
                        e.target.src = logoPNG;
                      }}/>
        </div>
        <div className="d-flex justify-content-end">
          <table className="navbar-default">
            <tbody>
              <tr className="container-fluid">
                <td className='w-20 nav-btn rounded text-center'>
                  <div className='w-50 btn btn-sm btn-link'>
                    <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/">Inicio</NavLink>
                  </div>
                </td>
                <td className='w-20 nav-btn rounded text-center'>
                  <div className='w-50 btn btn-sm btn-link'>

                    <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 text-center mt-10-px" to="/blog">Blog</NavLink>

                  </div>
                </td>
               
                {logginButtons}
               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </tr>
      </tbody>
      </table>
      </nav>
    );
                    }else{
                      
                    }
  }
}

export default NavBarLogged_Desktop_from_about;
