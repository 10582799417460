import React, { useState, useEffect } from "react";
//import { FacebookShareButton, TwitterShareButton, FacebookIcon, XIcon } from "react-share";

const content = null;

const blogListTotal = [
  {
    id: 1,
    title: "¿Qué es Drive-Team.es?",
    summary:
      "Saca provecho a los datos de tus tacógrafos. Traxain te ayuda usando los datos legalmente válidos del tacógrafo para calcular y controlar tus gastos en personal",
    path: "drive-team-tgd",
    author: "Ignacio Pardo",
    date: "4 de Marzo de 2024",
    imageUrl: "/captura_sobre.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Drive-Team.es es la plataforma para calcular automáticamente las dietas de los conductores de camión y autobús, tanto en el transporte
          nacional como internacional por carretera. Simplemente sube los archivos del tacógrafo, del vehículo o del conductor, y Drive-Team.es te
          dará un reporte de las actividades, del cumplimiento de jornadas y un desglose de las dietas.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Quién está detrás de Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          Drive-Team.es es uno de los productos ofrecidos por Traxain. Traxain S.L es una Startup española dedicada a solucionar los problemas del
          transporte por carretera a través del uso de la tecnología. Trabajamos constantemente para mejorar la vida de todos los implicados en este
          sector, que es el corazón de toda la economía moderna.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Por qué creamos Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          La escasez internacional de conductores de camión ha provocado aumentos significativos en los costes de las empresas de transporte. En este
          contexto es más necesario que nunca optimizar nuestro flujo de efectivo y hacer el mejor uso posible de nuestros recursos. Para ello{" "}
          Drive-Team.es ofrece 5 capacidades clave:
          <ul>
            <li>Un dashboard para analizar la productividad de cada empleado.</li>
            <li>Previsión y cálculo automático de dietas.</li>
            <li>Previsión y cálculo automático de Complementos por desplazamiento.</li>
            <li>Detección de actividades anómalas en el tacógrafo y aclaración por SMS.</li>
            <li>Cálculo y alertas de cumplimiento de jornadas.</li>
          </ul>
        </p>
        <br></br>
        <h2 className="fw-bold">¿Qué hace especial a Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          Lo que hace especial a Drive-Team.es es que utiliza directamente los datos del Tacógrafo digital de las unidades, para lo que trabajamos con
          nuestro socio Continental. Contar con los datos del Tacógrafo nos permite calcular exactamente los costes en los que tendrá que incurrir la
          empresa utilizando los datos que son legalmente vinculantes.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Cómo lo hacemos?</h2>
        <br></br>
        <p className="text-intend-30p">
          Nuestro objetivo es que tanto las empresas de transporte como los conductores puedan saber por anticipado cuánto van a cobrar y saber que
          este cálculo se ha realizado de manera imparcial utilizando los datos del Tacógrafo, que es imposible falsificar. Para ello ofrecemos un
          Dashboard que permite ver en tiempo real las actividades de los empleados y comprobar el tiempo de conducción, descanso, otros trabajos y
          disponibilidad. Con este Dashboard se pueden comparar los tiempos de trabajo de los distintos empleados, y se puede ver una estimación de
          salario que habrá que pagar a final de mes.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Es complicado de instalar?</h2>
        <br></br>
        <p className="text-intend-30p">Para nada, basta con tener un usuario y una contraseña, y acceder a Drive-Team.es para usarlo.</p>
        <br></br>
        <h2 className="fw-bold">¿No estás convencido aún?</h2>
        <br></br>
        <p className="text-intend-30p">
          No hay problema. Ponte en contacto con nosotros escribiendo a ignacio@traxain.com, en Traxain.com o al teléfono +34 638269633 y podrás
          probar Drive-Team.es sin compromiso.
        </p>
        <br></br>
        <h2 className="fw-bold">¿No es lo que buscabas?</h2>
        <br></br>
        <p className="text-intend-30p">
          Si Drive-Team.es no es lo que buscas, ¡Dinos qué buscas! Podemos ponerte en contacto con otros proveedores, o diseñar una solución para tí
          sin compromiso.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 2,
    title: "¿Qué pasa con el CMR digital?",
    summary:
      "Se está debatiendo en el Congreso una medida en el proyecto de ley de Movilidad Sostenible que establece la obligatoriedad de utilizar documentos de control digitales para el transporte, incluyendo el transporte internacional, a partir del 1 de octubre de 2024. Esta medida puede representar un avance en términos de transparencia y control en el sector del transporte.",
    path: "cmr-digital-actualidad",
    author: "Ignacio Pardo",
    date: "11 de Marzo de 2024",
    imageUrl: "/cmr.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Hoy queremos compartir con vosotros una información muy relevante para el sector del transporte. Se trata de una medida que se está
          debatiendo en el Congreso de los Diputados y que puede tener un impacto significativo en la forma en que se lleva a cabo el transporte de
          mercancías y viajeros.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Por qué tanto revuelo?</h2>
        <br></br>
        <p className="text-intend-30p">
          En el proyecto de ley de Movilidad Sostenible se ha establecido la obligatoriedad de que el documento de control exigido para el transporte,
          con algunas excepciones, sea necesariamente digital a partir del 1 de octubre de 2024. Esto significa que se deberá utilizar una plataforma
          autorizada por la Dirección General de Transporte Terrestre y que el documento digital deberá contener los datos requeridos para su
          inspección.
        </p>
        <br></br>
        <h2 className="fw-bold">¿Se aplicará también al internacional?</h2>
        <br></br>
        <p className="text-intend-30p">
          <b>SI.</b> Es importante destacar que esta medida se aplicará también al transporte internacional y que se permitirán las cartas de porte
          establecidas por las normativas nacionales o extranjeras que contengan los datos requeridos. Sin embargo, se interpreta que una carta de
          porte CMR en papel sería válida como documento contractual entre las partes, pero no como documento de control a efectos administrativos.
          Por lo tanto, como decimos, el documento electrónico, tanto CMR como Carta de Porte, será <b>OBLIGATORIO.</b>
        </p>
        <br></br>
        <h2 className="fw-bold">En resumen</h2>
        <br></br>
        <p className="text-intend-30p">
          En conclusión, esta medida puede representar un gran avance en términos de transparencia y control en el sector del transporte, y puede
          contribuir a la promoción de prácticas más sostenibles y eficientes en este ámbito. Como siempre, estaremos atentos a las novedades y
          actualizaciones en este sentido, para mantenerles informados de todo lo que pueda ser relevante para el sector.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 3,
    title: "Crecimiento en las Matriculaciones de Vehículos Comerciales en Noviembre",
    summary:
      "Noviembre ha registrado un notable aumento en las matriculaciones de camiones y furgonetas en España, reflejando la fortaleza del mercado de vehículos industriales y comerciales.",
    path: "matriculacion-automocion-mercado",
    author: "Ignacio Pardo",
    date: "18 de Marzo de 2024",
    imageUrl: "/trucksline.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          El mes de noviembre ha sido testigo de un incremento significativo en las matriculaciones de vehículos comerciales. Los camiones han
          experimentado un crecimiento del 17,2%, alcanzando las 2.961 unidades vendidas, mientras que el acumulado del año muestra un aumento del
          22,5% con un total de 26.335 unidades.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Por otro lado, las furgonetas han visto un impresionante crecimiento del 42,4% en noviembre, con 14.474 unidades vendidas. En total, hasta
          noviembre, se han comercializado 132.921 vehículos, lo que representa un aumento del 23,7%.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Este crecimiento ha sido notable en todos los canales, destacando especialmente en el canal de empresas, que ha registrado un aumento del
          44,8%. Las ventas a autónomos también han crecido significativamente, con un aumento del 32,2%.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 4,
    title: "Palibex y la Inteligencia Artificial: Innovación en la Logística",
    summary:
      "Palibex está integrando la inteligencia artificial en sus operaciones logísticas, mejorando la eficiencia en la gestión de mercancías y la coordinación entre centros. Exploramos cómo esta tecnología está transformando sus operativas diarias.",
    path: "palibex-ia-logistica",
    author: "Ignacio Pardo",
    date: "25 de Marzo de 2024",
    imageUrl: "/palibex.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Palibex está aprovechando las ventajas que aporta la inteligencia artificial en el núcleo de sus operaciones, especialmente en la gestión y
          coordinación del movimiento de las mercancías.
        </p>
        <br></br>
        <p className="text-intend-30p">
          El director de Sistemas, Marcos Fierro, resalta la mejora en el dimensionamiento de palés y camiones mediante IA, lo que optimiza el TMS y
          ayuda en la previsión de necesidades.
        </p>
        <br></br>
        <p className="text-intend-30p">
          La IA juega un papel crucial en la mejora operativa de Palibex, analizando datos para la toma de decisiones y optimización de procesos como
          transporte y mantenimiento.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Las innovaciones como la navegación por realidad aumentada de Google Maps y Microsoft Copilot están siendo estudiadas para su integración en
          las tareas diarias de la empresa.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Fierro subraya la importancia de adaptar innovaciones tecnológicas para convertirlas en mejoras tangibles y mantener la competitividad
          empresarial.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 5,
    title: "Portugal sigue el ejemplo de España con el céntimo sanitario",
    summary:
      "El Tribunal europeo ha fallado en contra de la “contribución por el servicio vial” en Portugal, similar al “céntimo sanitario” en España, afectando a los impuestos sobre combustibles y abriendo la puerta a reclamaciones de reembolso.",
    path: "portugal-sentencia-impuestos",
    author: "Ignacio Pardo",
    date: "1 de Abril de 2024",
    imageUrl: "/portugal.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Al igual que en España con el "céntimo sanitario", el Alto Tribunal europeo ha fallado en contra de la "contribución por el servicio vial"
          en Portugal. Este impuesto, creado en 2007, se aplicaba a combustibles para financiar el mantenimiento vial, recaudando más de 600 millones
          de euros anuales.
        </p>
        <br></br>
        <p className="text-intend-30p">
          El impuesto incluía un recargo en diésel, gasolina y gas licuado de petróleo. En España, el céntimo sanitario fue declarado ilegal por el
          Tribunal de Justicia de la Unión Europea, llevando a su derogación y a la apertura de reclamaciones de reembolso.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Ante un fallo similar, Portugal derogó su contribución en diciembre de 2022, incorporándola al impuesto sobre hidrocarburos. Ahora, los
          transportistas y otros consumidores afectados pueden reclamar las cantidades pagadas en los últimos cuatro años.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Empresas de transporte en Portugal pueden iniciar procedimientos administrativos para solicitar reembolsos de la contribución declarada
          ilegal por la justicia europea.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 6,
    title: "No Dejes Que Tus Horas Extras Se Evaporen: Domina el Uso del Tacógrafo Digital",
    summary:
      "Descubre en este artículo cómo tu fiel compañero en la ruta, el tacógrafo digital, puede ser la clave para no perder ni un euro de tus merecidas horas extras. Aprende a manejar este dispositivo con precisión y conviértelo en tu aliado para asegurar cada hora de esfuerzo trabajada. No permitas que tus ganancias adicionales se desvanezcan por un mal registro; toma el volante de tu carrera profesional con responsabilidad y conocimiento.",
    path: "tacografo-logistica-horas-extras",
    author: "Ignacio Pardo",
    date: "8 de Abril de 2024",
    imageUrl: "/tacografo.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Imagínate navegando por un mar de carreteras con tu camión, donde el tacógrafo digital es tu brújula. Este aparato no solo te guía, sino que
          también cuenta la historia de tu jornada laboral. Pero, ¿sabías que cómo lo usas puede hacer la diferencia entre ganar más o perder dinero
          en tus horas extras? Vamos a desentrañar este misterio y recuerda, para entender mejor tu tacógrafo, visita drive-team.es.
        </p>
        <br></br>
        <h2 className="fw-bold">1. El Tacógrafo Digital: Tu Mejor Aliado en la Ruta</h2>
        <br></br>
        <p className="text-intend-30p">
          El tacógrafo digital es como un diario fiel de tu vida en la carretera. Registrar correctamente tus horas de conducción y descanso es tan
          crucial como tener un buen mapa en un viaje desconocido. Usarlo adecuadamente es sinónimo de seguir el camino de la seguridad y la
          legalidad. Piénsalo como el contador de tu esfuerzo, que debe reflejar cada minuto trabajado con precisión de relojero.
        </p>
        <br></br>
        <h2 className="fw-bold">2. Cuidado con Perder Dinero: El Uso Incorrecto del Tacógrafo y las Consecuencias en Horas Extras</h2>
        <br></br>
        <p className="text-intend-30p">
          Un tacógrafo mal utilizado es como tener un agujero en el bolsillo de tu pantalón favorito; poco a poco, tus ganancias en horas extras se
          pueden esfumar sin que te des cuenta. Si marcas descanso cuando en realidad estás trabajando, es como decirle a tu cartera que no quiere más
          dinero. Las horas extras son como un premio por tu duro trabajo, y perderlas por un mal registro es como dejar que ese premio se escape de
          tus manos. Así que, usar este aparato con honestidad y precisión es el truco para mantener tu billetera feliz.
        </p>
        <br></br>
        <h2 className="fw-bold">3. Responsabilidad al Volante: Cumplir con la Ley para un Futuro Seguro</h2>
        <br></br>
        <p className="text-intend-30p">
          Usar correctamente el tacógrafo no es solo una regla, es como el cinturón de seguridad para tu carrera profesional. Cada registro preciso es
          un paso hacia un futuro más seguro y estable. Imagínate que cada entrada correcta en tu tacógrafo es como construir un puente sólido sobre
          un río de incertidumbre legal. Cumplir con la normativa no solo te protege de multas, sino que también mantiene intacta tu reputación como
          conductor responsable.
        </p>
        <br></br>
        <b>Conclusión</b>
        <br></br>
        <p className="text-intend-30p">
          El tacógrafo digital, ese pequeño aparato en tu tablero, es un gigante en importancia. Usarlo correctamente no solo es seguir un mapa hacia
          la seguridad y la legalidad, sino que también es asegurar que cada hora de esfuerzo se refleje en tu salario. Así que, la próxima vez que
          subas a tu camión, recuerda: el tacógrafo es tu aliado, tu contador honesto y tu guardián en la carretera. Y para entenderlo aún mejor, no
          olvides visitar drive-team.es. ¡Que tus rutas sean siempre seguras y rentables!
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 7,
    title: "II Foro Internacional Mujer y Sector de Transporte",
    summary:
      "El evento destacó la importancia de la visibilidad y el empoderamiento de las mujeres en el sector del transporte, abordando desafíos y comprometiéndose a continuar el apoyo.",
    path: "mujer-igualdad-logistica",
    author: "Ignacio Pardo",
    date: "15 de Abril de 2024",
    imageUrl: "/foromujer.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Celebrado en Valencia el 22 de febrero, el <strong>II Foro Internacional Mujer y Sector de Transporte</strong>, organizado por Andamur, puso
          de relieve la participación y los retos de las mujeres en el ámbito del transporte. Con un enfoque en aumentar la visibilidad femenina en un
          sector predominantemente masculino, el evento resaltó que solo el 4% de los empleos de conducción son ocupados por mujeres, subrayando la
          necesidad de abordar esta desigualdad.
        </p>
        <br></br>
        <p className="text-intend-30p">
          A través de mesas redondas y testimonios personales, las participantes exploraron los avances hacia la igualdad de género, aunque
          enfatizaron en las persistentes barreras para la conciliación entre el trabajo y la vida personal. El foro cerró con el anuncio de la
          próxima edición y la introducción de premios dedicados a las contribuciones femeninas en el sector, reafirmando el compromiso con el
          empoderamiento de las mujeres en el transporte.
        </p>
        <br></br>
      </div>
    ),
  },
];

export { blogListTotal };
