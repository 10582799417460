import React, { Component } from "react";
import BarChart from "./BarChart";
import PerWeekChart from "./PerWeekChart";
import ScatterChart from "./ScatterChart";
import SMSBarChart from "./SMSBarChart";
import DatePicker from 'react-datepicker';
import pickdate from '../pickdate.webp';
import graphCharge from '../graphcharge.webp';



const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    minHeight: "40px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    boxShadow: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#007bff",
    color: "white",
  }),
};

class EmployeeGraphic extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 1);

        this.state = {
            dateDesde: startDate,
            dateHasta: new Date(),
            isOpen: true,
            chartType: this.props.chartType,
            chartTitle: 'Horas de trabajo realizadas por los conductores',
            selectedEmployees: this.props.employees, 
            alreadyCalled: false,
            searchTerm:""
        };
    }

    toggleChartType = (chartType, chartTitle) => {
            const { dataGraphic } = this.props;
            // get dataGrafic.length
            
           
            if (dataGraphic.length === 0 || dataGraphic === "notCalled") {
                
            
               
                    let employees = this.state.selectedEmployees
                    let desde = this.state.dateDesde
                    if (desde === '') {
                        desde = 0
                    } else {
                        desde = parseInt(desde.getTime() / 1000)
                    }
                    let hasta = this.state.dateHasta
                    if (hasta === '') {
                        hasta = 0
                    } else {
                        hasta = parseInt(hasta.getTime() / 1000)
                    }
                    this.props.showGraphic(desde, hasta, employees, true, chartType)
            }
            
        this.setState({
            chartType,
            chartTitle,
        });
    };
    
    renderChart = () => {
        const { chartType } = this.state;
        const { dataGraphic } = this.props;
        var loading = this.props.loading;
        if (loading) {
            return <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={graphCharge} alt="graphCharge" />
                    </div>;
        } else if (dataGraphic === "notCalled") {
            return <div className="d-flex flex-column justify-content-center align-items-center">
                <p className=" fs-20 text-muted">Selecciona unas fechas y uno o varios empleados para ver los datos</p>
                <img className="mxw-200" src={pickdate} alt="pickdate" />
                </div>;
        } else if (dataGraphic.length === 0) {
            return <div className="d-flex flex-column justify-content-center align-items-center">
                <p className=" fs-20 text-muted">No hay datos para esas fechas, ajusta la selección</p>
                <img className="mxw-200" src={pickdate} alt="pickdate" />
                </div>;
        } else {
            switch (chartType) {
                case 'scatter':
                    return <ScatterChart dataGraphic={dataGraphic} />;
                case 'bar':
                    return <BarChart dataGraphic={dataGraphic} />;
                case 'sms':
                    return <SMSBarChart dataGraphic={dataGraphic} />;
                case 'perweek':
                    return <PerWeekChart dataGraphic={dataGraphic} />;
                default:
                    return null;
            }
        }
    };

    handleChangeDesde = async (dateDesde) => {
        try {
            this.setState({ dateDesde: dateDesde });
        } catch (err) {
            alert("Error al indicar la fecha inicial");
        }
    };

    handleChangeHasta = async (dateHasta) => {
        try {
            this.setState({ dateHasta: dateHasta });
        } catch (err) {
            alert("Error al indicar la fecha final");
        }
    };

    selectAllEmployees = () => {
        const { employees } = this.props;
        const allEmployeeIds = employees.map(employee => employee.id);
        this.setState({ selectedEmployees: employees });
    };
    
    deselectAllEmployees = () => {
        this.setState({ selectedEmployees: [] });
    };


    handleCheckboxChange = (employee) => {
        const { selectedEmployees } = this.state;
        const index = selectedEmployees.indexOf(employee);
        if (index !== -1) {
          selectedEmployees.splice(index, 1);
        } else {
          selectedEmployees.push(employee);
        }
        this.setState({ selectedEmployees });
    };
    
    handleSearchInputChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    };

    render() {
        const { isOpen, chartTitle, selectedEmployees } = this.state;
        const { employees } = this.props;
        const {searchTerm} = this.state;

        if (!isOpen) {
            return null;
        }

        const employeeOptions = employees.map(employee => ({ value: employee.id, label: employee.name }));
        const filteredEmployees = employees.filter(employee =>
            employee.name.toLowerCase().includes(searchTerm.toLowerCase())
        );


        if (this.props.device==="desktop") {
        return (
            <div className="d-flex flex-row al-it-lft">
                
                <div className="card shadow w-30 m-2 p-15-px d-flex flex-column align-items-center">
                    

                        <div className="d-flex flex-row">
                        <div className="w-50">
                            <p className="ml-5-px">Desde</p>
                            <DatePicker
                                className="form-control form-control-lg"
                                selected={this.state.dateDesde}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleChangeDesde.bind(this)}
                                required
                            />
                        </div>

                        <div  className="w-50">
                            <p className="ml-5-px">Hasta</p>
                            <DatePicker
                                className="form-control form-control-lg"
                                selected={this.state.dateHasta}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleChangeHasta.bind(this)}
                                required
                            />
                        </div>
                    </div>
                       

                    <div id="lista" className="w-100 p-5-px mt-10-px">
                        <p>Empleados a buscar: {selectedEmployees.length}</p>

                        <div>
                            {/* Campo de búsqueda */}
                            <input
                                className="form-control form-control-lg w-50"
                                type="text"
                                placeholder="Buscar empleado..."
                                value={searchTerm}
                                onChange={this.handleSearchInputChange}
                            />

                           
                        </div>

                        <div className="d-flex flex-row al-it-lft mt-5-px">
                            <button className="btn btn-primary w-50" onClick={this.selectAllEmployees}>
                                Seleccionar todos
                            </button>
                            <button className="btn btn-primary w-50 ml-5-px" onClick={this.deselectAllEmployees}>
                                Deseleccionar todos
                            </button>
                        </div>

                        <div className="w-100 p-5-px mt-10-px col-co max-h-550-px overflow-y-auto">
                            <ul className="list-group">
                                {/* {employees.map((employee) => ( */}
                                {filteredEmployees.map(employee => (
                                <li key={employee.id} className="list-group-item bt-w mt-2-px b-r-5" style={{ borderTopWidth: 1, marginTop: 2, borderRadius: 5 }}>
                                    <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value={employee.id}
                                    id={`checkbox_${employee.id}`}
                                    onChange={() => this.handleCheckboxChange(employee)}
                                    checked={selectedEmployees.includes(employee)}
                                    />
                                    <label className="form-check-label fs-14" htmlFor={`checkbox_${employee.id}`}>
                                    {employee.name}
                                    </label>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>


                    <div className="btn btn-primary w-100 p-5-px mt-10-px"
                        onClick={(event) => {
                            event.preventDefault()
                            let employees = this.state.selectedEmployees
                            let desde = this.state.dateDesde
                            if (desde === '') {
                                desde = 0
                            } else {
                                desde = parseInt(desde.getTime() / 1000)
                            }
                            let hasta = this.state.dateHasta
                            if (hasta === '') {
                                hasta = 0
                            } else {
                                hasta = parseInt(hasta.getTime() / 1000)
                            }

                            this.setState({ alreadyCalled: true })
                            this.props.showGraphic(desde, hasta, employees,true, this.props.chartType)
                        }}>
                        Buscar
                    </div>


                </div>
                  
                
                <div className="card shadow w-70 h-90 bg-white p-15-px m-2 ml-0">
                    <div className="card mb-20-px p-10-px">
                        <h2 className="text-muted">{chartTitle}</h2>
                    </div>
                    <div className="mb-30-px">
                        {this.renderChart()}
                    </div>
                    <div className="d-flex justify-content-center">
                        <button onClick={() => this.toggleChartType('scatter', 'Horas de trabajo realizadas por los conductores')} className="btn btn-primary me-mr-20">
                            Horas de trabajo
                        </button>
                        <button onClick={() => this.toggleChartType('bar', 'Ratio de trabajo por conductor')} className="btn btn-primary me-mr-20">
                            Ratio de trabajo
                        </button>
                        <button onClick={() => this.toggleChartType('sms', 'SMS enviados y respondidos')} className="btn btn-primary me-mr-20">
                            SMS enviados y respondidos
                        </button>
                        <button onClick={() => this.toggleChartType('perweek', 'Ratio semanal')} className="btn btn-primary me-mr-20">
                            Ratio agregado por semana
                        </button>
                        <button onClick={this.props.onClose} className="btn btn-danger">
                            Cerrar
                        </button>
                    </div>                
                </div>
            </div>
        );

         }else{
            return (
                <div className="d-block w-100 flex-column align-items-center">
                    <div className="card shadow w-100 m-2 p-15-px d-block flex-column align-items-center">
                        <div className="d-flex flex-row">
                            <div className="w-50">
                                <p className="ml-5-px">Desde</p>
                                <DatePicker
                                    className="form-control form-control-lg"
                                    selected={this.state.dateDesde}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={this.handleChangeDesde.bind(this)}
                                    required
                                />
                            </div>
    
                            <div className="w-50 ml-5-px">
                                <p className=" ml-5-px">Hasta</p>
                                <DatePicker
                                    className="form-control form-control-lg"
                                    selected={this.state.dateHasta}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={this.handleChangeHasta.bind(this)}
                                    required
                                />
                            </div>
                        </div>
    
                        <div id="lista" className="d-block w-100 p-15-px mt-10-px">
                            <p>Empleados a buscar: {selectedEmployees.length}</p>
    
                            <div className="d-flex flex-row al-it-lft">
                                <button className="btn btn-primary w-50" onClick={this.selectAllEmployees}>
                                    Seleccionar todos
                                </button>
                                <button className="btn btn-primary w-50 ml-5-px" onClick={this.deselectAllEmployees}>
                                    Deseleccionar todos
                                </button>
                            </div>
    
                            <div className="w-100 p-5-px mt-5-px col-co max-h-550-px overflow-y-auto">
                                <ul className="list-group">
                                    {employees.map((employee) => (
                                    <li key={employee.id} className="list-group-item" style={{ borderTopWidth: 1, marginTop: 2, borderRadius: 5 }}>
                                        <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        value={employee.id}
                                        id={`checkbox_${employee.id}`}
                                        onChange={() => this.handleCheckboxChange(employee)}
                                        checked={selectedEmployees.includes(employee)}
                                        />
                                        <label className="form-check-label" style={{fontSize: "14px"}} htmlFor={`checkbox_${employee.id}`}>
                                        {employee.name}
                                        </label>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
    
    
                        <div className="btn btn-primary d-block p-5-px mt-10-px"
                            onClick={(event) => {
                                event.preventDefault()
                                let employees = this.state.selectedEmployees
                                let desde = this.state.dateDesde
                                if (desde === '') {
                                    desde = 0
                                } else {
                                    desde = parseInt(desde.getTime() / 1000)
                                }
                                let hasta = this.state.dateHasta
                                if (hasta === '') {
                                    hasta = 0
                                } else {
                                    hasta = parseInt(hasta.getTime() / 1000)
                                }
                                this.setState({ alreadyCalled: true })
                                this.props.showGraphic(desde, hasta, employees,true, this.props.chartType)
                            }}>
                            Buscar
                        </div>
    
    
                    </div>
                      
                    
                    <div className="card shadow d-block w-100 bg-white m-2">
                        <div className="card mb-20-px">
                            <h2 className="text-muted">{chartTitle}</h2>
                        </div>
                        <div className="mb-30-px">
                            {this.renderChart()}
                        </div>
                        <div className="d-flex justify-content-center w-100 flex-wrap">
                            <button onClick={() => this.toggleChartType('scatter', 'Horas de trabajo realizadas por los conductores')} className="btn btn-primary m-5 w-100">
                                Horas de trabajo
                            </button>
                            <button onClick={() => this.toggleChartType('bar', 'Ratio de trabajo por conductor')} className="btn btn-primary m-5 w-100">
                                Ratio de trabajo
                            </button>
                            <button onClick={() => this.toggleChartType('sms', 'SMS enviados y respondidos')} className="btn btn-primary m-5 w-100">
                                SMS enviados y respondidos
                            </button>
                            <button onClick={() => this.toggleChartType('perweek', 'Ratio semanal')} className="btn btn-primary m-5 w-100">
                                Ratio agregado por semana
                            </button>
                            <button onClick={this.props.onClose} className="btn btn-danger m-5 w-100">
                                Cerrar
                            </button>
                        </div>                
                    </div>
                </div>
            );
         }
    }
}

export default EmployeeGraphic;
