import axios from 'axios';
 
import React,{Component} from 'react';
import { isEmpty } from 'lodash';


let file = '';
class UploadTGDFile extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      files:[],
      message:"",
      selectedFile:true
    }
  }
    
    // On file select (from the pop up)
    handleChangeFile = async (event) => {
    
      // Update the state
      //this.setState({ selectedFile: event.target.files[0] });

     let fileList = file.files
     
     if (file.files.length > 0) {
       
         var currentFiles = this.state.files;
         for (let key in fileList) {
          if (fileList[key].name !== undefined && fileList[key].name !== 'item'){
            if (fileList[key].name.slice(-4)===".tgd" || fileList[key].name.slice(-4)===".ddd"  
                || fileList[key].name.slice(-4)===".DDD" || fileList[key].name.slice(-4)===".TGD"
                || fileList[key].name.slice(-5)===".xlsx" || fileList[key].name.slice(-5)===".XLSX"){
                  currentFiles.push(fileList[key]);
                  let message
                  if (currentFiles.length===1){
                    message = currentFiles[0].name
                  }else{
                    message = currentFiles.length.toString()  + " archivos"
                  }
                  this.setState({
                    files: currentFiles,
                    message: message
                  });
            }
            else{
              this.setState({files : []})
              this.setState({message:'Archivo incorrecto'});
            }
          }
        }
        
      }
    };
    
    // On file upload (click the upload button)
    
    onFileUpload = async () => {

      this.props.putLoading(true);
      

      let arrayOfYourFiles = this.state.files

      const formData = new FormData();

      if (arrayOfYourFiles.length===0){
        this.setState({message:'Suba un archivo .xls o .tgd'})
        this.props.putLoading(false);
      }
      else if(arrayOfYourFiles[0].name.endsWith('.xlsx') || arrayOfYourFiles[0].name.endsWith('.XLSX')){
        formData.append('UploadFile', arrayOfYourFiles[0]);
        formData.append('file', arrayOfYourFiles[0]);
        formData.append('fileName', arrayOfYourFiles[0]);
        
        let token = this.props.userToken
        // Details of the uploaded file
        if(token!=='null'){
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              "Authorization": "Bearer " + token
            }
          };
        
          // Request made to the backend apisavePlacesArray
          // Send formData object
          axios.post(this.props.endPoint +"uploadXLSfile/", formData, config).then((response) => {
            this.setState({message: 'Archivo subido correctamente.'})
            this.props.putLoading(false);
          }).catch(error => {
              if(error.response.status == 403){
                this.setState({files : []})
                alert('Archivo vacío. Suba un archivo válido')
                this.props.putLoading(false);
              }
            
          });
          this.setState({files : []})
          setTimeout(() => window.location.reload(), 5000);
        }
        else{
          this.setState({files : []})
          alert('Para subir un archivo excel, necesita registrarte')
        }
        
      }
      else{
        


    
      // Create an object of formData
      
      for (let i = 0; i < arrayOfYourFiles.length; i++) {
        ////console.log(arrayOfYourFiles[i].name)
        formData.append('files', arrayOfYourFiles[i])
      }
      
      // Details of the uploaded file
      var token = this.props.userToken
      let url
      let config

      ////console.log(token)

      if (token!=="null"){
        config = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          },
        };
  
        url = this.props.endPoint+"uploadTGDfile/"
        axios.post(url, formData, config).then((response) => {
          var finalResponse = response.data
          this.props.processNumber(finalResponse, arrayOfYourFiles.length)
        });
          //this.props.showCheckState();
    

      }else{
        var employee 
        config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
        };
  
        
        url = this.props.endPoint+"uploadTGDfileTest/"

        //console.log("uploadTGDfileTest")

        await axios.post(url, formData, config).then((response) => {
          employee = response.data
          //console.log(employee)
          this.props.setEmployee(employee)

        });
          
        

      }
  
      // Request made to the backend apisavePlacesArray
      // Send formData object

      
      let modes = [{label:"Disponible", value: "available"},
      {label:"Conducción", value: "driving"}, 
      {label:"Descanso", value: "resting"}, 
      {label:"Trabajo", value: "working"}]

      this.setState({files: []})
      
      this.props.putLoading(false);
      
        }
        
      }
    
    // File content to be displayed after
    // file upload is complete
    fileData = () => {
    
      if (this.state.selectedFile) {
         
        return (
          <div>
            <p >{this.state.message}</p> 
            
          </div>
        );
      } else {
        return (
          <div>
             Selecciona un archivo

          </div>
        );
      }
    };
    
    render() {

      let uploadButton = <button className="btn btn-primary btn-block btn-lg mt-10 form-check-input:checked fs-14"
                                 onClick={this.onFileUpload}>
                                  Subir archivo
                          </button> 

      let content = <div> 
                      <div>
                        <input id='fileUpload' type='file' name="file" onChange={this.handleChangeFile} ref={(input) => { file = input; }} multiple hidden/>
                        <label className="btn btn-primary btn-block btn-lg form-check-input:checked fs-14" for="fileUpload">
                          Seleccionar archivo
                        </label>
                        <br/>
                        
                        <br></br>
                        {uploadButton}
                      </div>
                      {this.fileData()}
                    </div>
                    

      return (
        <table className='text-center w-100'>          
          <tbody className='text-center w-100'>
            <tr className='w-100 text-center'>
              <td className='w-100 text-center'>
                <b>
                  Sube aquí tus archivos TGD
                </b>
              </td>
            </tr>                  
            <tr className='text-center w-100'>
              <td className='text-center w-100'>
                <div className="card mb-4 w-100">
                  <div className="card-body text-center w-100">
                    {content}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>        
      );
    
      
    }
  }
 
export default UploadTGDFile;