import React from "react";
// import screenshot from '../../public/captura_sobre.webp';



const About = () => {
    let  device;
    let ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      device = "tablet";
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      device = "mobile";
    }
     device = "desktop";
  

  if (device === "desktop") {
    return (
      <div className="shadow overflow-auto h-100 w-100 bg-white pt-50 text-align-justify">
        <table className="position-relative mb-20">
          <tbody className="w-100">
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">Misión</h2>
                <p className="mb-30 text-intend-30p">
                  En Drive-Team, nos comprometemos a proporcionar soluciones innovadoras y seguras en el ámbito de la tacografía, especializándonos en
                  la gestión eficiente de nóminas para el sector logístico. Nuestra misión es simplificar y optimizar los procesos para nuestros
                  clientes, garantizando un control total sobre la información del tacógrafo y brindando confianza en la gestión de recursos humanos.
                </p>
              </td>
              <td className="w-4"></td>
            </tr>
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">Visión</h2>
                <p className="mb-30 text-intend-30p">
                  Buscamos ser líderes reconocidos en el sector logístico, destacando por nuestra excelencia en soluciones de tacografía y gestión de
                  nóminas. Nos esforzamos por ser la opción preferida de empresas comprometidas con la eficiencia, la seguridad y la automatización en
                  sus operaciones logísticas. Miramos hacia el futuro con la visión de seguir desarrollando tecnologías innovadoras que impulsen el
                  crecimiento sostenible de nuestros clientes.
                </p>
              </td>
              <td className="w-4"></td>
            </tr>
            <tr className="w-100">
              <td className="w-4"></td>
              <td>
                <h2 className="mb-15 fw-bold">Valores</h2>
                <p className="mb-30 text-intend-30p">Innovación, seguridad, eficiencia, transparencia, compromiso, ética y colaboración.</p>
              </td>
              <td className="w-4"></td>
            </tr>
          </tbody>
        </table>

        <div>
          <hr className="h-10p bg-secondary w-100 mb-30"></hr>
        </div>

        <table className="position-relative">
          <tbody className="w-100">
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">
                  ¿Qué es{" "}
                  <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                    Drive-Team.es
                  </a>
                  ?
                </h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  Drive-Team es la plataforma para calcular automáticamente las dietas de los conductores de camión y autobús, tanto en el transporte
                  nacional como internacional por carretera. Simplemente sube los archivos del tacógrafo, del vehículo o del conductor, y Drive-Team
                  te dará un reporte de las actividades, del cumplimiento de jornadas y un desglose de las dietas.
                </h3>
                <div className="d-flex justify-content-center align-items-center mt-30 mb-30">
                {/* src={screenshot} */}
                  <img  src="/captura_sobre.webp" className="shadow w-70 h-70 d-block m-auto rounded-3 mb-30" alt="Imagen interfaz APP" />
                </div>
                <h2 className="mb-15 fw-bold">
                  ¿Por qué creamos{" "}
                  <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                    Drive-Team.es
                  </a>
                  ?
                </h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  <p>
                    Creamos Drive-Team para abordar la escasez de conductores de camión y optimizar el flujo de efectivo en empresas de transporte.
                    Ofrecemos un dashboard para analizar la productividad, previsión automática de costos como dietas y complementos, detección de
                    actividades anómalas en tacógrafos y alertas de cumplimiento de jornadas. Utilizamos datos del tacógrafo digital para garantizar
                    cálculos precisos y legales, evitando reclamos futuros. La instalación es sencilla y ofrecemos pruebas sin compromiso. Si no
                    satisface tus necesidades, ofrecemos opciones alternativas o soluciones personalizadas.
                  </p>
                </h3>
                <h2 className="mb-15 fw-bold">¿Es complicado de instalar?</h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  <p>
                    Instalarlo es muy sencillo, basta con tener un usuario y una contraseña, y acceder a{" "}
                    <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                      Drive-Team.es
                    </a>{" "}
                    para usarlo.
                  </p>
                </h3>
                <h2 className="mb-15 fw-bold">¿No estás convencido aún? ¿No es lo que buscabas?</h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  Si Drive-Team no es lo que buscas no hay problema, haznos saber qué buscas. Podemos ponerte en contacto con otros proveedores, o
                  diseñar una solución personalizada. No hay problema. Puedes ponerte en contacto con nosotros escribiendo a{" "}
                  <a className="fw-bold text-highlight-color" href="mailto:ignacio@traxain.com">
                    {" "}
                    ignacio@traxain.com
                  </a>
                  , en{" "}
                  <a className="fw-bold text-highlight-color clickable" href="https://traxain.com">
                    Traxain.com
                  </a>{" "}
                  o al teléfono y <a className="fw-bold text-success">WhatsApp</a>{" "}
                  <a className="clickable text-success" onClick={() => window.open("https://wa.me/+34638269633?text=contactar%20con%20traxain")}>
                    +34 638 26 96 33
                  </a>{" "}
                  y podrás probar Drive-Team sin compromiso.
                </h3>
              </td>
              <td className="w-4">
                {/*TODO: Revisar si es necesario */}
                <td className="w-80 text-start fw-light"></td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="h-100 w-100 overflow-auto bg-white pt-50">
        <table className="position-relative mb-20">
          <tbody className="w-100">
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">Misión</h2>
                <p className="mb-30 text-intend-30p">
                  En Drive-Team, nos comprometemos a proporcionar soluciones innovadoras y seguras en el ámbito de la tacografía, especializándonos en
                  la gestión eficiente de nóminas para el sector logístico. Nuestra misión es simplificar y optimizar los procesos para nuestros
                  clientes, garantizando un control total sobre la información del tacógrafo y brindando confianza en la gestión de recursos humanos.
                </p>
              </td>
              <td className="w-4"></td>
            </tr>
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">Visión</h2>
                <p className="mb-30 text-intend-30p">
                  Buscamos ser líderes reconocidos en el sector logístico, destacando por nuestra excelencia en soluciones de tacografía y gestión de
                  nóminas. Nos esforzamos por ser la opción preferida de empresas comprometidas con la eficiencia, la seguridad y la automatización en
                  sus operaciones logísticas. Miramos hacia el futuro con la visión de seguir desarrollando tecnologías innovadoras que impulsen el
                  crecimiento sostenible de nuestros clientes.
                </p>
              </td>
              <td className="w-4"></td>
            </tr>
            <tr className="w-100">
              <td className="w-4"></td>
              <td>
                <h2 className="mb-15 fw-bold">Valores</h2>
                <p className="mb-30 text-intend-30p">Innovación, seguridad, eficiencia, transparencia, compromiso, ética y colaboración.</p>
              </td>
              <td className="w-4"></td>
            </tr>
          </tbody>
        </table>

        <div>
          <hr className="h-10p bg-secondary w-100 mb-30"></hr>
        </div>

        <table className="position-relative">
          <tbody c>
            <tr className="w-100">
              <td className="w-4"></td>
              <td className="w-92">
                <h2 className="mb-15 fw-bold">
                  ¿Qué es{" "}
                  <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                    Drive-Team.es
                  </a>
                  ?
                </h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  Drive-Team es la plataforma para calcular automáticamente las dietas de los conductores de camión y autobús, tanto en el transporte
                  nacional como internacional por carretera. Simplemente sube los archivos del tacógrafo, del vehículo o del conductor, y Drive-Team
                  te dará un reporte de las actividades, del cumplimiento de jornadas y un desglose de las dietas.
                </h3>
                <div className="d-flex justify-content-center align-items-center mt-30 mb-30">
                  <img src={screenshot} className="shadow w-70 h-70 d-block m-auto rounded-3 mb-30" alt="Imagen interfaz APP" />
                </div>
                <h2 className="mb-15 fw-bold">
                  ¿Por qué creamos{" "}
                  <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                    Drive-Team.es
                  </a>
                  ?
                </h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  <p>
                    Creamos Drive-Team para abordar la escasez de conductores de camión y optimizar el flujo de efectivo en empresas de transporte.
                    Ofrecemos un dashboard para analizar la productividad, previsión automática de costos como dietas y complementos, detección de
                    actividades anómalas en tacógrafos y alertas de cumplimiento de jornadas. Utilizamos datos del tacógrafo digital para garantizar
                    cálculos precisos y legales, evitando reclamos futuros. La instalación es sencilla y ofrecemos pruebas sin compromiso. Si no
                    satisface tus necesidades, ofrecemos opciones alternativas o soluciones personalizadas.
                  </p>
                </h3>
                <h2 className="mb-15 fw-bold">¿Es complicado de instalar?</h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  <p>
                    Instalarlo es muy sencillo, basta con tener un usuario y una contraseña, y acceder a {""}
                    <a className="fw-bold text-highlight-color clickable" onClick={() => window.open("https://drive-team.es")}>
                      Drive-Team.es
                    </a>{" "}
                    para usarlo.
                  </p>
                </h3>
                <h2 className="mb-15 fw-bold">¿No estás convencido aún? ¿No es lo que buscabas?</h2>
                <h3 className="lh-base text-intend-30p mb-30">
                  Si Drive-Team no es lo que buscas no hay problema, haznos saber qué buscas. Podemos ponerte en contacto con otros proveedores, o
                  diseñar una solución personalizada. No hay problema. Puedes ponerte en contacto con nosotros escribiendo a{" "}
                  <a className="fw-bold text-highlight-color" href="mailto:ignacio@traxain.com">
                    {" "}
                    ignacio@traxain.com
                  </a>
                  , en Traxain.com o al teléfono y <a className="fw-bold text-success">WhatsApp</a>{" "}
                  <a
                    className="clickable text-success fw-bold"
                    onClick={() => window.open("https://wa.me/+34638269633?text=contactar%20con%20traxain")}
                  >
                    +34 638 26 96 33
                  </a>{" "}
                  y podrás probar Drive-Team sin compromiso.
                </h3>
              </td>
              <td className="w-4">
                <td className="w-80 text-start fw-light"></td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
};

export default About;
