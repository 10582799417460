import React, { Component, useEffect, useState} from 'react'
import {BrowserRouter, Routes, Route, Link, Navigate} from 'react-router-dom'
import Footer from'./Footer'
import Page404 from './Page404'
import Home from './Home'
import Blog from './Blog'
import "./App.css"
// import NavBarComponent from './NavBarComponent'
import RegisterModal from './RegisterModal';
import LoginModal from './LoginModal';
// import { NavBarLogged_Mobile } from './NavBarLogged_Mobile'
import Navbar from './Navbar'
import NavBar_NL_ND from './NavBar_NL_ND'
import About from './About'


function subscribeToPushNotifications() {
  navigator.serviceWorker.ready.then(registration => {
    registration.pushManager.subscribe({ userVisibleOnly: true })
      .then(subscription => {
        // Send the subscription data to your FastAPI server
        fetch('/api/send_notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(subscription),
        });
      })
      .catch(error => {
        console.error('Error subscribing to push notifications:', error);
      });
  });
}


function requestNotificationPermission() {
  if ('Notification' in window) {
    // Request permission inside a user-generated event handler, like a button click.
    document.getElementById('notificationButton').addEventListener('click', function () {
      Notification.requestPermission().then((permission) => {
        //console.log(permission)
        if (permission === 'granted') {
          //console.log('Notification permission granted');
        } else {
          console.warn('Notification permission denied');
        }
      });
    });
  }
}





function App() {
  // useEffect(() => {
  //   requestNotificationPermission();
  // }, []);
  //  const [isLoggedIn, setIsLoggedIn] = useState(false); // Usamos el useState para controlar el estado del inicio y los botones que verá el usuario si está registrado o no
  //  const [isMobile, setIsMobile] = useState(false); // Usamos el useState para controlar si el usuario entra desde un smartphone

  //  const token = localStorage.getItem("TraxainUserToken");

  //   if (token && token !=="null") {
  //     setIsLoggedIn(true);
  //   }


  // // Función para determinar si el usuario está en un dispositivo móvil
  // const checkIsMobile = () => {
  //   // Aquí puedes utilizar cualquier método de detección de dispositivos móviles
  //   const isMobileDevice = window.innerWidth <= 768; // Por ejemplo, si el ancho de la ventana es menor o igual a 768 píxeles, consideramos que es un dispositivo móvil
  //   setIsMobile(isMobileDevice);
  // };

  return (
  <div className="App"> 
  {/* <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
  
      <button id="notificationButton" onClick={requestNotificationPermission}>
        Request Notification Permission
      </button> */}
       
    <BrowserRouter>
    {/* <NavBarComponent /> */}
    {/* <Navbar /> */}
    <br></br>
    
    <Routes>
      <Route path="/" element={<Home/>} />
       <Route path="/sobre-drive-team" element={<About/>} /> 
      <Route path="/blog/:blogId" element={<Blog />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/*" element={<Page404/>} />
      <Route path="/error-page" element={ <Page404 /> }/>
      <Route path="/redirect" element={ <Navigate to="/error-page" /> }/>
      <Route path="/register" element={<RegisterModal/>} />
       <Route path="/login" element={<LoginModal/>} />
      
      {/* <Route path="/login" element={isLoggedIn ? (isMobile ? <NavBarLogged_Mobile /> : <NavBarComponent />) : <login />} /> */}
      {/* <Route path="/login" element={isLoggedIn ? (isMobile ? <MobileDashboard /> : <Dashboard />) : <Navigate to="/navBarLogged_Mobile" />} />  */}
    </Routes>

    
    
    </BrowserRouter>
  </div>
  );
}

export default App
