import React, { Component } from "react";
import "./modal.css";
import "react-dropdown/style.css";

class SendSmsActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "Comentario",
      empId: "EmpId",
    };
  }

  async componentDidMount() {
    await this.setState({ comments: "Comentario", empId: "EmpId" });
  }

  async editSendSms(comments) {
    await this.setState({ comments: comments });
  }

  render() {
    try {
      var show = this.props.show;
      var children = this.props.children;

      var showHideClassName = show ? "modal display-block" : "modal display-none";

      //Formateo de los datos
      let date = new Date(this.props.selectedActivityDict.start * 1000);
      let formatDate = date.toLocaleString();

      let type = this.props.selectedActivityDict.type;
      let typeFormat;
      switch (type) {
        case "Rest":
          typeFormat = "Descanso";
          break;
        case "Working":
          typeFormat = "Trabajando";
          break;
        case "Driving":
          typeFormat = "Conducción";
          break;
        case "Available":
          typeFormat = "Disponible";
          break;
        case "Desconocido":
          typeFormat = "Desconocido";
          break;
      }

      let location = this.props.selectedActivityDict.place;
      let formatLocation = location;

            return (
                <div className='showHideClassName text-center bg-gray'>
                    <section className="modal-main text-center b-rad-10-px b-1-s">
                        <h5>{children}</h5>
                        <form className="text-center" onSubmit={(event) => {
                            event.preventDefault();
                            let comments = this.comments.value;
                            let empId;
                            let start = this.props.start;
                            let end = this.props.end;


      

                try {
                  empId = this.props.myEmployee.id;
                } catch {
                  empId = this.props.selectedActivityDict["employee_id"];
                }

                this.props.sendMessage(empId, start, end, comments);

                        }}>
                            <table className="w-100 text-center border-bottom-1-px">
                                <tr>
                                    <td className="w-80">
                                        <div>
                                            Hora de inicio: {formatDate}
                                        </div>
                                        <div>
                                            Lugar: {formatLocation}
                                        </div>
                                        <div>
                                            Tipo: {typeFormat}
                                        </div>
                                        <div>
                                            Duración: {this.props.selectedActivityDict.duration} minutos
                                        </div>
                                        <br/>
                                    </td>
                                </tr>
                            </table>
                            <br/>
                            <table className="w-100 text-center">
                                <tr>
                                    <td className="w-10"></td>
                                    <td className="w-80">
                                        <table className="w-100">
                                            <tr>
                                                <td>
                                                    Comentarios
                                                </td>
                                            </tr>
                                            <tr className="w-100">
                                                <td className="w-100">
                                                    <input
                                                        ref={(comments) => { this.comments = comments }}
                                                        className="form-control form-control-lg fs-14 text-muted w-100"
                                                        defaultValue={this.props.defaultComments}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </table>
                                    </td>
                                    <td className="w-10"></td>
                                </tr>
                                <tr>
                                    <td className="w-10"></td>
                                    <td className="w-80">
                                        <br></br>
                                    </td>
                                    <td className="w-10"></td>
                                </tr>
                                <tr>
                                    <td className="w-10" ></td>
                                    <td className="w-80" >
                                        <table className="w-100">
                                            <tr>
                                                <td className="w-40">
                                                    <button  
                                                        type="submit"
                                                        className="btn btn-primary btn-block btn-lg w-100 bg-success b-n fs-16">
                                                        Confirmar
                                                    </button>
                                                </td>
                                                <td className="w-20">
                                                </td>
                                                <td className="w-40">
                                                    <button type="button" className="btn btn-primary btn-block btn-lg w-100 bg-danger b-n fs-16" onClick={this.props.onClose}>
                                                        Cerrar
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td className="w-10"></td>
                                </tr>
                            </table>
                        </form>
                        <br></br>
                    </section>
                </div>
            );
        } catch (err) {
            return (<div>Error al cargar el componente</div>)
        }

    }
  }


export default SendSmsActivity;
