
import React, { Component, useContext, useState } from 'react'
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import Logout from './Logout'
import { Link, NavLink } from 'react-router-dom'
import NavBarComponent from './NavBarComponent'
import  NavBarLogged_Desktop  from './NavBarLogged_Desktop'
// import { NavBarLogged_Mobile } from './NavBarLogged_Mobile'
//import { NavBar_NL_ND } from './NavBar_NL_ND'
import NavBarLogged_NoD from './NavBarLogged_NoD';
import NavBar_NL_ND from './NavBar_NL_ND'


class Navbar extends Component {
  async componentDidMount() {
    try{
    let winWidth = await window.innerWidth
    let token = localStorage.getItem("TraxainUserToken");
    await this.setState({ token:token})
    let winHeight = await window.innerHeight
    await this.setState({ winWidth: winWidth })



      await this.setState({ winHeight: winHeight });
      let device = await this.getDeviceType();
      await this.setState({ device: device });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error en el navegador" });
    }
  }

  async getDeviceType() {
    try {
      let ua = await navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
      }
      return "desktop";
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al detectar el tipo de dispositivo" });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      winWidth: 0,
      winHeight: 0,
      device: "desktop",
      token: ""
      
    }
    this.showNavigatorModalNavbar = this.showNavigatorModalNavbar.bind(this)

  }

  async showNavigatorModalNavbar(){
    //console.log("en la funcion show...")
   await this.props.showNavigatorModalHome()
  }
  
  

  

  render() {
    try {
      const token = localStorage.getItem("TraxainUserToken");
      let field0;
      let field1;
      let field3;
      let width = this.state.winWidth;

      //PArte de phind
      const { device, showNavigatorModal } = this.state;

      if(token !== null && token !== "null"){
        if (this.state.device === "desktop") {
          //si el usuario está logueado y tiene vista de escritorio
          //console.log("estoy en la lienea 87")
          field3 = <NavBarLogged_Desktop 
          showNavigatorModalNavbar={this.showNavigatorModalNavbar}
          />
    
        


        } else {
          //si el usuario está logueado pero su vista no es de escritorio
          //console.log("estoy en la lienea 133")

          field3 = <NavBarLogged_NoD
            userRole={this.props.userRole}
            showNavigatorModalNavbar= {this.showNavigatorModalNavbar}
          />


        }
      } else {
        if (this.state.device === "desktop") {
          //si el usuario no está logueado y lo ve desde escritorio
     
          field3 = <NavBarComponent />
       
        } else {
          //si el usuario no está logueado y no lo ve desde escritorio
          //console.log("estamos en la liena 218")
          field3 = <NavBar_NL_ND
          showNavigatorModalNavbar={this.showNavigatorModalNavbar}
                    />
           

        }
      }

      if (this.props.userRole === "admin") {
        let field0Text = "Calcular Complementos";
        if (this.props.showANDirecto) {
          field0Text = "Salir del calculador";
        }
        // Render de variable field 0
        field0 = (
          <form
            onSubmit={(event) => {
              event.preventDefault();

              this.props.selectANDirecto();
            }}
          >
            <button type="submit" className="button">
              {field0Text}
            </button>
          </form>
        );
        // Render de variable field 1
        field1 = (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.props.usersEmployeeLoad();
            }}
          >
            <button type="submit" className="button">
              Cargar empleados
            </button>
          </form>
        );
      }

      let logoElement=<td className='w-10 text-center h-100'>
                    <a href='https://drive-team.es'>
                      <img src={logo} className="d-inline-block p-10-px h-100" alt="Drive-Team"
                      onError={(e) => {
                        e.target.src = logoPNG;
                      }}/>
                    </a>
                  </td>

if(token !== null && token !== "null"){


        //habilitados ambos botones
        if (this.state.device === 'desktop' && this.props.stateHistory.length>=1 && this.props.stateFuture.length>=1) {
          return (            
          
          <nav       className="navbar fixed-top bg-white shadow w-100 h-12">
            <table   className='w-100 h-90'>
              <tbody className='w-100 h-100'>
                <tr  className='w-100 h-10'>
                </tr>
                <tr className='w-100 h-80'>
                  {logoElement}
                  <td className='fs-35 w-25 text-start h-100'>
                    <a className='text-decoration-none .color-navbar-1 ff-helvetica svg h100' href='https://drive-team.es'>Drive-Team</a>
                  </td>
                  <td className='text-center svg'>
                    {field3}
                  </td>
                  <td className='text-center svg w-12'>
                    <Logout />
                  </td>
                </tr>
                <tr className='w-100 h-10'>
                </tr>
              </tbody>
            </table>

            <table   className='w-100 h-10 mt-30-px'>
              <tbody className='w-100 h-100'>
                {/* <tr  className='w-100 h-100'>
                   
                  <td  className='w-35'></td>
                  <td  className='w-30'>
                    <div className="btn-group shadow w-100">
                      <button className="btn btn-primary" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.volverAlPasado()}}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <button class="btn btn-primary" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.regresoAlFuturo()}}> 
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </td>
                    <td className='w-35'></td>
                  
                </tr> */}
              </tbody>
            </table>    

              <table style={{ width: "100%", height: "10%", marginTop: "30px" }}>
                <tbody style={{ width: "100%", height: "100%" }}>
                  <tr style={{ width: "100%", height: "100%" }}>
                    {/* Botones */}
                    <td style={{ width: "35%" }}></td>
                    <td style={{ width: "30%" }}>
                      <div class="btn-group shadow" style={{ width: "100%" }}>
                        <button
                          class="btn btn-primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.volverAlPasado();
                          }}
                        >
                          <i className="fas fa-arrow-left"></i>
                        </button>
                        <button
                          class="btn btn-primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.regresoAlFuturo();
                          }}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </td>
                    <td style={{ width: "35%" }}></td>
                    {/* Botones */}
                  </tr>
                </tbody>
              </table>
            </nav>
          );
        }
        //deshabilitado boton de atras
        else if (!this.props.showTripModal && this.state.device === "desktop" && this.props.stateHistory.length < 1 && this.props.stateFuture.length >= 1) {
          return (
          
          <nav className="navbar fixed-top bg-white shadow w-100 h-12">
            <table  className='w-100 h-90'>
              <tbody className='w-100 h-100'>
                <tr className='w-100 h-10'>
                </tr>
                <tr className='w-100 h-80'>
                  {logoElement}
                  <td className='fs-35 w-25 text-start h-100'>
                    <a className='text-decoration-none color-navbar-1 ff-helvetica svg h-100' href='https://drive-team.es'></a>
                  </td>
                  <td className='text-center svg'>
                    {field3}
                  </td>
                  <td className='text-center svg w-12'>
                    <Logout />
                  </td>
                </tr>
                <tr className='w-100 h-10'>
                </tr>
              </tbody>
            </table>

            <table   className='w-100 h-10 mt-30-px'>
              <tbody className='w-100 h-100'>
                <tr  className='w-100 h-100'>
                      {/* Botones */}
                  <td  className='w-35'></td>
                  <td  className='w-30'>
                    <div class="btn-group shadow w-100">
                      <button class="btn btn-primary disabled" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.volverAlPasado()}}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <button class="btn btn-primary" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.regresoAlFuturo()}}> 
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </td>
                    <td className='w-35'></td>
                  {/* Botones */}
                </tr>
              </tbody>
            </table>    


              <table style={{ width: "100%", height: "10%", marginTop: "30px" }}>
                <tbody style={{ width: "100%", height: "100%" }}>
                  <tr style={{ width: "100%", height: "100%" }}>
                    {/* Botones */}
                    <td style={{ width: "35%" }}></td>
                    <td style={{ width: "30%" }}>
                      <div class="btn-group shadow" style={{ width: "100%" }}>
                        <button
                          class="btn btn-primary disabled"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.volverAlPasado();
                          }}
                        >
                          <i className="fas fa-arrow-left"></i>
                        </button>
                        <button
                          class="btn btn-primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.regresoAlFuturo();
                          }}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </td>
                    <td style={{ width: "35%" }}></td>
                    {/* Botones */}
                  </tr>
                </tbody>
              </table>
            </nav>
          );
        }
        //deshabilitado boton de alante
        else if (!this.props.showTripModal && this.state.device === "desktop" && this.props.stateHistory.length >= 1 && this.props.stateFuture.length < 1) {
          return (
          
          <nav       className="navbar fixed-top bg-white shadow w-100 h-12">
            <table   className='w-100 h-90'>
              <tbody className='w-100 h-100'>
                <tr  className='w-100 h-10'>
                </tr>
                <tr  className='w-100 h-80'>
                {logoElement}
                  <td  className='fs-35 w-25 text-start h-100'>
                    <a  className='text-decoration-none color-navbar-1 ff-helvelica svg h-100' href='https://drive-team.es'></a>
                  </td>
                  <td  className='text-center svg'>
                    {field3}
                  </td>
                  <td  className='text-center svg w-12'>
                    <Logout />
                  </td>
                </tr>
                <tr className='w-100 h-10'>
                </tr>
              </tbody>
            </table>

            <table   className='w-100 h-10 mt-30-px'>
              <tbody className='w-100 h-100'>
                {/* <tr  className='w-100 h-100'>
              
                  <td  className='w-35'></td>
                  <td  className='w-30'>
                    <div className="btn-group shadow w-100">
                      <button className="btn btn-primary" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.volverAlPasado()}}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <button class="btn btn-primary disabled" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.regresoAlFuturo()}}> 
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </td>
                    <td className='w-35'></td>
         ¡
                </tr> */}
              </tbody>
            </table>    

              <table style={{ width: "100%", height: "10%", marginTop: "30px" }}>
                <tbody style={{ width: "100%", height: "100%" }}>
                  {/* <tr style={{ width: "100%", height: "100%" }}>
                  
                    <td style={{ width: "35%" }}></td>
                    <td style={{ width: "30%" }}>
                      <div class="btn-group shadow" style={{ width: "100%" }}>
                        <button
                          class="btn btn-primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.volverAlPasado();
                          }}
                        >
                          <i className="fas fa-arrow-left"></i>
                        </button>
                        <button
                          class="btn btn-primary disabled"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.regresoAlFuturo();
                          }}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </td>
                    <td style={{ width: "35%" }}></td>
                  
                  </tr> */}
                </tbody>
              </table>
            </nav>
          );
        }
        //deshabilitados ambos botones
        else if (!this.props.showTripModal && this.state.device === "desktop" && this.props.stateHistory.length < 1 && this.props.stateFuture.length < 1) {
          return (

          <nav       className="navbar fixed-top bg-white shadow w-100 h-12">
            <table   className='w-100 h-90'>
              <tbody className='w-100 h-100'>
                <tr  className='w-100 h-10'>
                </tr>
                <tr className='w-100 h-80'>
                {logoElement}
                  <td className='fs-35 w-25 text-start h-100'>
                    <a className='text-decoration-none color-navbar-1 ff-helvetica svg h-100' href='https://drive-team.es'></a>
                  </td>
                  <td className='text-center svg'>
                    {field3}
                  </td>
                  <td className='text-center svg w-12'>
                    <Logout />
                  </td>
                </tr>
                <tr className='w-100 h-10'>
                </tr>
              </tbody>
            </table>

            <table   className='w-100 h-10 mt-30-px'>
              <tbody className='w-100 h-100'>
                {/* <tr  className='w-100 h-100'>
               
                  <td  className='w-35'></td>
                  <td  className='w-30'>
                    <div class="btn-group shadow w-100">
                      <button class="btn btn-primary disabled" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.volverAlPasado()}}>
                        <i className="fas fa-arrow-left"></i>
                      </button>
                      <button className="btn btn-primary disabled" 
                              
                              onClick={(event) => {
                                event.preventDefault()
                                this.props.regresoAlFuturo()}}> 
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </td>
                    <td className='w-35'></td>
                
                </tr> */}
              </tbody>
            </table>    

          </nav>
          
          )
          


        
     
          // Si se carga desde ModalEmployee en modo escritorio
        } else if (this.props.showTripModal && this.state.device === "desktop") {
          return (
            <nav style={{ width: "100%", height: "10%" }} className="navbar fixed-top bg-white shadow">
              <table style={{ width: "100%", height: "90%" }}>
                <tbody style={{ width: "100%", height: "100%" }}>
                  <tr style={{ width: "100%", height: "10%" }}></tr>
                  <tr style={{ width: "100%", height: "80%" }}>
                    <td style={{ width: "10%", textAlign: "center", height: "100%" }}>
                      <a href="https://drive-team.es">
                        <img
                          src={logo}
                          style={{ padding: "10px", width: "80%", height: "auto" }}
                          className="d-inline-block"
                          alt="Drive-Team"
                          onError={(e) => {
                            e.target.src = logoPNG;
                          }}
                        />
                      </a>
                    </td>
                    <td style={{ fontSize: 35, width: "25%", textAlign: "left", height: "100%" }}>
                      <a
                        style={{ textDecoration: "none", color: "#5A5A5A", fontFamily: "Helvetica", verticalAlign: "middle", height: "100%" }}
                        href="https://drive-team.es"
                      >
                        Drive-Team
                      </a>
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{field3}</td>
                    <td style={{ textAlign: "center", verticalAlign: "middle", width: "12%" }}>
                      <Logout />
                    </td>
                  </tr>
                  <tr style={{ width: "100%", height: "10%" }}></tr>
                </tbody>
              </table>
            </nav>
          );
        } else {
          return (

            <nav className="navbar fixed-top bg-white flex-md-nowrap p-1 shadow min-h-30 w-100">
              <div className='color-navbar-2 h-auto w-100'>
                  <table className='w-100 h-auto'>
                      <tbody className='w-100 h-auto'>
                          <tr className='w-100 h-auto'>
                          </tr>
                          <tr className='w-100 h-auto'>
                              <td className='w-35'>
                                  <h1>
                                      <a className='text-decoration-none color-navbar-1 ff-helvetica' href='https://drive-team.es'>
                                          <img src={logo} className="d-inline-block w-100 h-auto p-10-px" alt="Drive Team" 
                                          onError={(e) => { e.target.src = logoPNG; }}/>
                                      </a>
                                  </h1>
                              </td>
    
                      <td className='w-30 h-100'>
    
    
    
                      </td>
                      <td style={{ width: "35%", height: "100%" }}></td>
                      <td className='w-35 h-100'>
                        {field3}

                      </td>

                      </tr>

                    <tr className='w-30 h-10'>
                    </tr>
    

                  </tbody>
                </table>
              </div>
            </nav>
          );
        }
      } else {
        // Sino esta logeado en modo escritorio
        if (this.state.device === "desktop") {
          return (
            <nav className="navbar fixed-top bg-white shadow h-10 w-100">
              <table className='w-100 h-100'>
                <tbody className='w-100 h-100'>
                  <tr className='h-10 w-100'>
      
                  </tr>
                  <tr className='w-100 h-80'>
                  {logoElement}

                    <td className='fs-35 w-40 text-start h-100'>
                      <a  className='text-decoration-none color-navbar-1 ff-helvetica svg h-100' href='https://drive-team.es'></a>
                    </td>
      
                    <td className='text-center svg'>
      
                      {field3}
      
                    </td>
      
                  </tr>
                  <tr className='h-0'>
      

                  </tr>
                  <tr style={{ height: "0%" }}></tr>
                </tbody>
              </table>
      
              <div className="text-center w-25">
      
              </div>    
      
              <div className="text-center w-15 fs-30">
      
              </div>
      

            </nav>
          );
          // SIno esta loegado en modo mobile
        } else {
          let winHeigth = window.innerHeight;
          let winWidth = window.innerWidth;

          let turnmode = winWidth > winHeigth;

          if (turnmode === false) {
            return (
      
              <nav  className="fixed-top bg-white shadow h-auto w-100 mb-10-px">
                <table  className='w-100 h-100'>
                  <tr  className='w-100 h-100 mb-10-px'>
                    <td  className='w-35 text-center svg p-10-px'>
                      <h1>
                        <a  className='text-decoration-none color-navar-1 ff-helvetica ' href='https://drive-team.es'>
                          <img src={logo} className="d-inline-block p-10-px" alt="Drive Team"

                            onError={(e) => {
                              e.target.src = logoPNG;
                            }}
                          />
                        </a>
                      </h1>
                    </td>
                    <td className='w-5 h-100' ></td>
                    <td className='w-50 h-100'>
                      {field3}
                    </td>

                  </tr>
                </table>
              </nav>
            );
          } else {
            return (
              <nav  className=" fixed-top bg-white shadow max-h-50-px w-100 mb-10-px">
                <table  className='w-100 h-50'>
                  <tr  className='w-100 h-50 mb-10-px'>
                    <td  className='w-2'></td>
                    <td  className='w-35 text-center svg p-10-px'>
                      <h1>
                        <a  className='text-decoration-none color-navbar1 ff-helvetica' href='https://drive-team.es'>
                          <img src={logo} className="d-inline-block p-10-px"  alt="Drive Team"

                            onError={(e) => {
                              e.target.src = logoPNG;
                            }}
                          />
                        </a>
                      </h1>
                    </td>
                    <td  className='w-11 h-100'></td>
                    <td  className='w-50 h-100 text-center svg'>
                      {field3}
                    </td>
                    <td  className='w-2'></td>

                  </tr>
                </table>
              </nav>
            );
          }
        }
      } 


  } catch(err) {
    // setTimeout(() => window.location.reload(), 2000);
    return (<div>Error al cargar el Navegador</div>)


  }

  }
}


export default Navbar;

