import React, { useState } from 'react';
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import { Link, NavLink } from 'react-router-dom'
import LoginModal from './LoginModal';
import { left } from '@popperjs/core';
import Logout from './Logout';

export function NavBarComponent() {
    const [showLinks, setShowLinks] = useState(false);



      const apagador = () => {
        //console.log("Apaga");
        {Logout.handleLogout}
        <>
  
        </>
        
      };
  
      const token = localStorage.getItem("TraxainUserToken");
      var logginButtons;
        if(token !== null && token !== "null"){
          logginButtons = <Logout />;
        }else {
          logginButtons = <div>
            <table>
              <tr>
              <td className='w-20 nav-btn rounded'>
                                  <div className='w-50 btn btn-sm btn-link'>
                                    <NavLink className="btn btn-sm btn-block nav-btn fs-5 mr-120-px mt-10-px" to="/register">Registrarse</NavLink>
                                  </div>
                                </td>
                                {/* Registro */}
                                {/* Iniciar sesion*/}
                                <td className='w-20 nav-btn rounded '>
                                  <div className='w-195 btn btn-sm btn-link'>
                                      <NavLink className='btn btn-sm btn-block nav-btn fs-5 mt-10-px' to="/login">Iniciar sesión</NavLink>
                                  </div>
              </td>
              </tr>
            </table>
            </div>       
        }
  
  //console.log("NavBarLogged_Desktop_from_about");
  
      return (
        <nav       className="navbar fixed-top bg-white shadow w-100 h-12">
              <table   className='w-100 h-90'>
                <tbody className='w-100 h-100'>
                  <tr  className='w-100 h-10'>
                    <br></br>
                  </tr>
                  <tr className='w-80 h-60'>
        <div className="navbar custom-navbar">
        <div className="d-flex justify-content-start align-items-center">
                      <img src={logo} className="d-inline-block p-10-px h-67 w-67" alt="Drive-Team"
                      onError={(e) => {
                        e.target.src = logoPNG;
                      }}/>
        </div>
          <div className="d-flex justify-content-end">
            <table className="navbar-default">
              <tbody>
                <tr className="container-fluid">
                  <td className='w-20 nav-btn rounded text-center'>
                    <div className='w-50 btn btn-sm btn-link'>
                      <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/">Inicio</NavLink>
                    </div>
                  </td>
                  <td className='w-20 nav-btn rounded text-center'>
                    <div className='w-50 btn btn-sm btn-link'>
                      <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 text-center mt-10-px" to="/blog">Blog</NavLink>
                    </div>
                  </td>
                 
                  {logginButtons}
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </tr>
        </tbody>
        </table>
        </nav>
      );
    }

    // return (
    //     //  //si el usuario no está logueado y lo ve desde escritorio
    //     // <div className="Navbar navbar navbar-expand-sm bg-light fixed-top max-h-100-px h-78-px ">
    //     //     <div className="leftSide w-100">
    //     //     <img src={logo} className="d-inline-block h-47-px mt-10-px mb-10-px ml-10-px" alt="Drive-Team"
    //     //             onError={(e) => {
    //     //                 e.target.src = logoPNG;
    //     //             }}/>
    //     //     </div> 
    //     //     {/* //TODO rellenar esta parte */}
            

    //       <table className='w-80'>
    //         <tr className='w-50'>
    //           <td className='w-20 nav-btn rounded'>
    //             <div className='w-50 btn btn-sm btn-link'>
    //             {/* //btn btn-sm btn-block nav-btn*/}
    //               <NavLink type="button" className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/">Inicio</NavLink>
    //             </div>
    //           </td>
    //           <td className='w-20 nav-btn rounded '>
    //             <div className='w-50 btn btn-sm btn-link'>
    //               <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/sobre-drive-team">Nosotros</NavLink>
    //             </div>
    //           </td>
    //           {/* Registro */}
    //           <td className='w-20 nav-btn rounded'>
    //             <div className='w-50 btn btn-sm btn-link me-sm-10'>
    //                <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 mt-10-px" to="/register">Registrarse</NavLink>
    //             </div>
    //           </td>
    //           {/* Registro */}
    //           {/* Iniciar sesion*/}
    //           <td className='w-20 nav-btn rounded '>
    //             <div className='w-195 btn btn-sm btn-link'>
    //                 <NavLink className='btn btn-sm btn-block nav-btn fs-5 mt-10-px' to="/login">Iniciar sesión</NavLink>
    //             </div>
    //           </td>              
    //         </tr>
    //       </table>   
    //     // </div>
    // );


export default NavBarComponent;
